export default {
  tomanyreqotp:"คำขอ OTP มากเกินไป กรุณารอสักครู่",
  text2fa:"มีการเปิดใช้งานยืนยันตัวตน 2 ขั้นตอน",
  syssendotp:"ระบบจะทำการส่ง OTP ไปที่เบอร์โทรศัพท์ :",
  verify_OTP:"ยืนยัน OTP",
  idcard:"เลขบัตรประชาชน",
  loginSMS: "เข้าสู่ระบบด้วยหมายเลขโทรศัพท์",
  phoneNumber: "หมายเลขโทรศัพท์มือถือ",
  requiredPhoneNumber: "โปรดกรอกหมายเลขโทรศัพท์มือถือให้ครบ",
  otp: "เลื่อนไปทางขวาเพื่อรับ OTP",
  verifyOTP: "ยืนยันตัวตน",
  enterOTP: "กรอกหมายเลข OTP",
  countDown: "เวลาที่เหลืออยู่ : ",
  resendOTP: "ขอ OTP อีกครั้ง",
  confirmOTP: "ยืนยัน OTP",
  DateDefault: "ค่าเริ่มต้น",
  namebusiness: "ชื่อบริษัท",
  CitizenProfile: "ข้อมูลผู้ใช้งานทั่วไป",
  BusinessProfile: "ข้อมูลบริษัท",
  dowloads: "ดาวน์โหลด",
  account_id: "Account id",
  user_id: "User id",
  business_id: "business_id",
  Numberofdaystodelete: "จำนวนวันที่ต้องการลบ",
  days: "วัน",
  TaxID: "เลขผู้เสียภาษี",
  usernameprofile: "Username",
  name: "ชื่อ",
  surname: "นามสกุล",
  emailthai: "วันอีเมล",
  email: "อีเมล",
  company: "บริษัท",
  Personal: "ข้อมูลส่วนตัว",
  consentheader: "เอกสารยินยอม",
  Youwanttodelete: "จำนวนไฟล์ที่ต้องการลบ",
  file: "ไฟล์",
  memoryinformation: "ข้อมูลหน่วยความจำ",
  companyinformation: "ข้อมูลบริษัท",
  dataStorageUsage: "จำนวนที่ใช้",
  dataStorageMax: "จำนวนทั้งหมด",
  namecompany: "ชื่อบริษัท",
  namecompanyeng: "ชื่อบริษัทภาษาอังกฤษ",
  default: "th",
  new: "อัปโหลด / สร้าง",
  login: "เข้าสู่ระบบ",
  username: "ชื่อผู้ใช้ / อีเมล",
  password: "รหัสผ่าน",
  quicksearch: "ค้นหา",
  hashtag_tooltip: "สำหรับผู้ใช้งานที่ต้องการค้นหาไฟล์หรือโฟลเดอร์ที่มีตั้งแต่ 2  แฮชแท็กขึ้นไป",
  hashtag_manual_header: "สำหรับกรณีที่ต้องการค้นหาไฟล์หรือโฟลเดอร์จากแฮชแท็ก 2 แฮชแท็กขึ้นไป",
  hashtag_manual_header_mobile: "กรณีต้องการค้นหาจากแฮชแท็ก 2 แฮชแท็กขึ้นไป",
  hashtag_manual_body_1: "สำหรับกรณีที่ต้องการค้นหาไฟล์หรือโฟลเดอร์จากแฮชแท็ก 2 แฮชแท็กขึ้นไป ต่อโฟลเดอร์ไฟล์สามารถใส่อักขระพิเศษในการค้นหาได้โดยจะใช้ เครื่องหมาย Comma ( , ) และ เครื่องหมาย Pipe ( | ) ในการค้นหา ตัวอย่างดังนี้",
  hashtag_manual_body_2: "Comma ( , ) ใช้สำหรับการค้นหาไฟล์หรือโฟลเดอร์โดยต้องมีแฮชแท็กที่ระบุไปทั้งหมด เช่น A,B หมายความว่า ให้ทำการค้นหาไฟล์หรือโฟลเดอร์ที่มีแฮชแท็ก A และ B (ใช้เพื่อกำหนดดีเทลการค้นหาให้แคบลง)",
  hashtag_manual_body_3: "Pipe ( | ) ใช้สำหรับค้นหาไฟล์หรือโฟลเดอร์นั้นจากแฮชแท็กใดแฮชแท็กหนึ่ง เช่น A | B หมายความว่า ให้ทำการค้นหาไฟล์หรือโฟลเดอร์ที่มีแฮชแท็ก A หรือ B (ใช้สำหรับกรณีที่ไม่มั่นใจว่าไฟล์โฟลเดอร์ที่ต้องการค้นหาถูกตั้งแฮชแท็กใด)",
  more_infor_hashtag: "กดเพื่อดูเพิ่มเติม",
  forgotpassword: "ลืมรหัสผ่าน",
  createaccount1: "ยังไม่มีบัญชี",
  createaccount2: "สร้างบัญชี ONE-ID",
  logout: "ออกจากระบบ",
  profile: "จัดการโปรไฟล์",
  Personalinformation: "ข้อมูลส่วนตัว",
  forbusiness: "สำหรับธุรกิจ",
  businessaccount: "บัญชีผู้ใช้งานธุรกิจ",
  folders: "โฟลเดอร์",
  files: "ไฟล์",
  folder: "โฟลเดอร์",
  file: "ไฟล์",
  recentfile: "ไฟล์ที่เข้าดูล่าสุด",
  company: "บริษัท",
  colorpiker: "ตั้งค่าธีม",
 otp_botonebox : {
    notelephone:"ผู้ใช้รายนี้ไม่มีหมายเลขโทรศัพท์มือถือสำหรับส่ง OTP",
    sendagain:"ส่ง OTP อีกครั้ง",
    setphonenumber:"ตั้งค่าเบอร์โทรศัพท์",
    minutes: 'นาที',
    send_otp_btn: "ส่งรหัส OTP",
    cancel_otp_btn: "ยกเลิก",
    resend_otp_btn: "ส่งรหัส OTP ใหม่",
    continue_otp_btn: "ดำเนินการ",
    logout_otp_btn: "อกกจากระบบ",
    verify_otp: "ยืนยัน OTP",
    username_oid: "ชื่อผู้ใช้หรืออีเมลของ One ID",
    phone_oid: "เบอร์โทรศัพท์",
    reason_hint: "เหตุผลของการเข้าใช้งานผู้ใข้ ",
    enter_otp: "ใส่ OTP จาก ChatBot ",
    enter_otp2: "ใส่ OTP ",
    require_username: "จำเป็นต้องใส่ชื่อผู้ใช้",
    validator_username: "โปรดระบุชื่อผู้ใช้จริงของ One ID",
    validator_phone: "โปรดระบุเบอร์โทรศัพท์เพื่อรับ OTP",
    validator_reason: "โปรดระบุเหตุผลเข้าใช้งาน",
    validator_reason_charactors: "โปรดใส่เหตุผลมากกว่า 5 ตัวอักษร",
    validator_reason_special: "ห้ามใส่ตัวอักษรพิเศษบางตัว",
    not_found_user: "ไม่พบเจอผู้ใช้นี้ใน OneId",
    account_not_add: "ผุ้ใช้ยังไม่ได้เพิ่มเพื่อน Chatbot",
    invalid_otp: "OTP ไม่ถูกต้อง",
    fill_otp: "โปรดระบุ OTP ที่ได้รับ",
    sessionwill: "เซสชั่นกำลังจะหมดอายุ",
    sessiontimeout: "เซสชั่นหมดอายุแล้ว",
    your_session_timeout_in: "เซสชั่นของคุณจะหมดอายุใน ",
    your_session_timeout: "เซสชั่นของหมดอายุแล้ว",
    otp_detail: "คุณสามารถขอ OTP ใหม่อีกครั้งเพื่อใช้ในการต่ออายุการใช้งานได้โดย OTP จะส่งไปยังผู้ใช้",
    
  },
  logo_feature: {
    manage_logo: "จัดการโลโก้",
    dragupload: "ลากไฟล์มาวางที่นี่ หรือ คลิกเพื่ออัปโหลด",
    detail_upload: "",
    file_detail: "ได้เฉพาะไฟล์ .JPEG, .PNG, .SVG และ .ICO ขนาดไฟล์สูงสุด",
    file_detail_size: "ไม่เกิน 4 MB. ",
    set_default: "ตั้งเป็นรูปภาพเริ่มต้น",
    save_change: "บันทึกเปลี่ยนแปลง",
    clear_: "ลบ",
    consent_header_change: "ยืนยันบันทึกการเปลี่ยนแปลง",
    default_detail: "ยืนยันเพื่อบันทึกตั้งเป็นรูปภาพโลโก้เริ่มต้นของ OneBox",
    // change_detail: "บันทึกการเปลี่ยนแปลงโลโก้ของ OneBox",
    change_detail: "ยืนยันเพื่อบันทึกการเปลี่ยนแปลงรูปภาพโลโก้ของ OneBox",
    default_consent: "ยืนยัน",
    change_consent: "ยืนยัน",
    save_complete: "บันทึกสำเร็จ",
    dark_mode: "โหมดมืด",
    light_mode: "โหมดสว่าง",
  },
  toast: {
    multiset: {
      star: "ติดดาวสำเร็จ",
      remove: "นำออกสำเร็จ",
      cancle: "ยกเลิกติดดาวสำเร็จ"
    },
    text: {
      starred: "ติดดาว ",
      success: " สำเร็จ",
      canclestart: "ยกเลิกติดดาว ",
      remove: "นำ ",
      totrash: " ลงถังขยะสำเร็จ",
      cannotstar: "ไม่สามารถติดดาว ",
      cannothide: "ไม่สามารถซ่อน ",
      cannotunhide: "ไม่สามารถนำกลับ ",
      cannotcancelshared: "ไม่สามารถยกเลิกการแชร์ ",
      textth: " ได้",
      restore: "กู้คืน",
      hide: "ซ่อน",
      unhide: "นำกลับ",
      cancelshared: "ยกเลิกการแชร์",
      multiremovetotrash: "นำทุกไฟล์ลงถังขยะสำเร็จ",
      ocr: "สร้างไฟล์ OCR สำเร็จ",
    },
    cannotconnectonebox: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้",
    cannotopenfile: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
    cannotdownload: "ไม่สามารถดาวน์โหลด ",
    nopermissionfile: "ไฟล์บางข้อมูลที่คุณเลือกอาจจะไม่ได้รับสิทธิในการเข้าถึง",
    confirmsetadmin:"ยืนยันการตั้งค่าผู้ดูแลระบบ",
    textsetadmin_1:"คุณต้องการตั้งค่า",
    textsetadmin_2:"เป็นผู้ดูแลระบบ ?",
    textsetadmin_3:"คุณต้องการยกเลิกการตั้งค่า",
    expired_token:"เซสชั่นหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
    otpMismatch: "รหัส OTP ไม่ตรงกัน", 
    mobileNumberIncorrect: "หมายเลขโทรศัพท์ไม่ถูกต้อง",
    tooManyRequests: "คำขอมากเกินไป โปรดลองอีกครั้งในภายหลัง",
    consentSuccess: "บันทึกความยินยอมเรียบร้อย",
    folderPathInvalid: "ที่อยู่ของโฟลเดอร์ไม่ถูกต้อง",
    isSourceFileFolder: "คุณอยู่ในแหล่งที่มาของไฟล์/โฟลเดอร์นี้แล้ว",
  },
  toolbar: {
    googledrive:"ย้ายข้อมูลจาก google drive",
    mapdrive:"Map Drive",
    cctv: "ต่ออายุหรือเปลี่ยนแพคเกจ CCTV",
    dcm: "คัดค้นเอกสาร",
    specialupload: "อัปโหลดแบบพิเศษ",
    mydrive: "ไดรฟ์ของฉัน",
    mydrive_bu: "ไดรฟ์บริษัท",
    mystarfile: "ติดดาว",
    mysharefile: "แชร์ร่วมกัน",
    mytrash: "ถังขยะ",
    library: "ห้องสมุด",
    storage: "พื้นที่จัดเก็บข้อมูล",
    use: "ใช้ไป",
    from: "จาก",
    searchCompanyTaxid: "ค้นหาจากชื่อบริษัทหรือเลขประจำตัวผู้เสียภาษี",
    file: "ใบ",
    dashboardinbox: {
      name: "แดชบอร์ดอินบ็อกซ์",
      companyname: "ชื่อบริษัท",
      totalinbox: "จำนวนอินบ็อกซ์ทั้งหมด",
      totalfilesize: "ผลรวมขนาดไฟล์อินบ็อกซ์ทั้งหมด",
      inbox_last6months: "จำนวนอินบ็อกซ์ย้อนหลัง 6 เดือน",
      filesize_last6months: "ผลรวมขนาดไฟล์อินบ็อกซ์ย้อนหลัง 6 เดือน",
      inboxpermonth: "จำนวน Inbox แต่ละเดือน (ย้อนหลัง 6 เดือน)",
      listcompany: "รายชื่อบริษัททั้งหมดที่ส่ง Inbox ให้",
      numberofinbox: "จำนวนอินบ็อกซ์",
    },
    dashboardoutbox: {
      name: "แดชบอร์ดเอาท์บ็อกซ์",
      companyname: "ชื่อบริษัท",
      totaloutbox: "จำนวนเอาท์บ็อกซ์ทั้งหมด",
      totalfilesize: "ผลรวมขนาดไฟล์เอาท์บ็อกซ์ทั้งหมด",
      outbox_last6months: "จำนวนเอาท์บ็อกซ์ย้อนหลัง 6 เดือน",
      filesize_last6months: "ผลรวมขนาดไฟล์เอาท์บ็อกซ์ย้อนหลัง 6 เดือน",
      outboxpermonth: "จำนวน Outbox แต่ละเดือน (ย้อนหลัง 6 เดือน)",
      listcompany: "รายชื่อบริษัททั้งหมดที่ส่ง Outbox ให้",
      numberofoutbox: "จำนวนเอาท์บ็อกซ์",
    },
    myinbox: {
      Parentname: "ETAX - อินบ็อกซ์",
      Personalname: "อินบ็อกซ์ส่วนตัว",
      Businessname: "อินบ็อกซ์บริษัท",
      Trashinbox: "ถังขยะอินบ๊อกซ์"
    },
    myoutbox: {
      Parentname: "ETAX - เอาท์บ็อกซ์",
      Personalname: "เอาท์บ็อกซ์ส่วนตัว",
      Businessname: "เอาท์บ็อกซ์บริษัท",
      Trashoutbox: "ถังขยะเอาท์บ๊อกซ์"
    },
    allbusinessinbox: "อินบ็อกซ์ (ทุกบริษัท)",
    allbusinessoutbox: "เอาท์บ็อกซ์ (ทุกบริษัท)",
    allbusinessinbox_: "อินบ็อกซ์ (ผู้ซื้อ)",
    allbusinessoutbox_: "เอาท์บ็อกซ์ (ผู้ขาย)",
    morecolor: "สีเพิ่มเติม...",
    searching: {
      name: "การค้นหาไฟล์ขั้นสูง",
      aspect_name: "การค้นหาไฟล์ลักษณะเฉพาะ",
      button: "ค้นหา",
      searchfromdate: "วันที่อัปโหลดเอกสาร",
      searchtodate: "ถึง",
      detail: "คำสำคัญ / คำหลัก",
      keywordsearch: "ค้นหาแบบ Keyword",
      fulltextsearch: "ค้นหาแบบ Fulltext",
      optional_fulltextearch: "เงื่อนไข (ค้นหาแบบ Fulltext)",
      optional_folder_name: "โฟลเดอร์",
      optional_name_fulltextearch: "ชื่อไฟล์",
      optional_content_fulltextearch: "เนื้อหาไฟล์",
      optional_hashtag_fulltextearch: "แฮชแท็ก",
      searchby: "ค้นหาจาก",
      fileinfolder:'ค้นหาภายในโฟลเดอร์นี้',
      startenddate: "วันที่เริ่ม - สิ้นสุด",
      startdate:"วันที่เริ่ม",
      enddate:"วันสิ้นสุด",
      smartuploadsearch:"การค้นหาไฟล์แบบพิเศษ"
    },
    expiredfile: "ไฟล์หมดอายุ",
    report_problem: "แจ้งปัญหา",
    onebox_token: {
      header: "Onebox Token",
      copy: "คัดลอก",
      close: "ปิด",
      token_timeout: "Token หมดอายุการใช้งานแล้ว",
      token_time_using: "Token นี้สามารถใช้ได้แค่ 5 นาทีเท่านั้น"
    }
  },
  mainRClick: {
    uploadfile: "อัปโหลดไฟล์",
    newfolder: "สร้างโฟลเดอร์",
    uploadfolder: "อัปโหลดโฟลเดอร์",
    createfile: "สร้างไฟล์",
    createfile_complete: "สร้างไฟล์สำเร็จ",
    createfile_failed: "สร้างไฟล์ไม่สำเร็จ",
    open_file_when_complete : "เปิดไฟล์ เมื่อสร้างไฟล์สำเร็จ",
    open_folder_when_complete : "เปิดโฟลเดอร์ เมื่อสร้างโฟลเดอร์สำเร็จ"
  },
  currentRClick: {
    printPDF:'พิมพ์',
    setpassword:'ตั้งรหัสผ่าน',
    editpassword:"แก้ไขรหัสผ่าน",
    forgetpassword:"ลืมรหัสผ่าน",
    deletepassword:"ลบรหัสผ่าน",
    cancelexpired: "ยกเลิกการตั้งค่าวันหมดอายุ",
    editexpired: "แก้ไขวันหมดอายุ",
    setexpired: "ตั้งค่าวันหมดอายุ",
    open: "เปิด",
    opennewtab: "เปิดในแท็บใหม่",
    showinfolder: "แหล่งที่มา",
    deleterelationfile: "ลบความสัมพันธ์เอกสาร",
    managefile: "กำหนดสิทธิ์",
    relationfile: "เอกสารที่เกี่ยวข้อง",
    createrelationfile: "สร้างความสัมพันธ์เอกสาร",
    alldelete: "ลบข้อมูลทั่งหมด",
    alldownload: "ดาวน์โหลดทั้งหมด",
    sharelinkfile: "แชร์ลิงก์ข้อมูล",
    sharelinkupload: "แชร์ลิงก์สำหรับอัปโหลด",
    sharefile: "แชร์",
    rename: "แก้ไขชื่อ",
    editfile: "แก้ไขและล็อกไฟล์",
    unlockfile: "ปลดล็อกไฟล์",
    movetotrash: "นำเข้าถังขยะ",
    movefile: "ย้ายข้อมูล",
    addtostared: "เพิ่มไปยังรายการติดดาว",
    downloadfile: "ดาวน์โหลด",
    sendfiletomail: "ส่งไฟล์เข้าอีเมล",
    sendfiletoonechat: "ส่งไฟล์เข้า OneChat",
    removestarred: "ยกเลิกรายการติดดาว",
    preview: "ดูตัวอย่าง",
    previewnewtab: "ดูตัวอย่างในแท็บใหม่",
    newpreview: "ดูตัวอย่างใหม่",
    keydetail: "รายละเอียด",
    tagversion: "รายละเอียดเวอร์ชั่น",
    sendfilemenu:'ส่งไฟล์',
    sharefilemenu:'แชร์',
    pwdmenu:'รหัสผ่าน',
    editmenu:'แก้ไข',
    readfile: "อ่านไฟล์",
    editfileonline: "แก้ไขออนไลน์",
    editting: "กำลังแก้ไข",
    setprofile:"ตั้งค่ารูปภาพ",
    setrequestdownload:"ตั้งค่าคำขอดาวน์โหลด",
    requestdownload:"ส่งคำขอดาวน์โหลด",
  },
  trashRClick: {
    detail: "รายละเอียด",
    restore: "กู้คืน",
    forcedelete: "ลบถาวร",
  },
  tagVersion:{
    manageversion:"จัดการเวอร์ชั่น",
    viewversion:"เวอร์ชั่น",
    addversion:"เพิ่มเวอร์ชั่น",
    remark:"กรุณาใส่รายละเอียด",
    updatebtn:'สร้างเวอร์ชั่น',
    closebtn:'ปิด',
    activebtn:'ใช้งาน',
    deletebtn:'ลบ',
    selectfile:'เลือกไฟล์: ',
    addfiletitle:"เพิ่มไฟล์เข้า ",
    submitbtn:'ตกลง',
    uploadbtn:'อัปโหลดไฟล์',
    addbtn:'เลือกไฟล์จาก onebox',
    filelabel:'อัปโหลดไฟล์',
    nofolder: "ไม่มีโฟลเดอร์",
    nofile: "ไม่มีไฟล์",
    detail: "รายละเอียด",
    startuploadorselectpdf: "สามารถอัปโหลดหรือเลือกไฟล์ได้เฉพาะไฟล์นามสกุล",
    enduploadorselectpdf: "เท่านั้น",
    download: "ดาวน์โหลด"

  },

  sharelinkfile: {
    messageboxheader: "แชร์ลิงก์ไฟล์",
    statusYes: "เปิดการแชร์ลิงก์ไฟล์",
    statusNo: "ปิดการแชร์ลิงก์ไฟล์",
    title: "ทุกคนที่มีลิงก์สามารถเข้าถึง",
    copy: "คัดลอก",
    qrcodeOn: "เปิดคิวอาร์โค้ด",
    qrcodeOff: "ปิดคิวอาร์โค้ด",
    saveqrcode: "บันทึกคิวอาร์โค้ด",
    setting: "ตั้งค่า",
    cannotshare: "ไม่สิทธิ์แชร์ลิงก์ไฟล์ได้",
    limitheader:"กำหนดจำนวนครั้งในการเปิดลิงก์",
    numberopen_1:"จำนวนครั้งที่ถูกเปิดแล้ว",
    numberopen_2:"ครั้ง",
    reset:"รีเซ็ต",
    resetword:"รีเซ็ตจำนวนครั้งที่ถูกเปิดแล้ว",
    limitset:"กำหนดจำนวนครั้ง",
    permissionheader:"กำหนดสิทธิ์การแชร์ลิงก์",
    timeheader:"ตั้งเวลาหมดอายุลิงก์ที่แชร์",
    date_set:"วันที่",
    hours_set:"ชั่วโมง",
    minute_set:"นาที",
    passwordheader:"ตั้งรหัสผ่านในการเปิดลิงก์",
    copylinksucces: "คัดลอกลิงก์สำเร็จ",
    saveqrsucces: "บันทึกคิวอาร์โค้ดสำเร็จ",
    emptypassword: "กรุณากรอกรหัสผ่านลิงก์",
    // settingsharelinkfilesucces: "ตั้งค่าการแชร์ลิงก์สำเร็จ",
    settingsharelinkfilesucces: "ตั้งค่าการแชร์ลิงก์และคัดลอกลิงก์สำเร็จ",
    opensharelinkfilesucces: "เปิดการแชร์ลิงก์และคัดลอกลิงก์สำเร็จ",
    closesharelinkfilesucces: "ปิดการแชร์ลิงก์สำเร็จ",
    sharelinkfileerror: "ไม่สามารถแชร์ลิงก์ไฟล์ได้",
    emptylimitopenfile: "กรุณากำหนดจำนวนครั้งในการเปิดลิงก์",
    passwordleast: "กรุณาตั้งรหัสผ่านอย่างน้อย 6 ตัว",
    choosedate: "กรุณาเลือกวันที่หมดอายุลิงก์ที่แชร์",
    resetlimitopenfileerror: "ไม่สามารถรีเซ็ตจำนวนครั้งในการเปิดลิงก์ได้",
    sharelinkfilemanyerror: "กรุณากรอกข้อมูลให้ครบถ้วน",
    shareby: "แชร์โดย",
    setsharelinkfile: "ตั้งค่าการแชร์ลิงก์ไฟล์",
    entersetplease: "**กรุณาตั้งค่าเพื่อคัดลอกลิงก์",
    statusaccess: "การเข้าถึงทั่วไป",
    showqrcode: "แสดงคิวอาร์โค้ด",
    setwatermark: "ตั้งค่าการติดลายน้ำ",
    watermarlusefor: "สำหรับไฟล์นามสกุล pdf, png, jpeg, jpg, tiff เท่านั้น",
    anyonewithalink: "ทุกคนที่มีลิงก์",
    limit: "จำกัด",
    pleasefillemail: "กรุณากรอกอีเมล",
    emailformatinvalid: "รูปแบบอีเมลไม่ถูกต้อง",
    filename: "ชื่อไฟล์",
    foldername: "ชื่อโฟลเดอร์",
    type: "รูปแบบลายน้ำ",
    example_water: "ตัวอย่างลายน้ำ",
    feature_watermark: "ฟีทเจอร์พิเศษสามารถกำหนดรูปแบบลายน้ำได้",
    type_ll: "เอียงซ้าย",
    type_tir: "เอียงขวา",
    type_ctr: "ตรงกลาง (ซ้ำ)",
    type_tl: "บนซ้าย",
    type_tr: "บนขวา",
    type_cl: "กลางซ้าย",
    type_cr: "กลางขวา",
    type_bl: "ล่างซ้าย",
    type_br: "ล่างขวา",
    type_ct: "ตรงกลาง",
  },
  sharelinkupload: {
    messageboxheader: "แชร์ลิงก์สำหรับอัปโหลด",
    statusYes: "เปิดการแชร์ลิงก์สำหรับอัปโหลด",
    statusNo: "ปิดการแชร์ลิงก์สำหรับอัปโหลด",
    title: "ทุกคนที่มีลิงก์สามารถเข้าถึง",
    copy: "คัดลอก",
    qrcodeOn: "เปิดคิวอาร์โค้ด",
    qrcodeOff: "ปิดคิวอาร์โค้ด",
    saveqrcode: "บันทึกคิวอาร์โค้ด",
    setting: "ตั้งค่า",
    cannotshare: "ไม่สิทธิ์แชร์ลิงก์สำหรับอัปโหลดได้",
    numberopen_1:"จำนวนครั้งที่ถูกเปิดแล้ว",
    numberopen_2:"ครั้ง",
    timeheader:"ตั้งเวลาหมดอายุลิงก์ที่แชร์",
    date_set:"วันที่",
    hours_set:"ชั่วโมง",
    minute_set:"นาที",
    copylinksucces: "คัดลอกลิงก์สำเร็จ",
    saveqrsucces: "บันทึกคิวอาร์โค้ดสำเร็จ",
    settingsharelinkfilesucces: "ตั้งค่าการแชร์ลิงก์สำเร็จ",
    sharelinkfileerror: "ไม่สามารถแชร์ลิงก์ได้",
    choosedate: "กรุณาเลือกวันที่หมดอายุลิงก์ที่แชร์",
    sharelinkfilemanyerror: "กรุณากรอกข้อมูลให้ครบถ้วน",
    shareby: "แชร์โดย",
    setsharelinkfile: "ตั้งค่าการแชร์ลิงก์สำหรับอัปโหลด",
    entersetplease: "**กรุณาตั้งค่าเพื่อคัดลอกลิงก์",
    statusaccess: "การเข้าถึงทั่วไป",
    showqrcode: "แสดงคิวอาร์โค้ด",
    addremark: "เพิ่มหมายเหตุ",
    remark: "หมายเหตุ",
    notshared: "ยังไม่มีการแชร์",
  },
  sharefile: {
    messageboxheader: "แชร์กับผู้ใช้ Onebox รายอื่น",
    oksharefile: "อัปเดตสถานะ",
    edit: "แก้ไข",
    view: "ดูเท่านั้น",
    download:"ดาวน์โหลด",
    upload:"อัปโหลด",
    delete:"ลบ",
    Email: "อีเมล",
    Status: "สถานะ",
    sharewith: "แชร์กับ",
    share: "แชร์",
    sharefoldersuccess: "แชร์โฟลเดอร์สำเร็จ",
    sharefilesuccess: "แชร์ไฟล์สำเร็จ",
    editsharefile: "การตั้งค่าการแชร์",
    pleasefill_email: "กรุณากรอกอีเมล",
    pleasefill_username: "กรุณากรอกชื่อผู้ใช้งาน",
    pleasefill_onecollab: "กรุณากรอกอีเมล (One Collab)",
    pleasefill_type: "กรุณาเลือกประเภทผู้ใช้งาน",
    emptytaxid: "กรุณากรอกเลขประจำตัวผู้เสียภาษี (บริษัท)",
    cannotshare: "ไม่สามารถแชร์ผู้ใช้นี้ได้",
    cannotemail: "ไม่สามารถใช้อีเมลล์ซ้ำได้",
    deletesuc: "ลบข้อมูลแล้ว",
    cannotsharefile: "ไม่สามารถแชร์ไฟล์ให้ตัวเองได้",
    cannotsharefolder: "ไม่สามารถแชร์โฟล์เดอร์ให้ตัวเองได้",
    taxidbusiness: "เลขประจำตัวผู้เสียภาษี (บริษัท)",
    settime: "ตั้งเวลาแชร์",
    closedatepiker: "ปิด",
    setpermissionsharefile: "กำหนดสิทธิ์การแชร์ไฟล์",
    setdate:"วันที่",
    sethours:"ชั่วโมง",
    settime:"เวลา",
    setminute:"นาที",
    submitsharefile: "ยืนยันการยกเลิกการแชร์",
    with: "กับ",
    notidelete1: "คุณต้องการยกเลิกการแชร์",
    notidelete2: "หรือไม่ ?",
    business: "บริษัท",
    notfoundtaxid: "ไม่พบบริษัทจากเลขประจำตัวผู้เสียภาษีนี้",
    notfoundemail: "ไม่พบอีเมลนี้",
    file: "ไฟล์",
    folder: "โฟลเดอร์",
    personal:"ผู้ใช้งานทั่วไป",
    type:"ประเภทผู้ใช้งาน",
    name:"ชื่อ",
    share_detail:"รายละเอียดการแชร์",
    file_name:"ชื่อไฟล์/โฟลเดอร์",
    business_name:"ชื่อบริษัท",
    sharing_not_found:"ไม่พบข้อมูลการแชร์",
    save:"บันทึก",
    cancel_sharing:"ยกเลิกการแชร์",
    no_date_set:"ไม่กำหนดวันที่"
  },

  myinboxPage: {
    include_duplicate_files:"Include duplicate files (รวมรายการที่ซ้ำกัน)",
    summary_item:"Summary Item (1 ไฟล์ 1 บรรทัด)",
    search_attachments:"ค้นหาไฟล์แนบ",
    restore_filter:"กู้คืนจากฟิลเตอร์",
    delete_filter:"ลบจากฟิลเตอร์",
    search_file:"จำนวนเอกสารที่ค้นพบ :",
    yyyy_mm_dd:"ปี(ค.ศ.) - เดือน - วัน",
    clear_data:"เคลียร์ข้อมูล",
    loading:"กำลังดาวน์โหลด",
    company:"บริษัท",
    Filedetails: "รายละเอียดไฟล์",
    Relateddocument: "เอกสารที่เกี่ยวข้อง",
    create_relation:"เพิ่ม Relation",
    export: "ดาวน์โหลดไฟล์ทั้งหมด",
    exportcsv: "ดาวน์โหลดไฟล์ทั้งหมด(CSV)",
    exportexcel: "ดาวน์โหลดไฟล์ทั้งหมด(Excel)",
    storageisfull: "พื้นที่จัดเก็บข้อมูลเต็ม",
    search: "ค้นหา",
    Nodata: "ไม่พบข้อมูล",
    items: "รายการ",
    search_:" ค้นหาเจอ",
    typecompany: "บริษัท",
    typefiles: "รายการ",
    consent: "ความยินยอม",
    openConsent: "เปิดรับ",
    stipulation: "ดูข้อกำหนด",
    contactadmin: "กรุณาติดต่อแอดมินของบริษัทเพื่ออนุมัติ",
    contactadmin1: "การยินยอมเพื่อรับเอกสารอินบ็อกซ์",
    contactadmin2: "(สิทธิ์แอดมินของบริษัทเท่านั้น)",
    contactadmin__: "กรุณาติดต่อแอดมินของบริษัทเพื่อรับเอกสารอินบ็อกซ์",
    accept: "ยินยอม",
    reject: "ไม่ยินยอม",
    HeaderSourceSystem: {
      systemname: "ระบบ"
    },
    msg: {
      nofile: "กรุณาเลือกไฟล์ที่ต้องการทำรายการ  ",
      apierror: "ไม่สามารถดาวน์โหลดไฟล์ได้ !!!",
      pleasechoosepdf: "โปรดเลือกเฉพาะไฟล์ PDF",
      norenamedocument: "ไม่สามารถเปลี่ยนชื่อไฟล์นี้ได้",
    },
    dowload: {
      dowloading: "กำลังดาวน์โหลด",
      dowloadsuccess:"ดาวน์โหลดสำเร็จ",
      dowloading_print: "กำลังรวมเป็นไฟล์เดียว",
      dowloadingsuccess_print: "รวมไฟล์สำเร็จ",
      downloadpreparing: "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...",
    },
    multipledownload: "ดาวน์โหลด",
    conditionFile: "ค้นหาเอกสาร",
    conditionOptional: {
      ponumber: "PO Number",
      customerid: "เลขประจำตัวลูกค้า",
      filename: "ชื่อไฟล์เอกสาร",
      fileextension: "ประเภทไฟล์",
      filedocumentid: "เลขที่เอกสาร",
      doctype: "ประเภทเอกสาร",
      taxrefund: "สถานะขอคืนภาษี",
      taxabb: "สถานะขอใบกำกับภาษี",
      sendername: "ชื่อผู้ส่ง",
      sendername2: "ชื่อผู้เสียภาษี(ผู้ขาย)",
      receivername: "ชื่อผู้รับ",
      receivername2: "ชื่อผู้เสียภาษี(ผู้ซื้อ)",
      sendername_: "ชื่อผู้ขาย",
      receivername_: "ชื่อผู้ซื้อ",
      systemname: "ระบบ",
      sellertaxid: "เลขประจำตัวผู้เสียภาษีผู้ส่ง",
      sellertaxid2: "เลขประจำตัวผู้เสียภาษี(ผู้ขาย)",
      sellerbranchid: "เลขที่สาขาผู้ส่ง",
      buyertaxid: "เลขประจำตัวผู้เสียภาษีผู้รับ",
      buyertaxid2: "เลขประจำตัวผู้เสียภาษี(ผู้ซื้อ)",
      buyerbranchid: "เลขที่สาขาผู้รับ",
      sellertaxid_: "เลขประจำตัวผู้เสียภาษีผู้ขาย",
      sellerbranchid_: "เลขที่สาขาผู้ขาย",
      buyertaxid_: "เลขประจำตัวผู้เสียภาษีผู้ซื้อ",
      buyerbranchid_: "เลขที่สาขาผู้ซื้อ",
      // fromdateupload: "วันที่ส่งเอกสาร",
      fromdateupload: "วันที่ได้รับเอกสาร",
      rulefromdateupload: "กรุณากรอกวันที่ต้องการค้นหา",
      todateupload: "ถึง",
      ruletodateupload: "กรุณากรอกวันที่ต้องการค้นหา",
      closefromdateupload: "ปิด",
      closetodateupload: "ปิด",
      fromdatedocument: "เอกสารลงวันที่",
      from_datedocument: "วันที่",
      rulefromdatedocument: "กรุณากรอกวันที่เอกสาร",
      todatedocument: "ถึง",
      to_datedocument: "ถึง",
      ruletodatedocument: "กรุณากรอกวันที่เอกสาร",
      closefromdatedocument: "ปิด",
      closetodatedocument: "ปิด",
      chanel:"ช่องทาง",
      status:'สถานะ'
    },
    settingHeader: {
      setting: "ตั้งค่าการจัดการตาราง",
      submit: "ตกลง"
    }
  },

  myoutboxPage: {
    include_duplicate_files:"Include duplicate files (รวมรายการที่ซ้ำกัน)",
    summary_item:"Summary Item (1 ไฟล์ 1 บรรทัด)",
    search_attachments:"ค้นหาไฟล์แนบ",
    restore_filter:"กู้คืนจากฟิลเตอร์",
    delete_filter:"ลบจากฟิลเตอร์",
    yyyy_mm_dd:"ปี(ค.ศ.) - เดือน - วัน",
    clear_data:"เคลียร์ข้อมูล",
    exportcsv: "ดาวน์โหลดไฟล์ทั้งหมด(CSV)",
    exportexcel: "ดาวน์โหลดไฟล์ทั้งหมด(Excel)",
    export: "ดาวน์โหลดไฟล์ทั้งหมด",
    add_relation:"เพิ่ม Relation",
    search: "ค้นหา",
    Nodata: "ไม่พบข้อมูล",
    items: "รายการ",
    typecompany: "บริษัท",
    typefiles: "รายการ",
    HeaderSourceSystem: {
      systemname: "ระบบ"
    },
    msg: {
      nofile: "กรุณาเลือกไฟล์ที่ต้องการดาวน์โหลด !!! ",
      apierror: "ไม่สามารถดาวน์โหลดไฟล์ได้ !!!",
      pleasechoosepdf: "โปรดเลือกเฉพาะไฟล์ PDF", 
      nodocumentstodownload: "ไม่มีเอกสารให้ดาวน์โหลด",
      norenamedocument: "ไม่สามารถเปลี่ยนชื่อไฟล์นี้ได้",
    },
    multipledownload: "ดาวน์โหลด",
    conditionFile: "ค้นหาเอกสาร",
    conditionOptional: {
      ponumber: "PO Number",
      customerid: "รหัสลูกค้า",
      filename: "ชื่อไฟล์เอกสาร",
      fileextension: "ประเภทไฟล์",
      filedocumentid: "เลขที่เอกสาร",
      doctype: "ประเภทเอกสาร",
      sendername: "ชื่อผู้ส่ง",
      sendername_: "ชื่อผู้ขาย",
      receivername_: "ชื่อผู้ซื้อ",
      receivername: "ชื่อผู้รับ",
      systemname: "ระบบ",
      sellertaxid: "เลขประจำตัวผู้เสียภาษีผู้ขาย",
      sellerbranchid: "เลขที่สาขาผู้ขาย",
      buyertaxid: "เลขประจำตัวผู้เสียภาษีผู้ซื้อ",
      buyerbranchid: "เลขที่สาขาผู้ซื้อ",
      sellertaxid_: "เลขประจำตัวผู้เสียภาษีผู้ขาย",
      sellerbranchid_: "เลขที่สาขาผู้ขาย",
      buyertaxid_: "เลขประจำตัวผู้เสียภาษีผู้ซื้อ",
      buyerbranchid_: "เลขที่สาขาผู้ซื้อ",
      fromdateupload: "วันที่อัปโหลดเอกสาร",
      rulefromdateupload: "กรุณากรอกวันที่อัปโหลดเอกสาร",
      todateupload: "ถึง",
      ruletodateupload: "กรุณากรอกวันที่อัปโหลดเอกสาร",
      closefromdateupload: "ปิด",
      closetodateupload: "ปิด",
      fromdatedocument: "เอกสารลงวันที่",
      rulefromdatedocument: "กรุณากรอกวันที่เอกสาร",
      todatedocument: "ถึง",
      ruletodatedocument: "กรุณากรอกวันที่เอกสาร",
      namefolder: "ชื่อโฟลเดอร์",
      namecustomer: "ชื่อลูกค้า",
      receivernameextension: "ประเภทผู้รับ(มี Box/ไม่มี Box)",
      closefromdatedocument: "ปิด",
      closetodatedocument: "ปิด",
      chanel:"ช่องทาง",
      statussendonechat:"สถานะส่ง Onechat"
    },
    buttonAction: {
      relatedFile: "เอกสารที่เกี่ยวข้อง",
      fileDetail: "รายละเอียดไฟล์",
      download: "ดาวน์โหลด"
    }
  },
  mutideletedocinboxoutbox: {
    listofdoc: "รายการเอกสารที่ต้องการลบ",
    fileselect: "เอกสารที่เลือก",
    permissiondenied: "ไม่สามารถลบเอกสารนี้ได้",
    permissiongranted: "สามารถลบเอกสารนี้ได้",
    deletedoc: "กำลังลบเอกสาร",
    deletedocsuccess: "ลบสำเร็จ",
    itemsmutiinout: "รายการ",
    itemmutiinout: "รายการ",
    cannotdelete: "ไม่สามารถลบเอกสารได้",
    canceldelete: "ยกเลิกลบเอกสาร"
  },
  dcmPage: {
    conditionFile: "ค้นหาเอกสาร",
    conditionFile_: "เงื่อนไขการค้นหาเอกสาร",
    searchfileattachments :"ค้นหาเอกสารแบบ ไฟล์แนบ",
    download_attachments: "ดาวน์โหลดไฟล์แนบ",
    please_attach_file:"กรุณาแนบไฟล์เอกสาร",
    error_searchfileattachments_1: 'ไม่พบคอลัมน์ "Document Number" ในไฟล์แนบ',
    error_searchfileattachments_2: "กรุณาแนบไฟล์ Excel (.xlsx)",
    error_searchfileattachments_3: 'กรุณากรอกข้อมูลในคอลัมน์ "Document Number"',
    conditionOptional: {
      fromdateupload: "วันที่ได้รับเอกสาร",
      rulefromdateupload: "กรุณากรอกวันที่ได้รับเอกสาร",
      todateupload: "ถึง",
      ruletodateupload: "กรุณากรอกวันที่อัปโหลดเอกสาร",
      closefromdateupload: "ปิด",
      closetodateupload: "ปิด",
      fromdatedocument: "เอกสารลงวันที่",
      rulefromdatedocument: "กรุณากรอกวันที่เอกสาร",
      todatedocument: "ถึง",
      ruletodatedocument: "กรุณากรอกวันที่เอกสาร",
      closefromdatedocument: "ปิด",
      closetodatedocument: "ปิด",
    }
  },

  trashPage: {
    messageboxheader: "ลบถาวรหรือไม่",
    messageboxsubheader: " ไฟล์หรือโฟลเดอร์ 1 รายการกำลังจะถูกลบอย่างถาวร",
    messageboxsubheader2: "คำเตือน: หากเลือก ลบถาวร จะไม่สามารถกู้คืนเอกสารได้อีก",
    forcedelete: "ลบถาวร",
    canceldelete: "ยกเลิก",
    cleartrash: "ล้างถังขยะ",
    cleartrashfilefolder: "ล้างไฟล์/โฟลเดอร์ในถังขยะหรือไม่ ?",
    confrimmuti: "ไฟล์/โฟลเดอร์ที่เลือกจะถูกลบออกถาวร",
    confrimdeleteall1: "ไฟล์/โฟลเดอร์ทั้งหมดจะถูกลบออกถาวร",
    confrimdeleteall2: "หมายเหตุ: ข้อมูลจะถูกสำรองไว้ 7 วันหลังการลบไฟล์/โฟลเดอร์ เมื่อครบกำหนดไฟล์/โฟลเดอร์จะถูกลบออกถาวร",
    preparedelete: "กำลังเตรียมการลบไฟล์/โฟลเดอร์",
    cancel_delete: "ยกเลิกการลบ",
    deletesuccess: "ลบไฟล์/โฟลเดอร์สำเร็จ",
    deletingfilefolder: "กำลังลบไฟล์/โฟลเดอร์",
    nofileandfolderintrash: "ไม่มีไฟล์/โฟลเดอร์ในถังขยะ",
    detaildelete: "รายละเอียดการลบไฟล์/โฟล์เดอร์",
    cannotdelete: "มีไฟล์/โฟลเดอร์ที่ลบไม่สำเร็จ",
  },

  manual: {
    businessbox: "คู่มือการสมัคร Business Box",
    oneboxmanual: "คู่มือการใช้งาน One Box",
    etax: "คู่มือสำหรับค้นหาไฟล์ e-Tax Invoice",
    useonebox: "VDO การใช้งาน One Box",
    storage: "VDO การซื้อ Storage เพิ่มด้วยตนเอง",
    manualfail: "คู่มือไม่สามารถใช้งานได้ในขณะนี้ กรุณาติดต่อผู้ดูแลระบบ"
  },

  uploadfile: {
    name: "อัปโหลดไฟล์",
    buttonupload: "อัปโหลดไฟล์",
    donotshare: "ไม่สามารถอัปโหลดไฟล์ในส่วนแชร์ร่วมกันได้",
    buttonclose: "ปิด",
    clicktoupload: "ลากไฟล์มาวางที่นี่ หรือ <a class=' filepond--label-action' color='primary'>คลิกเพื่ออัปโหลด</a>",
    maxsizeuploadtext: "***สามารถอัปโหลดไฟล์ขนาดไม่เกิน 250 GB ต่อ  1 ไฟล์***",
    upload_excel: "อัปโหลด Excel",
    list_of_employees:"รายชื่อพนักงาน",
    download_form: "ดาวน์โหลดตัวอย่างฟอร์ม",
    clicktouploadnew: "ลากไฟล์มาวางที่นี่ หรือ คลิกเพื่ออัปโหลด !",
    uploadfail: "อัปโหลดไฟล์ไม่สำเร็จ",
    loadingfile: "กำลังโหลด",
    fileduplicate: "ไฟล์นี้เป็นไฟล์ซ้ำ",
    uploadsuccess: "อัปโหลดไฟล์สำเร็จ",
    taptoretry: "กดเพื่ออัปโหลดอีกครั้ง",
    uploadingfile: "กำลังอัปโหลด",
    cancelingfile: "กำลังยกเลิกการอัปโหลด",
    reverterror: "อัปโหลดอีกครั้งไม่สำเร็จ",
    taptocancel: "กดไอคอนโหลดเพื่อยกเลิก",
    taptoundo: "กดเพื่อเลิกทำ",
    success: "สำเร็จ",
    fail: "ไม่สำเร็จ",
    duplicate: "ซ้ำ",
    file: "ไฟล์",
    files: "ไฟล์"
  },
  uploadfolder: {
    name: "สร้างโฟลเดอร์",
    buttonupload: "สร้างโฟลเดอร์",
    textfoldername: "ชื่อโฟลเดอร์",
    donotshare: "ไม่สามารถสร้างโฟลเดอร์ในส่วนแชร์ร่วมกันได้",
    buttonclose: "ปิด",
    createsucess: "สร้างโฟลเดอร์เรียบร้อย",
    cannotcreate: "ไม่สามารถสร้างโฟลเดอร์ได้ในขณะนี้",
    nameduplicate: "ชื่อโฟลเดอร์ซ้ำ โปรดเปลี่ยนชื่อ",
    nospecialcharacter: `ชื่อโฟลเดอร์ต้องไม่มีอักขระพิเศษ < > : * ? \\ | / " '`,
    enternamefolder: "กรุณากรอกชื่อโฟลเดอร์"
  },
  inputuploadfolder: {
    uploadfoldersuccess: "อัปโหลดสำเร็จ",
    cannotchoosefile: "ไม่สามารถเลือกไฟล์ในขณะมีกำลังอัปโหลด",
    detailupload: "รายละเอียด",
    uploadfail: "อัปโหลดไม่สำเร็จ",
    uploaddup: "ไฟล์/โฟลเดอร์ซ้ำ",
  },
  errormessageforupload: {
    cannotcheckduplicate: "ไม่สามารถตรวจสอบไฟล์/โฟลเดอร์ซ้ำได้",
    er001pleaseinputfiledup: "กรุณาส่ง file_duplicate ('create_copy' หรือ 'create_tag_version')",
    er102sizemore5gb: "ขนาดไฟล์เกิน 5 GB",
    er102filehaveexist: "ไฟล์นี้มีอยู่แล้ว",
    er403accountnotexist: "ไม่มีผู้ใช้บัญชีนี้",
    er403Cannotfindfile: "ไม่พบไฟล์",
    er403Cannotfindfolder: "ไม่พบโฟลเดอร์",
    er403cannotuploadfiletype: "คุณไม่สามารถอัปโหลดไฟล์ประเภทนี้ได้ เนื่องจากผู้ดูแลระบบของบริษัทคุณได้บล็อคไว้",
    er404authfail: "Authentication ไม่ถูกต้อง",
    er404allowuploadfile: "คุณไม่มีสิทธิ์อัพโหลดไฟล์นี้",
    er404allowuploadfolder: "คุณไม่มีสิทธิ์อัพโหลดโฟลเดอร์นี้",
    er405keymissing: "key (user_id, folder_id, file_duplicate, remark) หายไป",
    er500cannotfindfolderid: "ไม่พบ folder_id นี้ในระบบ",
    er500cannotfindfileid: "ไม่พบ file_id นี้ในระบบ",
    er500notfoundaccount: "ไม่พบ account_id นี้ในระบบ",
    er500permissionhandlenotcorrect: "permission_handle ไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง",
    cannotupload: "ไม่สามารถอัปโหลดไฟล์/โฟลเดอร์ได้ กรุณาติดต่อผู้ดูแลระบบ",
  },
  tablefile: {
    resendmail: "ส่งไฟล์อีกครั้ง",
    warning_expiration_time: "วันที่แจ้งเตือนไฟล์หมดอายุ",
    expiredinbox:"วันที่ไฟล์หมดอายุ",
    filenameonly: "ชื่อไฟล์",
    filename: "ชื่อไฟล์ / โฟลเดอร์",
    filesize: "ขนาดไฟล์",
    location: "ตำแหน่งที่ตั้ง",
    fileposition: "ตำแหน่งไฟล์",
    option: "จัดการไฟล์",
    owner: "ผู้สร้าง",
    version: "เวอร์ชั่น",
    date: "วันที่สร้าง",
    docno: "เลขที่เอกสาร",
    docdate: "เอกสารลงวันที่",
    doctype: "ประเภทเอกสาร",
    sendername: "ผู้ส่ง",
    sendername_: "ผู้ขาย",
    inboxdate: "วันที่ได้รับเอกสาร",
    taxrefund: "สถานะขอคืนภาษี",
    sharedate: "วันที่แชร์",
    shareby: "แชร์โดย",
    createdate: "วันที่อัปโหลดไฟล์",
    download: "ดาวน์โหลด",
    lastmodified: "วันที่แก้ไข",
    trashdtm:"วันที่ลบ",
    remaindays:"จำนวนวันคงเหลือ",
    restore: "กู้คืน",
    empty: "โฟลเดอร์นี้ว่างเปล่า",
    empty_resend:"ไม่พบข้อมูล",
    emptysearch:"ไม่พบไฟล์ / โฟลเดอร์ ที่ค้นหา",
    searchtext:"ผลการค้นหา",
    receivername: "ผู้รับ",
    receivername_: "ผู้ซื้อ",
    outboxdate: "วันที่ส่งเอกสาร",
    doc_id: "รหัสเอกสาร",
    date_effect: "วันที่บังคับใช้",
    tag_version: "แก้ไขครั้งที่",
    fileuse: "พื้นที่ที่ใช้ไป",
    filetotalstorage: "พื้นที่เก็บทั้งหมด",
    expiration_time: "วันที่หมดอายุ",
    permission: "สิทธิ์การเข้าถึง",
    status:"สถานะ",
    status_onechat: "สถานะส่ง Onechat",
    ogposition:"ตำแหน่งเดิม",
    lastupdatename:"ผู้ลบ",
    po_number: "PO Number",
    count: "จำนวนไฟล์",
    loadingitems: "กำลังโหลด..."
  },

  allbusinessPage: {
    tablebusinesslist: {
      business: "บริษัท",
      record: "รายการ"
    },
    inbox: {
      title: "อินบ็อกซ์ (ทุกบริษัท)",
      title_: "อินบ็อกซ์ (ผู้ซื้อ)",
      fromdate: "จาก",
      todate: "ถึง",
      close: "ปิด"
    },
    outbox: {
      title: "เอาท์บ็อกซ์ (ทุกบริษัท)",
      title_: "เอาท์บ็อกซ์ (ผู้ขาย)",
      fromdate: "จาก",
      todate: "ถึง",
      close: "ปิด"
    }
  },

  multirightclick: {
    download: "ดาวน์โหลดที่เลือก",
    delete: "ลบที่เลือก",
    star: "ติดดาวที่เลือก",
    movefiles: "ย้ายข้อมูล",
    hashtag: "เพิ่มแฮชแท็ก",
    copyfile: "ทำสำเนา"
  },

  keydetail: {
    path: "ตำแหน่ง",
    details: "รายละเอียด",
    general: "ข้อมูลทั่วไป",
    filename: "ชื่อ",
    filesize: "ขนาด",
    document_no: "เลขที่เอกสาร",
    document_type: "ประเภทเอกสาร",
    cre_dtm: "วันที่ได้รับเอกสาร",
    receiver_name: "ผู้รับ",
    sender_name: "ผู้ส่ง",
    filetype: "ชนิด",
    filedate: "วันที่อัปโหลด",
    closeDialog: "ปิด",
    owner: "ผู้สร้าง",
    month: {
      jan: "ม.ค.",
      feb: "ก.พ.",
      mar: "มี.ค.",
      apr: "เม.ษ.",
      may: "พ.ค.",
      jun: "มิ.ย.",
      jul: "ก.ค.",
      aug: "ส.ค.",
      sep: "ก.ย.",
      oct: "ต.ค.",
      nov: "พ.ย.",
      dec: "ธ.ค."
    },
    cannotopendatafile: "ไม่สามารถเรียกข้อมูลไฟล์ได้ในขณะนี้",
    permission: "สิทธิ์การเข้าถึง",
    expiration: "วันหมดอายุ",
    edit_download:"แก้ไขและดาวน์โหลด",
    downloadonly:"ดาวน์โหลดเท่านั้น",
    viewonly:"ดูเท่านั้น",
    name_user:"ชื่อ",
    modified_date:"วันที่ดำเนินการ",
    activity:"บันทึกกิจกรรม",
    no_data_activity : "ไม่พบบันทึกกิจกรรม"
  },
  shareRClick: {
    rename: "แก้ไขชื่อ",
    movetotrash: "ยกเลิกการแชร์",
    movetotrash_: "นำเข้าถังขยะ",
    addtostared: "เพิ่มไปยังรายการติดดาว",
    downloadfile: "ดาวน์โหลด",
    removestarred: "ยกเลิกรายการติดดาว",
    preview: "ดูตัวอย่าง",
    listshare: "ข้อมูลการแชร์",
    hide:'ซ่อน',
    unhide:'นำกลับ',
  },

  greeting: "Welcome to Your Vue.js App",
  language_picker_helper: "Choose a language",
  languages: {
    english: "English",
    thai: "ภาษาไทย"
  },
  changeName: {
    nospecialcharacter: `ชื่อไฟล์/โฟลเดอร์ ต้องไม่มีอักขระพิเศษ < > : * ? \\ | / " '`,
    changeName: "เปลี่ยนชื่อ",
    close: "ปิด",
    submit: "ตกลง",
    changeSuccess: "เปลี่ยนชื่อสำเร็จ",
    noChange: "ไม่สามารถเปลี่ยนชื่อได้",
    filename: "ชื่อ",
    addfilename: "กรุณากรอกชื่อ ไฟล์/โฟลเดอร์",
    addurl: "ระบุ URL",
    addname: "ระบุชื่อ",
  },
  sentFileOnechat: {
    header: "ส่งไฟล์เข้า OneChat",
    friend: "เพื่อน",
    nofriend: "คุณไม่มีเพื่อนใน OneChat",
    close: "ปิด",
    choose:"เลือก",
    send: "ส่ง",
    cannotsendfolder: "ไม่สามารถส่ง folder ได้ในขณะนี้",
    sendfile: "ส่งไฟล์เข้า OneChat เรียบร้อย",
    sendding:"กำลังส่งไฟล์ไปยัง onechat",
    choosefriend:"ผู้รับที่เลือก",
    success:"เรียบร้อย",
    cannotsendfile: "ไม่สามารถส่ง file ได้ในขณะนี้",
    addreceiver: "กรุณาเลือกผู้รับไฟล์",
  },
  sendtoemail: {
    sendtomail: "ส่งไฟล์เข้าอีเมล",
    to: "ถึง :",
    subject: "เรื่อง :",
    buttonclose: "ปิด",
    buttonsend: "ส่งอีเมล",
    sendemailsuccess: "ส่งอีเมลเรียบร้อย",
    error: "ไม่สามารถส่งอีเมลได้ในขณะนี้ ",
    cannotsendfolder: "ไม่สามารถส่ง folder ได้ในขณะนี้",
    pleasefillemail: "กรุณากรอกอีเมลผู้รับ",
    invalidemailformat: "รูปแบบอีเมลไม่ถูกต้อง"
  },
  multirecovery: {
    taball: 'ทั้งหมด',
    tabnonerecovery_success: 'รอย้ายไฟล์',
    tabrecovery_success: 'กู้คืนสำเร็จ',
    selectAll: "เลือกทั้งหมด",
    folder: "โฟลเดอร์",
    file: "ไฟล์",
    folderselected: "โฟลเดอร์ที่เลือก",
    fileselected: "ไฟล์ที่เลือก",
    document_recovering: "กำลังกู้คืนเอกสาร",
    recovering: "กำลังกู้คืน",
    recovery_cancel: "ยกเลิกกู้คืน",
    list: "รายการ",
    list_filefolder_recov: "รายการเอกสารที่ต้องการกู้คืน",
    filenoparent: "ไฟล์/โฟลเดอร์นี้ไม่มีแหล่งที่มา",
    filenopermission: "แหล่งที่มาไม่มีสิทธิ์กู้คืน",
    deletepermission: "ไม่มีสิทธิ์ลบไฟล์/โฟลเดอร์นี้",
    movefilefolder: "ย้าย ไฟล์/โฟลเดอร์",
    move: "ย้าย",
    moving: "กำลังย้าย",
    movingfilefolder: 'กำลังย้ายไฟล์และโฟลเดอร์',
    movesuccess: 'ย้ายไฟล์/โฟลเดอร์สำเร็จ',
    filefoldermoved: "ไฟล์/โฟลเดอร์นี้ถูกย้ายแล้ว",
    allowpermission: "มีสิทธิ์กู้คืนไฟล์/โฟลเดอร์",
    document_allowpermission: "มีสิทธิ์กู้เอกสาร",
    notallowpermission: "ไม่มีสิทธิ์กู้คืนไฟล์/โฟลเดอร์",
    document_notallowpermission: "ไม่สามารถกู้เอกสารได้",
    document_fail_recovered: "กู้คืนเอกสารไม่สำเร็จ",
    recoverror: "ไม่สามารถกู้คืนไฟล์/โฟลเดอร์นี้ได้",
    document_recovered: "กู้คืนเอกสารสำเร็จ",
    recovsuccess: "กู้คืนไฟล์/โฟลเดอร์สำเร็จ",
    recovfail: "กู้คืนไฟล์/โฟลเดอร์ไม่สำเร็จ",
    waittomove: "รอย้ายไฟล์",
    waittomovefilefolder: "รอย้ายไฟล์/โฟลเดอร์",
    recovbtn: "กู้คืน"
  },
  multimovefiles: {
    selectfile: "ไฟล์/โฟลเดอร์ที่เลือก",
    moveto: "ย้ายไปที่",
    move: "ย้าย",
    permission_denied: "ไม่มีสิทธิ์ย้าย",
    permission_granted: "มีสิทธิ์ย้าย",
    filefoldermomove1: "ไฟล์/โฟลเดอร์ ",
    filefoldermomove2: " รายการ ที่ไม่สามารถย้ายได้",
  },
  movefiles: {
    movefiles: "ย้ายไฟล์",
    buttonclose: "ปิด",
    move: "ย้าย",
    maveat: "ย้ายมาที่นี่",
    success: "สำเร็จ",
    nosuccess: "ไม่สำเร็จ",
    nofolder: "ไม่มีข้อมูล",
    movefolder: " ย้ายโฟลเดอร์",
    permission_denied: "ไม่มีสิทธิ์ย้าย",
    cannotmovefile: "ไม่สามารถย้ายไฟล์ได้",
    movefoldersuccess: "ย้ายโฟลเดอร์สำเร็จ",
    cannotmovefolder: "ไม่สามารถย้ายโฟลเดอร์ได้",
    filenomove: "ไฟล์นี้ยังอยู่ในโฟลเดอร์เดิม กรุณาเลือกโฟลเดอร์ที่จะย้าย",
    foldernomove:
      "โฟล์เดอร์นี้ยังอยู่ในโฟลเดอร์เดิม กรุณาเลือกโฟลเดอร์ที่จะย้าย",
    choosetargetfolder: "กรุณาเลือกโฟลเดอร์ที่จะย้าย",
    mutifilefoldernomove: "ไฟล์/โฟลเดอร์ทั้งหมดยังอยู่ในโฟลเดอร์เดิม กรุณาเลือกโฟลเดอร์ที่จะย้าย",
    muticannotmove: "ไม่สามารถย้ายไฟล์/โฟลเดอร์ได้",
    multicannotmovesomefilefolder: "ไม่สามารถย้ายไฟล์/โฟลเดอร์บางรายการหรือทั้งหมดได้",
    multisuccess: "ย้ายไฟล์/โฟลเดอร์สำเร็จ",
    lockfilefolder: "ไม่สามารถย้ายไฟล์/โฟลเดอร์ได้เนื่องจากโฟลเดอร์ถูกล็อค",
    permissionnotallow: "ไม่มีสิทธิ์ย้ายลงโฟลเดอร์นี้",
    choosetargetallowfolder: "โปรดเลือกโฟลเดอร์ที่มีสิทธิ์ย้าย",
  },
  Filter: {
    name: "ชื่อไฟล์ / โฟลเดอร์",
    size: "ขนาดไฟล์",
    position: "ตำแหน่งไฟล์",
    date: "วันที่อัปโหลด",
    outboxdate: "วันที่ส่งเอกสาร",
    docno: "เลขที่เอกสาร",
    docdate: "เอกสารลงวันที่",
    nameonly: "ชื่อไฟล์",
    inboxdate: "วันที่ได้รับเอกสาร",
    shareby: "แชร์โดย",
    owner: "ผู้สร้าง",
    sharedate: "วันที่แชร์",
    doctype: "ประเภทเอกสาร",
    sendname: "ผู้ส่ง",
    receivername: "ผู้รับ",
    sendnameAll: "ผู้ขาย",
    receivernameAll: "ผู้ซื้อ",
    status: "สถานะไฟล์",
    expiredinbox:"วันหมดอายุไฟล์"

  },
  uploadFile: {
    header: "อัปโหลดไฟล์",
    upload: "กำลังอัปโหลดไฟล์",
    uploadsuccess: "อัปโหลดไฟล์สำเร็จ",
    cannotupload: "ไม่สามารถอัปโหลดไฟล์ได้",
    dropfile: "วางไฟล์/โฟลเดอร์เพื่ออัปโหลด",
    movedropfile: "วางไฟล์/โฟลเดอร์เพื่อย้าย",
  },
  sharelist: {
    headermessage: "ข้อมูลการแชร์",
    sharewith: "แชร์กับ",
    cancelsharefile: "ยกเลิกแชร์ไฟล์",
    cancelbutton: "ยกเลิก"
  },
  theme: "ธีม",
  admin: {
    groupname:"ชื่อกลุ่ม",
    creategroup:"สร้างกลุ่ม",
    unlock_complete: "ปลดล็อคไฟล์/โฟลเดอร์สำเร็จ",
    unlock_notcomplete: "ปลดล็อคไฟล์/โฟลเดอร์ไม่สำเร็จ",
    consent: "ความยินยอม",
    edit_date: "วันที่แก้ไขล่าสุด",
    system:"ระบบ",
    systemconsent: "ความยินยอมระบบ",
    systembusiness:"ระบบของบริษัท",
    status: "สถานะ",
    confirmclonepermission:"หากเปิดการตั้งค่าสิทธิ์เฉพาะโฟลเดอร์นี้ สิทธิ์ที่ตั้งค่าจะมีผลเฉพาะโฟลเดอร์นี้เท่านั้น",
    permissionclone:"ตั้งสิทธิ์เฉพาะโฟลเดอร์นี้",
    confirmdelete:"ยืนยันการลบไฟล์/โฟลเดอร์" ,
    confirmdelete_text1: "คุณต้องการลบไฟล์/โฟลเดอร์",
    confirmdelete_text2:"ออกจากระบบ ?",
    user_name:"ชื่อพนักงาน",
    oneid_user_id:"USER ID ONEID",
    business_email:"อีเมล",
    mobile:"เบอร์โทรศัพท์",
    seeall:"ดูทั้งหมด",
    addemail:'เพิ่มอีเมล',
    emailtext:"อีเมล",
    setadmin:"ตั้งค่าผู้ดูแลระบบ",
    setemail:"ตั้งค่าการแจ้งเตือนผ่านอีเมล",
    dashboard:"แดชบอร์ดบริษัท",
    numberuser:"จำนวนผู้ใช้ : ",
    totalusage:"การใช้งานทั้งหมด",
    spacestorage:"พื้นที่จัดเก็บ",
    available:"ที่ใช้ได้",
    driveused:"ไดรฟ์ที่ใช้",
    drivetash:"ไดรฟ์ถังขยะ",
    storageused:"ที่เก็บข้อมูลที่ใช้",
    file:"ไฟล์",
    folder:"โฟลเดอร์",
    personalfile:"ไดรฟ์ส่วนตัว",
    personalfilebuss:"ไดรฟ์ส่วนตัวของบริษัท",
    inbox:"อินบ็อกซ์",
    outbox:"เอาท์บ็อกซ์",
    sharedrive:"ไดรฟ์บริษัท",
    top5department:"5 แผนกที่ใช้พื้นที่สูงสุด",
    top5employee:"พนักงานที่ใช้พื้นที่สูงสุด",
    alldepartment:"แผนกทั้งหมดที่ใช้พื้นที่",
    employeeinshare:"พนักงานทั้งหมดที่ใช้พื้นที่ในไดรฟ์บริษัท",
    employeeoutshare:"รายละเอียดการใช้งานพื้นที่ในบริษัท",
    adminfolder: "admin",
    adminfolder: "ผู้ดูแล",
    textset:
      "อัปเดตค่าบทบาทสำหรับสมาชิกในกลุ่มนี้แล้ว อาจต้องใช้เวลาเพื่อให้การเปลี่ยนแปลงมีผล",
    wanttoset: "คุณต้องการตั้งค่าการเข้าถึงหรือไม่?",
    confrimsetting: "ยืนยันตั้งค่าการเข้าถึง",
    openall: "เปิดทั้งหมด",
    closeall: "ปิดทั้งหมด",
    update: "กำลังอัปเดต",
    setupdate: "อยู่ระหว่างการดำเนินการอัปเดตข้อมูล",
    managefile: "จัดการไฟล์/โฟลเดอร์",
    setpermissionfile: "ตั้งค่าการเข้าถึง",
    department: "แผนก",
    role:"ตำแหน่ง",
    tel: "เบอร์ติดต่อ",
    employee: "พนักงาน",
    view: "ดูเท่านั้น",
    download: "ดาวน์โหลด",
    edit: "แก้ไข",
    upload: "อัปโหลด",
    delete_s: "ลบ",
    admin_folder: "ผู้ดูแล",
    setting: "ตั้งค่า",
    save: "บันทึก",
    close: "ปิด",
    managefeature: "จัดการเมนู",
    selectoption: "ตัวเลือกการตั้งค่า",
    filterdepartment: "ค้นหาแผนก",
    all: "ทั้งหมด",
    name: "ชื่อ",
    business: "บริษัท",
    submit: "ตกลง",
    manageinbox: "จัดการการเข้าถึง อินบ็อกซ์",
    manageoutbox: "จัดการการเข้าถึง เอาท์บ็อกซ์",
    settingall: "ตั้งค่าสิทธิ์การเข้าถึง",
    permissiondepartment: "ตั้งค่าการเข้าถึงของแผนก",
    permissionemployee: "ตั้งค่าการเข้าถึงของพนักงาน",
    permissionrole: "ตั้งค่าการเข้าถึงของตำแหน่ง",
    permissionbusiness: "ตั้งค่าการเข้าถึงของบริษัท",
    status: "สถานะ",
    allow: "สิทธิ์ดูเท่านั้น",
    denind: "ไม่มีสิทธิ์เข้าใช้งาน",
    ad: "สิทธิ์ดาวน์โหลด/ลบ",
    delete: "ค่าเริ่มต้น",
    option: "ตั้งค่า",
    remain_storage: "ความจุที่เหลือ",
    use_storage: "ความจุที่ใช้",
    storage: "ความจุที่กำหนด",
    managestorage: "จัดการโควต้าพื้นที่จัดเก็บ",
    log: "ข้อมูลการใช้งาน",
    settingfeature: "ตั้งค่าการเข้าถึงเมนู",
    indexemail: "อีเมล:",
    indexmobile: "เบอร์โทรศัพท์:",
    adminheader: "ผู้ดูแลระบบ",
    titlestorage_re: "ความจุที่เหลืออยู่ของบริษัท",
    titlestorage_all: "ความจุทั้งหมดของบริษัท",
    search: "ค้นหา",
    department_search: "ค้นหาแผนก",
    upload_permission:"อัปโหลดสิทธิ์การเข้าถึง",
    headerCheck: "ยืนยันการตั้งค่า",
    opencheckdepartment:
      "คุณต้องการเปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของแผนก หรือไม่?",
    opencheckrole:
      "คุณต้องการเปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของตำแหน่ง หรือไม่?",
    closecheckdepartment:
      "คุณต้องการปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของแผนก หรือไม่ ?",
    closecheckrole:
      "คุณต้องการปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของตำแหน่ง หรือไม่ ?",
    confirmclosedepartment:
      "***หากคุณปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของแผนก จะทำให้สิทธิ์ที่ตั้งค่าไว้ไม่สามารถทำงานได้***",
    confirmcloserole:
      "***หากคุณปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของตำแหน่ง จะทำให้สิทธิ์ที่ตั้งค่าไว้ไม่สามารถทำงานได้***",
    opencheckemployee:
      "คุณต้องการเปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของพนักงาน หรือไม่ ?",
    closecheckemployee:
      "คุณต้องการปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของพนักงาน หรือไม่ ?",
    confirmcloseemployee:
      "***หากคุณปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของพนักงาน จะทำให้สิทธิ์ที่ตั้งค่าไว้ไม่สามารถทำงานได้***",
    opencheckbusiness:
      "คุณต้องการเปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของบริษัท หรือไม่ ?",
    closecheckbusiness:
      "คุณต้องการปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของบริษัท หรือไม่ ?",
    confirmclosebusiness:
      "***หากคุณปิดการกำหนดสิทธิ์การเข้าถึง ไฟล์/โฟลเดอร์ ของบริษัท จะทำให้สิทธิ์ที่ตั้งค่าไว้ไม่สามารถทำงานได้***",
      settypeupload:"ตั้งค่าไฟล์อัปโหลด",
      password_expired: "ตั้งค่ารหัสผ่านหมดอายุ",
      day: "วัน",
      time:"เวลา",
      set_date: "วันที่ตั้งค่า",
      no_date_set:"ไม่กำหนดวัน",
      enable:"เปิดใช้งาน",
      disable:"ปิดใช้งาน",
      two_factor_authen: "การยืนยันตัวตน 2 ขั้นตอน",
      maxenterdatenumber: "ระบุจำนวนวันได้สูงสุด 366 วัน",
  },
  admintoolbar: {
    report_problem: "รายงานปัญหา",
    managemapdrive:"จัดการแมพไดรฟ์",
    manageemployee:"จัดการพนักงาน",
    managefile: "จัดการไฟล์/โฟลเดอร์",
    managefeature: "จัดการเมนู",
    managestorage: "จัดการโควต้าพื้นที่จัดเก็บ",
    manageinbox: "จัดการอินบ็อกซ์",
    manageoutbox: "จัดการเอาท์บ็อกซ์",
    dashboard: "แดชบอร์ด",
    admin: "จัดการบริษัท",
    log: "ข้อมูลการใช้งาน",
    setadmin:"ตั้งค่าผู้ดูแลระบบ",
    setemail:"ตั้งค่าการแจ้งเตือนผ่านอีเมล",
    genkey:"สร้างคีย์",
    manageheader:"จัดการบริษัท",
    mydriveadmin:"ไดรฟ์ส่วนตัวพนักงาน",
    buystorage:"ซื้อพื้นที่เก็บข้อมูล",
    paymentlist:"รายการชำระเงิน",
    packagepurchasehistory:"ประวัติการซื้อแพ็กเกจ",
    register:"ลงทะเบียนบัญชีนิติบุคคล",
    admin2:"Admin",
    consent: "ความยินยอม",
    recheckimap: "ตรวจสอบ MAIL AUTO KEEP",
    fileandfolderbackup: "สำรองข้อมูลไฟล์/โฟลเดอร์",
    addemployee:"เพิ่มพนักงาน",
    setbusiness:"ตั้งค่าบริษัท",
    template_feature: "สร้างเทมเพลต" 
  },
  adminrightclick: {
    setpermission: "ตั้งค่าการเข้าถึง",
    movetolibrary:"นำเข้าห้องสมุด",
    download:"ดาวน์โหลด",
    downloadall:"ดาวน์โหลดไฟล์ทั้งหมด",
    unlockfile_right: "ปลดล็อคไฟล์",
    unlockfolder_right: "ปลดล็อคโฟลเดอร์"
  },
  admintoast:{
    settingsuccess:"ตั้งค่าสำเร็จ",
    settingfail:"ไม่สามารถตั้งค่าได้",
    confirmdeleteemail:"ยืนยันการลบอีเมล",
    confirmdeleteemail_text:"คุณต้องการลบ ",
    cancel:"ยกเลิก",
    notenoughstorage: "พื้นที่จัดเก็บข้อมูลไม่เพียงพอ",
    errormanagequotastorage: "ไม่สามารถจัดการโควต้าพื้นที่จัดเก็บได้",
  },
  adminlog:{
    genkey:"สร้างคีย์",
    name:"ชื่อผู้ใช้งาน",
    process_name:"การทำงาน",
    process_dtm:"เวลาการทำงาน",
    detail:"รายละเอียด",
    dialog:"รายละเอียดข้อมูลการใช้งาน",
    filename:"ชื่อไฟล์",
    foldername:"ชื่อโฟลเดอร์",
    status:"สถานะ",
    newfilename:"ชื่อไฟล์ใหม่",
    newfilestatus:"สถานะไฟล์ใหม่",
    newfolderstatus:"สถานะโฟลเดอร์ใหม่",
    newfoldername:"ชื่อโฟลเดอร์ใหม่",
    newparentfoldername:"ชื่อโฟลเดอร์แม่ใหม่",
    oldfilename:"ชื่อไฟล์เก่า",
    oldfilestatus:"สถานะไฟล์เก่า",
    oldfoldername:"ชื่อโฟลเดอร์เก่า",
    oldfolderstatus:"สถานะโฟลเดอร์เก่า",
    oldparentfoldername:"ชื่อโฟลเดอร์แม่เก่า",
    currentname:"ชื่อปัจจุบัน",
    statussharelink:"สถานะแชร์ลิงก์",
    type:"ชนิดข้อมูล",
    newname:"ชื่อใหม่",
    oldname:"ชื่อเก่า",
    sharewith:"แชร์กับ",
    statusshare:"สถานะแชร์",
    busineeset:"การตั้งค่าบริษัท",
    department:"แผนก",
    nameset:"ชื่อที่ตั้งค่า",
    resultset:"ผลลัพธ์การตั้งค่า",
    filenamein:"ชื่อไฟล์อินบ็อกซ์",
    filenameout:"ชื่อไฟล์เอาท์บ็อกซ์",
    newrelation:"ความสัมพันธ์ใหม่",
    oldrelation:"ความสัมพันธ์เก่า",
    text:"กรุณาเลือกข้อมูลการทำงานที่ต้องการตรวจสอบ",
    download: "ดาวน์โหลด",
    fine:"ค้นหาข้อมูลการใช้งาน",
    log_information:"ข้อมูลการทำงาน", 
    employee_name:"ชื่อพนักงาน",
    file_folder_name:"ชื่อไฟล์/โฟลเดอร์",
    no_name:"(ไม่มีชื่อ)",
    email:"อีเมล"
  },
  adminrecheckimap: {
    checkimapbutton: "ตรวจสอบและดาวน์โหลด",
    noserviceimap: "บริษัทนี้ไม่มีบริการ MAIL AUTO KEEP",
    noemailsavedoc: "ไม่พบอีเมลที่จะบันทึกเอกสาร",
    checkpleaseweit: "กำลังดำเนินการตรวจสอบเอกสาร โปรดรอสักครู่...",
    checkdoc: "กำลังตรวจสอบเอกสาร",
    downloaddocsuccess: "ดาวน์โหลดเอกสารสำเร็จ",
    nowdownload: "กำลังดาวน์โหลดเอกสาร",
    notdownload: "ไม่สามารถดาวน์โหลดเอกสารได้ในขณะนี้",
    pleaseenterpasswordemail: "กรุณากรอกรหัสผ่านของอีเมล",
    pleaseselectdate: "กรุณาเลือกวันที่ต้องการค้นหา",
    dateemail: "วันที่อีเมลเอกสาร",
    storagemail: "พื้นที่เมล",
    usagestorage: "พื้นที่คงเหลือ",
    unlimit: "ไม่จำกัด",
    in: "จาก",
    storagemailautokeep: "พื้นที่ Mail Auto Keep"
  },
  relationMyfile:{
    headerdialog:"เอกสารที่เกี่ยวข้อง",
    createrelation:"สร้างความสัมพันธ์",
    addrelation:"เพิ่มความสัมพันธ์",
    relationname:"ชื่อความสัมพันธ์ : ",
    chooserelation:"เลือกความสัมพันธ์",
    namerelation:"ชื่อความสัมพันธ์",
    relation:"ความสัมพันธ์",
    master:"หลัก",
    child:"รอง"
  },
  dragdrop:{
    cancelupload:"ยกเลิกการอัปโหลด",
    cannotupload: "มีไฟล์ที่อัปโหลดไม่สำเร็จ",
    uploadsuccess:"อัปโหลดสำเร็จ",
    uploadfail: "อัปโหลดไม่สำเร็จ",
    detail:"รายละเอียด",
    hide:"ซ่อน",
    uploading:"กำลังอัปโหลด",
    close:"ปิด",
    header:"รายละเอียดการอัปโหลด",
    file:"ไฟล์",
    cancel:"ยกเลิก",
    of:"จาก",
    morefivegb: "ขนาดไฟล์เกิน 5 GB",
    morefile: "ขนาดไฟล์เกิน ",
    yourspacenotenough: "พื้นที่ของคุณไม่เพียงพอ",
    cannotdetailupload: "ไม่สามารถอัปโหลดไฟล์/โฟลเดอร์ได้ในขณะนี้",
    nofolder: "ยังไม่ได้เลือกโฟลเดอร์",
    duplicatefile: "ไฟล์นี้ซ้ำ",
    duplicatefolder: "โฟลเดอร์นี้ซ้ำ",
    duplicatefail: "การเช็คไฟล์/โฟลเดอร์ซ้ำมีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ"
  },
  checkdelete:{
    deleteonlyme:"ลบของตัวเองเท่านั้น",
    deleteandrequest:"ลบและส่งrequest",
    ok:'ยืนยัน',
    cancel:'ยกเลิก',
    delete:'ลบ',
    wanttodelete:'คุณต้องการลบไฟล์นี้?',
    wanttodeleteandrequest:'คุณต้องการลบและแจ้งคำขอลบ',
    cantrestore:'หากคุณทำการลบไฟล์ จะไม่สามารถกูคืนไฟล์ได้'
    
  },
  checkpassword:{
    oldpassword:"รหัสผ่านเดิม",
    newpassword:"รหัสผ่านใหม่",
    confirmnewpassword:"ยืนยันรหัสผ่านใหม่",
    setnewpassword:"ตั้งรหัสผ่านใหม่",
    referencecode:"รหัส Reference",
    clickrequestotp:"กรุณากดขอรหัส OTP",
    requestotp:"ขอรหัส OTP",
    forget:"ลืมรหัสผ่าน",
    title:'ไฟล์นี้ป้องกันด้วยรหัสผ่าน',
    check:'ใส่รหัสผ่านของ',
    removepassword: 'กรุณาใส่รหัสผ่านเดิมเพื่อยกเลิกรหัสผ่าน',
    inputpassword:'กรุณากรอกรหัสผ่าน',
    editpassword:'แก้ไขรหัสผ่าน',
    deletepassword:'ลบรหัสผ่าน',
    forgotpassword:'ลืมรหัสผ่าน',
    setpassword:'ตั้งรหัสผ่าน',
    ok:'ยืนยัน',
    edit:'แก้ไข',
    cancel:'ยกเลิก',
    passwordotp:'รหัส Reference',
    password:'รหัสยืนยัน',
    setok:'ตั้งค่า',
    passwordincorrect:'รหัสผ่านไม่ถูกต้อง',
    enterpassword:'กรุณาใส่รหัสผ่าน',
    deletepasswordsuccess:'ลบรหัสผ่านสำเร็จ',
    createpasswordsuccess: 'สร้างรหัสผ่านสำเร็จ',
    editpasswordsuccess: 'แก้ไขรหัสผ่านสำเร็จ',
    oldpasswordincorrect:'รหัสผ่านเดิมไม่ถูกต้อง',
    confirmnewpasswordnotmatch: 'ยืนยันรหัสผ่านไม่ตรงกัน',
    newpasswordsameoldpassword: 'รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม',
    sendotp: 'ส่ง OTP ไปยังอีเมล',
    success: 'สำเร็จ',
    sendotpfailed: 'ส่ง OTP ไปยังอีเมลล้มเหลว',
    close:"ปิด"
  
  },
  dashboard:{
    name:'ชื่อ',
    emempty_1:'ไม่มีพนักงานที่ใช้ไดรฟ์บริษัทสูงสุด',
    emempty_2:'ไม่มีพนักงานที่ใช้ไดรฟ์ส่วนตัวสูงสุด',
    deempty:'ไม่มีแผนกที่ใช้พื้นที่สูงสุด'
  },
  fileduplicate: {
    headerfileduplicate: "พบเจอไฟล์/โฟลเดอร์ซ้ำ",
    filecopy: "คัดลอก",
    filereplace: "แทนที่",
    fileskip: "ยกเลิก",
    selectall: "เลือกทั้งหมด",
    skipmsg: "ยกเลิกการอัปโหลด",
  },
  fileexpiredall:{
    fileexpired: 'ไฟล์หมดอายุ'
  },
  expiredrightclick:{
    showinfolder:"แหล่งที่มา",
    setexpire:'แก้ไขวันหมดอายุ',
    choosedate:'เลือกวันหมดอายุ',
    deleteexpire:'ลบ',
    setexpireddate:'ตั้งค่าวันหมดอายุ',
    setexpiredsuccess:'ตั้งค่าวันหมดอายุสำเร็จ',
    expireddate:'วันหมดอายุ :',
    settingexpiration:'การตั้งค่าวันหมดอายุ',
    beforeexpireddate:'วันเตือนก่อนหมดอายุ :',
    settingbeforeexpiration:'การตั้งค่าวันเตือนก่อนหมดอายุ',
    choosebeforeexpiretiondate:'เลือกวันเตือนก่อนหมดอายุ',
    setbeforeexpire:'วันที่แจ้งเตือนก่อนไฟล์หมดอายุ',
    alert_email: "แจ้งเตือนผ่าน E-mail",
    alert_onechat: "แจ้งเตือนผ่าน One Chat",
    friend:"เพื่อน",
    condition:"หากต้องการแจ้งเตือนมากว่า 1 อีเมลกรุณากรอกอีเมลคั่นด้วยเครื่องหมาย (,)",
    detail: "รายละเอียด",
  },
  libraryRclick:{
    download:'ดาวน์โหลด',
    preview:'ดูตัวอย่าง',
  },
  checkmultidownload:{
    listselectdownload:'รายการไฟล์และโฟลเดอร์ที่เลือก',
    countfile:'จำนวนไฟล์และโฟลเดอร์ที่สามารถดาวน์โหลดได้',
    cannotdownload:'จำนวนไฟล์และโฟลเดอร์ที่ไม่มีสิทธิ์ดาวน์โหลด',
    totalfile:'จำนวนไฟล์และโฟลเดอร์ทั้งหมดที่เลือก',
    file:'ไฟล์',
    folder:'โฟลเดอร์',
    download:'ดาวน์โหลด',
    candownload:'สามารถดาวน์โหลดได้',
    cannotpermissiondownload:'ไม่มีสิทธิ์ดาวน์โหลด',
    cannotlockdownload:'ไม่สามารถดาวน์โหลดได้เนื่องจากไฟล์มีการล็อก',
    downloading:'กำลังดาวน์โหลด',
    downloadsuccess:'ดาวน์โหลดสำเร็จ',
    downloadnotsuccess:'ดาวน์โหลดไม่สำเร็จ',
    mergingfiles:'กำลังรวมไฟล์..',
  },
  dialogmultidelete:{
    header:"รายการไฟล์และโฟลเดอร์ที่เลือก",
    total:"จำนวนไฟล์และโฟล์เดอร์ที่เลือก",
    can_delete:"จำนวนไฟล์และโฟล์เดอร์ที่สามารถลบได้",
    cannot_delete:"จำนวนไฟล์และโฟล์เดอร์ที่ไม่มีสิทธิ์ลบ",
    title2:"ไฟล์",
    title1:"โฟลเดอร์",
    filelock:"ไม่สามารถลบไฟล์เนื่องจากมีการล็อกไฟล์",
    canbedelete:"สามารถลบไฟล์/โฟลเดอร์นี้ได้",
    cannotdelete:"คุณไม่มีสิทธิ์ลบไฟล์/โฟลเดอร์นี้",
    buttonclose:"ปิด",
    buttondelete:"ลบ",
    deleting:"กำลังลบไฟล์/โฟลเดอร์",
    deletesuccess:"ลบไฟล์/โฟลเดอร์ สำเร็จ",
    deletecancel: "ยกเลิกลบไฟล์/โฟลเดอร์",
    deletefail:"ไม่สามารถลบไฟล์/โฟล์เดอร์ นี้ได้",
    canceldelete: "ยกเลิก"
  },
  dialogconfirmdelete:{
    header:"ยืนยันการนำ ไฟล์/โฟลเดอร์ เข้าถังขยะ",
    noti_1:"คุณต้องการนำ",
    noti_2:"เข้าถังขยะหรือไม่ ?",
    comfirmdeletetext1: "การลบถาวรจะไม่สามารถกู้คืนกลับมาได้",
    comfirmdeletetext2: "คุณต้องการทำรายการใช่หรือไม่",
    permanently: "ลบถาวร",
    restoresuccess: "กู้คืนสำเร็จ !",
    deletesuccess: "ลบสำเร็จ !",
    permanentlydelete_success: "ลบถาวรสำเร็จ !",
    undocumented_delete:"ไม่มีเอกสารที่ต้องการลบ",
    undocumented_permanently:"ไม่มีเอกสารที่ต้องการลบถาวร",
    undocumented_restore:"ไม่มีเอกสารที่ต้องการกู้คืน",
    noti_emptytrash: "การกระทำนี้จะเป็นการลบเอกสารในถังขยะอินบ็อกซ์ทั้งหมด ยืนยันที่จะดำเนินการหรือไม่?",
    noti_emptytrash_outbox: "การกระทำนี้จะเป็นการลบเอกสารในถังขยะเอาท์บ็อกซ์ทั้งหมด ยืนยันที่จะดำเนินการหรือไม่?"
  },
  balloon:{
    choose:"เลือก",
    chooseall:"เลือกทั้งหมด",
    evaluate:"แบบการประเมิน",
    download_all:"ดาวน์โหลดไฟล์ทั้งหมดจากที่ค้นหา",
    download_all_csv:"ดาวน์โหลดไฟล์ทั้งหมดจากที่ค้นหา (CSV)",
    download_all_excel:"ดาวน์โหลดไฟล์ทั้งหมดจากที่ค้นหา (Excel)",
    reload:"โหลดข้อมูลใหม่",
    view:"ดูเท่านั้น",
    download:"ดาวน์โหลด",
    upload:"อัปโหลด",
    edit:"แก้ไข",
    delete:"ลบ",
    admin:"ผู้ดูแล",
    clear:"ปิด",
    close:"ปิด",
    menu:"เมนู",
    upload_create:"อัปโหลด / สร้าง",
    purchase:"ซื้อพื้นที่เก็บข้อมูล",
    print:"พิมพ์ PDF",
    menu_download:"เมนู ดาวน์โหลด",
    back:"ย้อนกลับ",
    grid:"มุมมองตาราง",
    list:"มุมมองรายการ",
    star:"รายการโปรด",
    move:"ย้าย ไฟล์/โฟลเดอร์",
    menu_trash:"เมนู ถังขยะ",
    cleartrash: "ล้างถังขยะ",
    permanently_delete:"ลบถาวร (ที่เลือก)",
    restore:"กู้คืน",
    allrestore:"กู้คืนทั้งหมด",
    resend: "ส่งอีกครั้ง",
    manual:"คู่มือการใช้งาน One Box",
    darkmode: "โหมดมืด",
    lightmode: "โหมดสว่าง",
    lock_function:"สำหรับ One Box Enterprise Package เท่านั้น",
    storage_and_permission: "พื้นที่จัดเก็บเต็ม และ ไม่มีสิทธิ์ดาวน์โหลด",
    storage: "พื้นที่จัดเก็บเต็ม",
    permission: "ไม่มีสิทธิ์ดาวน์โหลด",
    Medandetax:"เอกสาร",
    business_management:"จัดการบริษัท",
    drive_business:"ไดรฟ์บริษัท",
    info_date:"วันที่ได้รับเอกสาร คือวันที่เอกสารเข้าสู่ระบบ One Box",
    info_date_doc:"เอกสารลงวันที่ คือวันที่ระบุลงในเอกสาร",
    info_date_outbox:"วันที่อัปโหลดเอกสาร คือวันที่นำเอกสารเข้าสู่ระบบ One Box",
    permanently_delete_total:"ลบถาวร (ทั้งหมด)",
    addhashtag: "เพิ่มแฮชแท็ก",
    setting:"การตั้งค่า",
    editfileonline:"แก้ไขได้เฉพาะไฟล์นามสกุล docx xlsx pptx",
    allsettingmanagestorage: "ตั้งค่าโควต้าพื้นที่จัดเก็บทั้งหมด",
    selectsettingmanagestorage: "ตั้งค่าโควต้าพื้นที่จัด (ที่เลือก)",
    managestorage: "จัดการโควต้าพื้นที่จัดเก็บ",
  },
  textprint: {
    filesomethingwrong: "ไม่สามารถพิมพ์ไฟล์เสียได้",
    cannotprint: "ไม่สามารถพิมพ์ไฟล์ได้ในขณะนี้",
  },
  consent:{    
    Name_:"ชื่อเอกสาร",
    Status:"สถานะ",
    Edit:"แก้ไข",
    date_time:"วันที่",
    Detail:"รายละเอียด",
    cancelapprove:"ปฏิเสธ",
    appove:"ยินยอม",
    pending:"รอดำเนินการ",
    approvetext:"ยินยอมเอกสาร",
    canceltext:"ปฏิเสธเอกสาร",
    detail:"รายละเอียด..",
    confirmcancel:"ยืนยันการยกเลิก",
    confirmcanceltext1:"คุณต้องการลบ",
    confirmcanceltext2:"หรือไม่?",
    dialogdetail:"รายละเอียด",
    cancel:"ยกเลิก",
    setting:"ตั้งค่า",
    save:"บันทึก",
    except:"ยกเว้น",
    receive_etax_all:"รับ e-Tax จากบริษัททั้งหมด",
    receive_etax:"รับ e-Tax เฉพาะ : ",
    company:"บริษัท",
    total:"จำนวน",
    taxid_duplicate:"เลขประจำตัวผู้เสียภาษีซ้ำ",
    added_taxid:"เพิ่มเลขประจำตัวผู้เสียภาษีสำเร็จ",
    set_successfully:"ตั้งค่าสำเร็จ",
    limit_receipt_of_etax:"การเลือกรับเอกสารอิเล็กทรอนิกส์",
    show_more:"...แสดงเพิ่มเติม", 
    show_less:"...แสดงน้อยลง",
    title_receive_etax:"บันทึกข้อมูลบริษัทเพื่อรับ e-Tax",
    receive_all:"รับทั้งหมด",
    receive_specificate:"เลือกรับ",
    receive_all_with_except:"ยกเว้น",
    title_receive_all:"รับ e-Tax จากบริษัททั้งหมด",
    title_receive_specificate:"รับ e-Tax เฉพาะบริษัทที่เลือกรับ",
    title_receive_all_with_except:"รับ e-Tax ทุกบริษัท / ยกเว้นบริษัทที่ไม่ต้องการเลือกรับ",
    input_block_taxid:"ระบุเลขผู้เสียภาษีที่ต้องการยกเว้น",
    input_accept_taxid:"ระบุเลขผู้เสียภาษีที่ต้องการเลือกรับ",
    setting_history:"ประวัติการตั้งค่า",
    current_status:"สถานะปัจจุบัน : ",
    name_user:"ชื่อ",
    modified_date:"วันที่ดำเนินการ",
    close:"ปิด",
    history:"ประวัติแก้ไข",
    title_log:"ข้อมูลการใช้งาน การเลือกรับเอกสารอิเล็กทรอนิกส์",
    please_accept_consent:"กรุณาเปิดรับความยินยอม"
   
  },
  backupfileandfolder: {
    allrestore: "กู้คืนไฟล์/โฟลเดอร์ทั้งหมด",
    contentallrestore: "คุณต้องการกู้คืนไฟล์/โฟลเดอร์ทั้งหมดหรือไม่ ?",
    multirestore: "กู้คืนไฟล์/โฟลเดอร์ที่เลือก",
    contentmultirestore: "คุณต้องการกู้คืนไฟล์/โฟลเดอร์ที่เลือกหรือไม่ ?",
    cancelrestore: "ยกเลิกการกู้คืน",
    preparerestore: "กำลังเตรียมการกู้คืนไฟล์/โฟลเดอร์",
    restoresuccess: "กู้คืนไฟล์/โฟลเดอร์สำเร็จ",
    restoringfilefolder: "กำลังกู้คืนไฟล์/โฟลเดอร์",
    nofileandfolderforrestore: "ไม่มีไฟล์/โฟลเดอร์สำหรับการกู้คืน",
    detailrestore: "รายละเอียดการกู้คืนไฟล์/โฟล์เดอร์",
    cannotrestore: "มีไฟล์/โฟลเดอร์ที่กู้คืนไม่สำเร็จ",
    pleaseselect: "กรุณาเลือกไฟล์/โฟลเดอร์ที่ต้องการกู้คืน",
    nodatarestore: "ไม่มีไฟล์สำหรับการกู้คืน"
  },
  edithashtag:{
    edit:"แก้ไข",
    ok:"บันทึก",
    buttonclose:"ปิด",
    editsuccess:"แก้ไขแฮชแท็กสำเร็จ",
    erroredit:"แก้ไขแฮชแท็กไม่สำเร็จ",
    setting:"เพิ่ม/แก้ไข แฮชแท็ก",
    add:"เพิ่ม",
    hashtagempty:'กรุณาใส่แฮชแท็กที่ต้องการ',
    nospecialcharacter: "แฮชแท็กต้องไม่มีอักขระ , | หรือช่องว่าง",
  },
  donotpreview:"ไม่มีสิทธิ์เข้าถึงไฟล์นี้",
  permissionsharefile:{
    viewonly:"ดูเท่านั้น",
    upload:"อัปโหลด",
    download:"ดาวน์โหลด",
    edit:"แก้ไข",
    delete:"เข้าถึงทั้งหมด"
  },
  adminsetemail:{
    headeraddemail:"เพิ่มอีเมลรับการแจ้งเตือน",
    headereditemail:"แก้ไขอีเมลรับการแจ้งเตือน",
    add:"เพิ่ม",
    edit:"แก้ไข"
  },
  confirm:{
    confirmcancleshared:"ยืนยันการยกเลิกแชร์ไฟล์",
    cancelshared:"คุณต้องการยกเลิกการแชร์",
    confirm:"ยืนยัน",
    closecomfirm:"ปิด",
    confirmhide:"ยืนยันการซ่อนไฟล์",
    hide:"คุณต้องการซ่อน",
    confirmunhide:'ยืนยันยกเลิกการซ่อนไฟล์',
    unhide:'คุณต้องยกเลิกการซ่อน'
  },
  lockfile:{
    unlockfilesuccess: "ปลดล็อกไฟล์สำเร็จ"
  },
  register:{
    regissuccess: "ลงทะเบียนสำเร็จ"
  },
  quota_storage:{
    quota_storage:"โควต้าพื้นที่จัดเก็บ",
    quota_usage:"โควต้าที่ใช้ไปแล้ว",
    quota_remain:"โควต้าที่เหลืออยู่",
    quota_default: "โควต้าเริ่มต้น",
    setting:"ตั้งค่าพื้นที่เก็บข้อมูล",
    settingselect:"ตั้งค่าพื้นที่เก็บข้อมูล (ที่เลือก)",
    settingall:"ตั้งค่าพื้นที่เก็บข้อมูลทั้งหมด",
    settingdefault: "ตั้งค่าพื้นที่เก็บข้อมูลเริ่มต้น",
    headerset:"ตั้งค่าโควต้าพื้นที่จัดเก็บ",
    quota_business:"โควต้าพื้นที่จัดเก็บของบริษัท",
    text_remain_bus:"ความจุที่เหลืออยู่ของบริษัท",
    text_remain:"ความจุที่เหลืออยู่",
    text_used:"ความจุที่ใช้ไปแล้ว",
    text_quota:"ความจุที่กำหนด",
    setstorage:"ตั้งค่าพื้นที่เก็บข้อมูล",
    text_edit:"ใส่ความจุที่ต้องการแก้ไข",
    text_set_quota:"ความจุที่ตั้งค่าไว้",
    text_set_quota_head:"โควต้าพื้นที่จัดเก็บที่ตั้งค่าไว้",
    quota_DE:"โควต้าแผนก",
    quota_EM:"โควต้าพนักงาน",
    updatingsetting: "กำลังอัปเดต",
    updatesettingsuccess: "อัปเดตสำเร็จ",
    cannotfinddata: "ไม่พบข้อมูลพนักงานนี้ในระบบ",
    cannotfindemployeeinbiz: "ไม่พบพนักงานคนนี้ในบริษัทของคุณ",
    cannotupdatemorespace: "ไม่สามารถอัปเดตได้เนื่องจากพื้นที่เก็บข้อมูลน้อยกว่าพื้นที่ใช้งาน",
    cannotupdate: "ไม่สามารถอัปเดตโควต้าพื้นที่จัดเก็บได้ในขณะนี้",
    notset: "ไม่ได้ตั้งค่า",
    pleaseselectemployee: "กรุณาเลือกพนักงานที่ต้องการตั้งค่าพื้นที่จัดเก็บข้อมูล",
    alldepartment: "ทั้งหมด",
    edit: "แก้ไข",
    messagesetdefault: "เมื่อตั้งค่านี้ เมื่อมี User เข้ามาในบริษัท จะได้โควต้าตามที่ตั้งไว้โดยอัตโนมัติ"
  },
  statusfileexpired:{
    unsetsettingexpired:'ยกเลิกการตั้งค่าหมดอายุ',
    expired:'หมดอายุ',
    nearexpired:'ใกล้หมดอายุ',
    unsetexpiredfile:'คุณต้องการยกเลิกการตั้งค่าไฟล์หรือโฟลเดอร์ ?',
    unsetsuccess:'ยกเลิกตั้งค่าวันหมดอายุสำเร็จ',
    unsetunsuccess:'ไม่สามารถยกเลิกตั้งค่าวันหมดอายุ',
    expired_search:"ค้นหา"
  },
  selectbutton:{
    hashtag:'แฮชแท็ก',
    encrypt:'การเข้ารหัสไฟล์',
    remark:'** หากมีการ Encrypt ไฟล์ อาจใช้เวลาการอัปโหลดนานกว่าปกติ'
    
  },
  loginbusiness:{
    error:'Token หมดอายุ กรุณาล็อกอินอีกครั้ง'
  },
  packagedetail:{
    ManageUser:'จัดการผู้ใช้งาน',
    UploadDownloadShare:'อัปโหลด/ดาวน์โหลด/แชร์',
    RestrictAccess:'จำกัดการเข้าถึง',
    AdvanceSearch:'การค้นหาขั้นสูง',
    ConnectThroughAPI:'เชื่อมต่อผ่านAPI',
    SendFiletoeMail:'ส่งไฟล์ไปที่อีเมล',
  },
  defaultbusiness:'บริษัทเริ่มต้น',
  settingdefaultbusiness:'ตั้งค่าบริษัทเริ่มต้น',
  settinfdefaulesuccess:'ตั้งค่าบริษัทเริ่มต้นสำเร็จ',
  shortcut_header:'สร้างทางลัดเข้าเว็บไซต์',
  goback:"ย้อนกลับ",
  resend:{
    sorrt_by:'เรียงตาม',
    status_error: 'ส่งไม่สำเร็จ',
    status_success:'ส่งสำเร็จ',
    status_pending:'กำลังทำงาน',
    datafile:'รายการไฟล์',
    datafilesummary:'รวมรายการไฟล์'

  },
  buystorage:"ซื้อพื้นที่เก็บข้อมูล",
  resendmail:{
    header:"ส่งไฟล์อีกครั้ง",
    wordresend:"คุณต้องการส่งไฟล์ไปยัง",
    note:"หมายเหตุ: สามารถใช้เครื่องหมาย , (จุลภาค)หากต้องการส่งมากกว่า 1 อีเมล",
    addmail:"เพิ่มอีเมล",
    wordresend_2:"อีกครั้งใช่หรือไม่",
    confirm:"ยืนยัน"
  },
  sorting: {
    sort: "เรียงตาม",
    name: "ชื่อ",
    date_created: "วันที่สร้าง",
    date_modified: "วันที่แก้ไข",
    date_delete: "วันที่ลบ",
    type: "ประเภท",
    size_file:"ขนาด",
    ascending: "จากน้อยไปมาก (ก-ฮ , A-Z)",
    descending: "จากมากไปน้อย (ฮ-ก , Z-A)",
    document_date : "วันที่เอกสาร",
    document_type : "ประเภทเอกสาร",
    cre_dtm : "วันที่ส่งเอกสาร",
    customer_name : "ชื่อผู้ซื้อ",
    saler_name : "ชื่อผู้ขาย",
    filename : "ชื่อเอกสาร",
    doc_no:"เลขที่เอกสาร",
    status:"สถานะ",
    trash_dtm:"วันที่ลบ"

  },
  paymentlist:{
    package_name:"ชื่อแพ็กเกจ",
    price:"ราคา(บาท)",
    payment_date:"วันที่ต้องชำระ",
    dateofpayment:"วันที่ชำระ",
    payment_status:"สถานะการชำระ",
    tax_invoice:"ใบแจ้งหนี้/ใบกำกับภาษี",
    pay:"ชำระเงิน",
    btnpay: "กดเพื่อชำระเงิน",
  },
  packagepurchasehistory:{
    package_name:"ชื่อแพ็กเกจ",
    package_name_addon:"ชื่อแพ็กเกจ (เสริม)",
    price:"ราคา (บาท)",
    payment_type:"ประเภทการชำระ",
    dateofpayment:"วันที่ชำระ",
    payment_status:"สถานะการชำระ",
    tax_invoice:"ใบกำกับภาษี"
  },
  packagenew:{
    package:"แพ็กเกจ",
    price:"ราคา (บาท)",
    amount:"จำนวน",
    purchase_package:"ซื้อแพ็กเกจ",
    my_package:"แพ็กเกจของฉัน",
    package_history:"ประวัติการซื้อแพ็กเกจ",
    current_mystorage:"พื้นที่จัดเก็บข้อมูลปัจจุบัน",
    used_space:"ใช้ความจุไป",
    total_users:"จำนวน User ในระบบทั้งหมด",
    title_package:"เลือกแพ็กเกจ Onebox ที่เหมาะกับคุณ แพ็กเกจสมาชิกเริ่มต้นที่ 350 บาท/เดือน",
    month:"รายเดือน",
    year:"รายปี",
    baht_month:"บาท/เดือน",
    baht_year:"บาท/ปี",
    choose_package:"เลือกแพ็กเกจ",
    vat:"*แพ็กเกจยังไม่รวม VAT 7 % และรองรับการชำระผ่าน 3 ช่องทาง (Credit Card / QR Code / Internet Banking)",
    text_notvat:"*ราคา Storage ยังไม่รวม VAT 7 %",
    clear:"ล้างค่า",
    update_package:"อัปเดตแพ็กเกจ",
    pay:"ชำระเงิน",
    service_charge: "คำนวนค่าบริการ Storage ของคุณ",
    login_for_purchase_storage:"เข้าสู่ระบบเพื่อซื้อพื้นที่เก็บข้อมูล",
    login_for_purchase_package:"เข้าสู่ระบบเพื่อซื้อแพ็กเกจ",
    personal:"สำหรับบุคคล",
    business:"สำหรับธุรกิจ",

  },
  addon_package:{
    addon_package:"เลือกแพ็กเกจเสริม One Box เพื่อคุณ",
    addon_title:"บัญชี ONE BOX สามารถเพิ่มแพ็กเกจเสริมเพื่อตอบโจทย์ความต้องการของคุณ",
    choose_package:"เลือกแพ็กเกจ",
    price:"ราคา(บาท)",
    add:"เพิ่มจำนวน",
  },
  Order_summary:{
  Order_summary:"สรุปรายการสั่งซื้อ",
  not_choose:"คุณยังไม่เลือกแพ็กเก็จหลัก",
  not_choose_addon:"คุณยังไม่เลือกแพ็กเก็จเสริม",
  main_package:"แพ็กเกจหลัก",
  addon_package:"แพ็กเกจเสริม",
  total:"รวมทั้งหมด",
  baht:"บาท",
  vat:"*แพ็กเกจยังไม่รวม VAT 7 %"
  },
  taxinvoice_address:{
  taxinvoice_address:"ที่อยู่ใบกำกับภาษี",
  purchaser:"ผู้ซื้อ : ",
  address:"ที่อยู่ : ",
  address_detail:"เลขที่ 1768 อาคาร ไทยซัมมิท ทาวเวอร์ ชั้น 16 แขวง ห้วยขวาง เขต บางกะปิ กรุงเทพมหานคร 10310",
  tax_identification:"เลขประจำตัวผู้เสียภาษีอากร : ",
  click_here:"คลิกที่นี่เพื่อกรอกข้อมูลใบกำกับภาษี",
  edit_tax:"✔ กรอกใบกำกับภาษีเรียบร้อย สามารถคลิกที่นี่เพื่อแก้ไข"
  },
  my_package:{
    my_package:"แพ็กเกจของฉัน",
    change_package:"เปลี่ยนแปลงแพ็กเกจ",
    current_mystorage:"พื้นที่จัดเก็บข้อมูลปัจจุบัน",
    used_space:"ใช้ความจุไป",
    total_users:"จำนวน User ในระบบทั้งหมด",
    main_package:"แพ็กเกจหลัก",
    addon_package:"แพ็กเกจเสริม",
    main_package_next:"แพ็กเกจหลัก(รอบบิลถัดไป)",
    baht:"บาท",
    month:"เดือน",
    year:"ปี",
    duration:"ระยะเวลาการใช้งานได้",
    no_expiration:"ไม่มีวันหมดอายุ"
  },
  package_current:{
    confirm_tax:"ยืนยันการออกใบกำกับภาษี",
    want_tax:"คุณต้องการออกใบกำกับภาษีหรือไม่ ?",
    cancel:"ยกเลิก",
    wish:"ต้องการ",
    unwish:"ไม่ต้องการ",
  },
  resendfile: {
    resend: "ส่งอีกครั้ง",
    closefromdateresend: "ปิด",
    fromdate: "เอกสารลงวันที่",
    todate: "ถึง",
    filename: "ชื่อไฟล์",
    document_no: "เลขที่เอกสาร",
    document_date: "เอกสารลงวันที่",
    document_type: "ประเภทเอกสาร",
    data_type: "ชนิดไฟล์",
    sender_name: "ผู้ส่ง",
    receiver_name: "ผู้รับ",
    cre_dtm: "วันที่ส่งเอกสาร",
    status_trash_receiver: "สถานะ",
    optional: "จัดการไฟล์",
    msg: {
      nofile: "กรุณาเลือกไฟล์ที่ต้องการทำรายการ  ",
      apierror: "ไม่สามารถส่งไฟล์ได้ !!!"
    },
  },
  checkremember:{
    check: "ต้องการจดจำฉันในระบบหรือไม่",
    yes: "ต้องการ",
    no: "ไม่ต้องการ",
  },
  sharewithme:{
    filesharewithme: "ไฟล์แชร์ร่วมกัน",
    hiddenfile: "ไฟล์ที่ซ่อน",
    adminonly: "เฉพาะแอดมินบริษัทเท่านั้นที่จะยกเลิกการแชร์นี้"
  },
  landingpagenavbar:{
    home: "หน้าหลัก",
    oneboxserviceinfo: "ข้อมูลให้บริการ One Box",
    dataprotectionpolicy: "นโยบายการคุ้มครอง",
    termsofservice: "ข้อกำหนดการใช้บริการ",
    termsofuseofelectronic:"ข้อกำหนดการใช้บริการระบบเก็บเอกสารอิเล็กทรอนิกส์",
    manual:"คู่มือการใช้งาน ONE BOX",
    businessbox: "คู่มือการสมัคร Business Box",
    oneboxmanual: "คู่มือการใช้งาน One Box",
    etax: "คู่มือสำหรับค้นหาไฟล์ e-Tax Invoice",
    useonebox: "VDO การใช้งาน One Box",
    storage: "VDO การซื้อ Storage เพิ่มด้วยตนเอง",
    registeraccount: "สมัครสมาชิก",
    registerone: "ลงทะเบียนผู้ใช้ One Platform",
    registerbusiness: "ลงทะเบียนบัญชีนิติบุคคล",
    login: "เข้าสู่ระบบ",
    gotoonebox: "เข้าสู่ One Box",
    logout: "ออกจากระบบ",
    language: "ภาษา",
    login_onecollab:"One Collaboration Mail",
    login_azure_id: "เข้าสู่ระบบด้วยบัญชีองค์กร"
  },
  landingpagefooter:{
    contactus: "ติดต่อเรา",
    addressheader: "ที่อยู่",
    addresscontent1: "เลขที่ 1768",
    addresscontent2: "อาคารไทยซัมมิท ทาวเวอร์",
    addresscontent3: "ชั้น 10-12 และชั้น IT",
    addresscontent4: "ถนนเพชรบุรีตัดใหม่",
    addresscontent5: "แขวงบางกะปิ",
    addresscontent6: "เขตห้วยขวาง",
    addresscontent7: "กรุงเทพมหานคร",
    addresscontent8: "10310",
    telephone: "เบอร์ติดต่อ"
  },
  landingpage: {
    rememberme: "จดจำข้อมูลผู้ใช้งาน",
    accept: "ยอมรับ",
    termsofserviceoneid: "ข้อกำหนดการใช้บริการ ONE ID",
    and: "และ",
    privacypolicy: "นโยบายความคุ้มครองข้อมูลส่วนบุคคล",
    createaccountoneid: "สร้างบัญชี OneID ?",
    createaccount_oneid: "สร้างบัญชี One Platform ?",
    registereasyoneid: "ลงทะเบียน Easy One Platform",
    registeruserheader: "ลงทะเบียนผู้ใช้งาน",
    success: "เสร็จสิ้น",
    mobileno: "เบอร์โทรศัพท์",
    username: "ชื่อผู้ใช้",
    password: "รหัสผ่าน",
    idcard:" เลขบัตรประจำตัวประชาชน",
    verify:"ตรวจสอบ",
    confirmpassword: "ยืนยันรหัสผ่าน",
    registerbtn: "ลงทะเบียน",
    haveanaccount: "คุณมีบัญชี One Platform ใช่หรือไม่ ?",
    registersuccessfully: "ลงทะเบียนสำเร็จ",
    loginlink: "ลงชื่อเข้าใช้งาน",
    pleaseremember: "โปรดจำข้อมูลนี้ !!",
    failedLogin: "คุณพยายามเข้าระบบหลายครั้งเกินไป กรุณาลองใหม่ใน",
    hour: "ชั่วโมง",
    minute: "นาที",
    second: "วินาที",
    contentsection1: {
      title: "สมัครง่าย สะดวก รวดเร็ว ด้วย ONEBOX",
      item: "รองรับการใช้งานผ่าน เบราว์เซอร์",
    },
    contentsection2: {
      title: "ความสะดวกในการใช้งานและความสามารถในการแชร์ไฟล์",
      subtitle1: "สะดวกทุกที่ทุกเวลา",
      item1: "สามารถเข้าถึงพื้นที่จัดเก็บไฟล์ได้ทุกที่ ทุกเวลา และทุกอุปกรณ์ผ่าน Web Application",
      subtitle2: "แชร์ไฟล์ได้หลายช่องทาง",
      item2: "สามารถแชร์ไฟล์ให้ผู้อื่นเข้ามาดาวน์โหลดได้โดยไม่ต้องส่ง link และยังสามารถส่งไฟล์ผ่าน chat และ e-mail ได้",
    },
    contentsection3: {
      title: "หมดความกังวลเรื่องการเก็บไฟล์และความปลอดภัยของข้อมูล",
      subtitle1: "ความปลอดภัยของข้อมูล",
      item1: "สามารถจัดเก็บไฟล์ได้ทุกประเภทข้อมูลถูกเก็บรักษาอย่างปลอดภัยบนฐานข้อมูลในประเทศไทยโปร่งใสตรวจสอบได้",
      subtitle2: "ป้องกันข้อมูลภายในของบริษัท",
      item2: "ป้องกันข้อมูลภายในของบริษัทด้วยการจำกัดสิทธิ์การเข้าถึงไฟล์",
      subtitle3: "Advance Search",
      item3: "สามารถค้นหาไฟล์จากเนื้อหาสำคัญในเอกสารได้อย่างง่ายดาย",
    },
    notiloginandregister: {
      incorrectusernameorpassword: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
      emptyusernameorpassword: "กรุณากรอกชื่อผู้ใช้และรหัสผ่าน",
      incorrectpasswordnotmatchregister: "รหัสผ่านไม่ตรงกัน กรุณายืนยันรหัสผ่านอีกครั้ง",
      incorrectformetusername: "รูปแบบชื่อผู้ใช้งานไม่ถูกต้อง",
      incorrectformetpassword: "รูปแบบรหัสผ่านไม่ถูกต้อง",
      emptyregister: "กรุณากรอกข้อมูลให้ครบถ้วน",
      required: "โปรดระบุข้อมูล",
      formetentertextphone: "โปรดระบุตัวเลข 10 หลัก",
      formetenterusername: "ตัวอักษรอย่างน้อย 6 ตัว และห้ามมีอักขระพิเศษ",
      formetenterpassword: "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8",
      formetenterconfirmpassword: "รหัสผ่านไม่ตรงกัน",
      usernameduplicate: "ชื่อผู้ใช้ซ้ำ",
      mobileduplicate: "เบอร์โทรศัพท์ซ้ำ",
      incorrectlogin: "โปรดระบุข้อมูลให้ถูกต้อง",
    },
  },
  alertemailstoragefull: {
    setemailforalert: "ตั้งค่าอีเมล",
    notemailalert: "ไดรฟ์บริษัทนี้ ไม่มีการตั้งอีเมลแจ้งเตือนพื้นที่ใกล้เต็ม",
    pleaseenteremailalert: "กรุณาระบุอีเมลที่ต้องการให้ส่งแจ้งเตือน",
    setalertsuccess: "ตั้งค่าอีเมลเพื่อแจ้งเตือนสำเร็จ",
  },
  med: {
    medicalcertificate: "ใบรับรองแพทย์",
    etax: "Etax",
    hospitalname: "ชื่อโรงพยาบาล",
    filename: "ชื่อไฟล์",
    document_date: "วันที่เอกสาร",
    entry_status: "สถานะการนำเข้า One Box",
    entry_dtm: "วันที่นำเข้า One Box",
    importfilemed: "นำเข้า",
    messageimportfilemed1: "คุณต้องการนำไฟล์จำนวน",
    messageimportfilemed2: "ไฟล์เข้าสู่ One Box ของคุณหรือไม่?",
    selectfile: "เลือกไฟล์ที่ยังไม่ได้นำเข้า",
    accept: "นำไฟล์เข้าสู่ One Box",
    close: "ปิด",
    confirm: "ยืนยัน",
    cancel: "ยกเลิก",
  },
  shopdee:{
    shopdeemeekuen:"ช้อปดีมีคืน",
    messageimportfile: "คุณต้องการนำไฟล์จำนวน",
    messageimportfile: "ไฟล์เข้าสู่ One Box ของคุณหรือไม่?",
    filename: "ชื่อไฟล์",
    document_id:"เลขที่เอกสาร",
    document_type : "ประเภทเอกสาร",
    document_date: "วันที่เอกสาร",
    duplicate_status: "สถานะการนำเข้า One Box",
    selectfile: "เลือกไฟล์ที่ยังไม่ได้นำเข้า",
    accept: "นำไฟล์เข้าสู่ One Box",
    close: "ปิด",
    confirm: "ยืนยัน",
    cancel: "ยกเลิก",
    download: "ดาวน์โหลด",
    preview:"ดู"
  },
  reload_permission:"อัปเดตแผนผังองค์กรจาก One ID",
  confirm_reload_permission:"ยืนยันการอัปเดตแผนผังองค์กรจาก One ID",
  reload_permission_check:"การอัปเดตแผนผังองค์กรจาก One ID ต้องใช้เวลาในการอัปเดต",
  reload_permission_check1:"คุณต้องการอัปเดตหรือไม่ ?",
  admin_business:"แอดมินของบริษัท :",
  donthaveadminbusiness:"การยินยอมเพื่อรับเอกสารอินบ็อกซ์เป็นสิทธิ์ Admin Business เท่านั้น",
  consentlog:"ข้อมูลการยินยอม",
  setbusinesslog:"ข้อมูลการตั้งค่าบริษัท",
  conditions:"กรุณาระบุเงื่อนไข",
  textconditions:"เอกสารลงวันที่ วันที่ส่งเอกสาร หรือ เลขที่เอกสาร อย่างใดอย่างหนึ่ง เพื่อทำการค้นหาเอกสาร",
  textconditions_delete_filter:"เอกสารลงวันที่ วันที่ส่งเอกสาร หรือ เลขที่เอกสาร อย่างใดอย่างหนึ่ง เพื่อกรองเอกสารที่ต้องการลบจากฟิลเตอร์",
  textconditions_restore_filter:"เอกสารลงวันที่ วันที่ส่งเอกสาร หรือ เลขที่เอกสาร อย่างใดอย่างหนึ่ง เพื่อกรองเอกสารที่ต้องการกู้คืนจากฟิลเตอร์",
  text_want_delete:"คุณต้องการลบเอกสารที่ฟิลเตอร์ใช่หรือไม่ ?",
  text_want_delete_permanently:"คุณต้องการลบเอกสารที่ฟิลเตอร์(ถาวร)ใช่หรือไม่ ?",
  text_want_restore:"คุณต้องการกู้คืนเอกสารที่ฟิลเตอร์ใช่หรือไม่ ?",
  conditions_file_attachments:'กรุณาแนบไฟล์เอกสารที่มีการระบุข้อมูล " เลขที่เอกสาร " เพื่อทำการค้นหาเอกสาร',
  items:"รายการ",
  Number_documents:"จำนวนเอกสาร : ",
  dashboard_inboxoutbox:"แดชบอร์ด",
  status_inbox_outbox: {
    active: "ใช้งาน",
    pending: "ผู้รับพื้นที่เต็ม",
    duplicate: "เอกสารซ้ำ",
    resend_from_etax: "ใช้งาน",
    sender_delete: "ผู้ส่งขอลบ",
    request_delete_log: "ผู้ส่งขอลบ",
    reject: "ผู้รับปฎิเสธการขอลบ",
  },
  onlyoffice:{
    alert_word: "การแก้ไขออนไลน์จะทำการบันทึกลงบนไฟล์เดิม ไม่สามารถกู้คืน version เดิมก่อนแก้ไข",
    confirm: "ยืนยัน"
  },
  direct_upload: {
    savedriveof:"ไฟล์จะถูกบันทึกลงที่ไดรฟ์ของ",
    uploadfile: "อัปโหลด",
    clearfile: "เคลียร์",
    nofilewaitupload: "ไม่มีไฟล์",
    // filewaitupload: "ไฟล์ที่รออัปโหลด",
    file: "ไฟล์",
    files: "ไฟล์",
    uploadsuccess: "อัปโหลดสำเร็จ",
    uploadfail: "มีไฟล์ที่อัปโหลดไม่สำเร็จ",
    loading: "กำลังโหลด",
    pleasewait: "กรุณารอสักครู่",
    cannotlogin: "ไม่สามารถเข้าสู่ระบบได้ในขณะนี้",
    yourspacenotenough: "พื้นที่ของคุณไม่เพียงพอ",
  },
  information:{
    hashtag: "กรณีที่ต้องการใส่ข้อมูล 'แฮชแท็ก' กรุณาระบุ 'แฮชแท็ก' ก่อนอัปโหลดไฟล์",
    encrypt: "กรณีเลือกการเข้ารหัสไฟล์ คือการป้องกันการเข้าถึงไฟล์ โดยจะต้องดาวน์โหลดผ่านหน้าเว็บไซต์เท่านั้น",
    twofactorauthen: "*** การยืนยันตัวตน 2 ขั้นตอน คือ การที่ผู้ใช้งานต้องทำการยืนยันว่าตนเองว่าคือผู้ใช้งานจริง โดยระบบจะทำการส่งหมายเลข OTP ไปที่เบอร์โทรศัพท์เพื่อใช้ในการยืนยันตัวตนในการเข้าสู่ระบบ ***"
  },
  count_data_Advasearch: "ข้อมูลที่ตรงกัน",
  Usermanual: "คู่มือ MAP DRIVE",
  blockmapdrive:"ถูกบล็อคการใช้งานโดย Administrator",
  openmapdrive:"เปิดการใช้งาน",
  closemapdrive:"ปิดการใช้งาน",
  copysuccess:"คัดลอก",
  timeremaining:"เวลาที่เหลือ",
  publicpage: {
    dowload: "ดาวน์โหลด",
    preview: "ดูไฟล์",
    filename: "ชื่อไฟล์",
    filesize: "ขนาดไฟล์",
    sharedby: "แชร์โดย",
    shareddate: "แชร์เมื่อ",
    sharedfrom: "แชร์จาก",
    openby: "เปิดโดย",
    opendate: "เปิดเมื่อ",
    bizwatermark: "บริษัท",
    openfully: "ลิงก์นี้ถูกเปิดครบจำนวนครั้งที่กำหนดแล้ว",
    filefolderdelete: "ไฟล์หรือโฟลเดอร์นี้ถูกลบไปแล้ว",
    linktimeout: "ลิงก์นี้หมดเวลาแชร์แล้ว",
    filefoldernotfoundorcloseshare: "ไม่พบไฟล์/โฟลเดอร์นี้ในระบบ หรือ ไฟล์/โฟลเดอร์นี้ถูกปิดการแชร์",
    logintoopenlink: "เข้าสู่ระบบเพื่อเปิดลิงก์นี้",
    notaccesslink: "ไม่พบไฟล์/โฟลเดอร์นี้ในระบบ หรือ ไฟล์/โฟลเดอร์นี้ถูกปิดการแชร์ หรือ ลิงก์นี้หมดเวลาแชร์แล้ว หรือ ลิงก์นี้ถูกเปิดครบจำนวนครั้งที่กำหนดแล้ว",
    login: "เข้าสู่ระบบ",
    enterpasswordfilefolder: "ใส่รหัสผ่านของไฟล์/โฟลเดอร์นี้",
    cancel: "ยกเลิก",
    notpreview: "ไฟล์นี้ไม่รองรับในการดูตัวอย่าง",
  },
  sharelinkuploadpage: {
    savedriveof:"ไฟล์จะถูกบันทึกลงที่โฟลเดอร์",
    uploadfile: "อัปโหลด",
    clearfile: "เคลียร์",
    nofilewaitupload: "ไม่มีไฟล์",
    // filewaitupload: "ไฟล์ที่รออัปโหลด",
    file: "ไฟล์",
    files: "ไฟล์",
    uploadsuccess: "อัปโหลดสำเร็จ",
    uploadfail: "มีไฟล์ที่อัปโหลดไม่สำเร็จ",
    loading: "กำลังโหลด",
    pleasewait: "กรุณารอสักครู่",
    cannotlogin: "ไม่สามารถเข้าสู่ระบบได้ในขณะนี้",
    yourspacenotenough: "พื้นที่ของคุณไม่เพียงพอ",
    filename: "ชื่อไฟล์",
    pleaseinputfilename: "กรุณากรอกชื่อไฟล์โดย",
    not: "ไม่",
    fileextension: "ใส่นามสกุลไฟล์",
    notfilemoreone: "กรุณาอัปโหลดครั้งละไฟล์",
    status: "สถานะ",
    waitupload: "รออัปโหลด",
    uploading: "กำลังอัปโหลด",
    uploadsuccess: "อัปโหลดสำเร็จ",
    notupload: "ยังไม่อัปโหลด",
    remark: "ทมายเหตุ",
    failuplaod: "อัปโหลดไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
    linkexpired: "ลิงก์นี้หมดอายุแล้ว",
    disablelink: "ลิงก์นี้ถูกปิดการแชร์หรือไม่ถูกต้อง กรุณาตรวจสอบลิงก์อีกครั้ง",
    linkploblem: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
    nolink: "ลิงก์ไม่ถูกต้อง",
    clicktoupload_mobile: "คลิกเพื่ออัปโหลด !",
  },
  reportPrb: {
    headerPage: "แจ้งปัญหา",
    no_header_table: "ลำดับที่",
    header_table: "หัวข้อแจ้งรายงานปัญหา",
    date_reported: "วันที่แจ้งปัญหา",
    hottopic_header: "คำถามที่พบบ่อย",
    description_header: "คำอธิบาย"
  },
  copyandpaste:{
    copy_btn: "ทำสำเนา",
    copying_word: "กำลังคัดลอก",
    copied_success: "คัดลอกสำเร็จ",
    copy_paste_success: "คัดลอกไฟล์สำเร็จ",
    copy_paste_error: "คัดลอกไฟล์ไม่สำเร็จ",
    snackbar_clipboard_copy: "คัดลอก(เฉพาะ)รายการไฟล์ไปยังคลิปบอร์ด ...",
    snackbar_clipboard_paste: "วาง(เฉพาะ)รายการไฟล์ไปยังโฟลเดอร์ ...",
    file_copy: "คัดลอกไฟล์",
    file_pasted: "วางไฟล์",
    pasted_file_folder: "วางไฟล์ในโฟลเดอร์",
    files_copy: "คัดลอกไฟล์ทั้งหมด"
  },
  evaluategialog:{
    evaluate_title:"แบบประเมินความพึงพอใจในการใช้งาน One Box",
    evaluate_title1:"เพื่อนำข้อมูลที่ได้ไปใช้ในการพัฒนาระบบให้ตรงต่อความต้องการมากยิ่งขึ้น",
    evaluate_btn1:"ประเมินภายหลัง",
    evaluate_btn2:"เริ่มการประเมิน",
  },
  smartinput: {
    template: "เทมเพลต",
    smartinput: "อัปโหลดแบบพิเศษ",
    pleaseselectfile: "กรุณาเลือกไฟล์ที่ต้องการอัปโหลด",
    emptyaspectnull: "กรุณากรอกข้อมูลให้ครบถ้วน",
    special_aspect_error: `ห้ามมีอักขระพิเศษ < > : * ? \\ | / " '`,
    fileto: "ไฟล์ทั้งหมดจะถูกอัปโหลดไปยัง : ",
    exemplefilename: "ตัวอย่างการอ่านชื่อไฟล์ : ",
    notchose: "ไม่เลือก",
    tampletedetail: "รายละเอียดเทมเพลต",
    create_template: "สร้างเทมเพลต",
    edit_template: "แก้ไขเทมเพลต",
    view_template: "รายละเอียดเทมเพลต",
    search_template_name: "ค้นหาชื่อเทมเพลต",
    no_data_templates: "ไม่มีเทมเพลต",
    selectfoldertemplate: "กำหนดโฟลเดอร์เทมเพลต",
    createnewfolder: "สร้างโฟลเดอร์ใหม่",
    select: "เลือก",
    selecthere: "เลือกโฟลเดอร์นี้",
    foldertemplate: "โฟลเดอร์เทมเพลต : ",
    fomatnamefile: "รูปแบบของชื่อไฟล์",
    yourfileformatwrong: "รูปแบบของชื่อไฟล์ไม่ถูกต้อง",
    valuesamedropdown: "ค่าจากชื่อไฟล์ไม่ตรงกับตัวเลือก",
    notyetchosen: "ยังไม่ได้เลือก",
    pleaseselectfoldertemplate: "กรุณาเลือกโฟลเดอร์เทมเพลต",
    permission_denied: "ไม่มีสิทธิ์เลือก",
    header_table: {
      template_name: "ชื่อเทมเพลต",
      template_owner: "ผู้สร้างเทมเพลต",
      template_date: "วันที่สร้าง",
      template_manage: "จัดการ"
    },
    template_form: {
      field_key: "หัวข้อคีย์",
      field_name: "ชื่อหัวข้อ",
      field_type: "ประเภทหัวข้อ",
      field_dropdown: "ตัวเลือก ",
      field_dropdown_mobile: "ตัวเลือก ",
      field_reuqired: "จำเป็นต้องระบุ"
    },
    select_default: {
      item_text: "ข้อความ",
      item_date: "วันที่",
      item_dropdown: "ตัวเลือก"
    },
    template_detail: "ดูรายละเอียด",
    template_edit: "แก้ไข",
    btn_add_field: "เพิ่มหัวข้อ",
    btn_delete_field: "ลบหัวข้อ",
    btn_cancel: "ยกเลิก",
    btn_save: "บันทึก",
    btn_save_change: "บันทึกแก้ไข",
    field_active: "เปิดใช้งาน",
    field_non_active: "ปิดใช้งาน",
    error_message_field: "โปรดระบุชื่อTemplate และห้ามใส่ ' หรือ /",
    error_message_dup: "ชื่อฟิลด์คีย์ซ้ำกัน",
    warning_search_smup:"กรุณากรอกข้อมูลเพื่อค้นหา",
    emptry_aspect: "ไม่มีหัวข้อ",
    message_suggession: `ระบุชื่อเทมเพลตตัวอักษรไม่เกิน 50 ตัวอักษรและห้ามมีอักขระพิเศษ < > : * ? \\ | / " '`,
    error_message_field_name: `โปรดระบุชื่อเทมเพลตที่ไม่เกิน 50 ตัวอักษรและห้ามมีอักขระพิเศษ < > : * ? \\ | / " '`,
    error_message_field_key: `โปรดระบุหัวข้อคีย์ที่ไม่เกิน 50 ตัวอักษรและห้ามมีอักขระพิเศษ < > : * ? \\ | / " '`,
    error_message_field_labelname: `โปรดระบุชื่อหัวข้อที่ไม่เกิน 50 ตัวอักษรและห้ามมีอักขระพิเศษ < > : * ? \\ | / " '`,
    error_message_field_dropdown: `โปรดระบุตัวเลือกที่ไม่เกิน 100 ตัวอักษรและห้ามมีอักขระพิเศษ < > : * ? \\ | / " '`,
    error_message_field_symbol: `โปรดระบุสัญลักษณ์ 1 ตัวอักษรและห้ามมีอักขระพิเศษ < > : * ? \\ | / " '`,
    error_message_dup: "ชื่อหัวข้อคีย์ซ้ำกัน",
    cannotgettemplate: "ไม่สามารถโหลดข้อมูลเทมเพลตได้ กรุณาติดต่อผู้ดูแลระบบ",
    cannotgetdetailtemplate: "ไม่สามารถโหลดข้อมูลรายละเอียดเทมเพลตได้ กรุณาติดต่อผู้ดูแลระบบ",
    success_create_template: "สร้างเทมเพลตสำเร็จ",
    error_create_template: "สร้างเทมเพลตไม่สำเร็จ",
    success_edit_template: "แก้ไขเทมเพลตสำเร็จ",
    error_edit_template: "แก้ไขเทมเพลตไม่สำเร็จ",
    error_some: "เกิดข้อผิดพลาดบางอย่าง",
    field_name_detail: `"ชื่อหัวข้อ" จะถูกนำไปแสดงในเมนูอัปโหลดแบบพิเศษ`,
    downloadreportfilesmartupload: "ดาวน์โหลดรายงานอัปโหลดแบบพิเศษ",
    nofileforreport: "ไม่มีไฟล์สำหรับดาวน์โหลดรายงาน",
    datagoogledrive: "ไฟล์/โฟลเดอร์ใน Google Drive",
    filename_search_infor: "สำหรับผู้ใช้ที่ต้องการค้นหา 2 ไฟล์ขึ้นไป ",
    filename_search_infor_2: "สามารถใช้สัญลักษณ์ , คั่นระหว่างชื่อไฟล์",
    filename_search_infor_new2: "สามารถกดไอคอน i เพื่อกรอกสัญลักษณ์สำหรับการคั่นระหว่างข้อมูล",
    filename_search_infor_3: "กรุณากรอกสัญลักษณ์สำหรับการคั่นระหว่างข้อมูล",

  },
  renamedocument: {
    renamedocumentheader: "ตั้งค่าเปลี่ยนชื่อเอกสารดาวน์โหลด",
    selectformatrenamedocument:"กำหนดรูปแบบเปลี่ยนชื่อเอกสารดาวน์โหลด",
    selectformatrenamedocumentsuccess:"กำหนดรูปแบบเปลี่ยนชื่อเอกสารดาวน์โหลดสำเร็จ",
    selectformatrenamedocumentfail: "ไม่สามารถกำหนดรูปแบบเปลี่ยนชื่อเอกสารดาวน์โหลดได้ กรุณาติดต่อผู้ดูแลระบบ",
    default: "ทุกประเภทเอกสาร",
    createdate: "วันที่สร้าง",
    example: "ตัวอย่าง",
    doctype: "ประเภทเอกสาร",
    format: "รูปแบบ",
    SellerName: "ชื่อผู้ส่ง",
    SellerTaxID: "เลขประจำตัวผู้เสียภาษีผู้ส่ง",
    SellerBranchID: "เลขที่สาขาผู้ส่ง",
    BuyerName: "ชื่อผู้รับ",
    BuyerTaxID: "เลขประจำตัวผู้เสียภาษีผู้รับ",
    BuyerBranchID: "เลขที่สาขาผู้รับ",
    DocumentTypeCode: "เลขที่ประเภทเอกสาร",
    DocumentNumber: "เลขที่เอกสาร",
    DocumentDate: "เอกสารลงวันที่",
    textandsymbol: "ข้อความและสัญลักษณ์",
    selectall: "กำหนดรูปแบบสำหรับประเภทเอกสารทั้งหมด",
    notselect: "ยังไม่ได้เลือก",
    emptysetting: "ไม่มีการตั้งค่า",
    titleformat: "สามารถระบุรูปแบบได้สูงสุด 3 แบบ ยกเว้นข้อความและสัญลักณ์",
    confirmdeleterename: "ยืนยันลบการตั้งค่าเปลี่ยนชื่อเอกสารดาวน์โหลด",
    textconfirmdeleterename: "คุณต้องการลบการตั้งค่าเปลี่ยนชื่อเอกสารดาวน์โหลดนี้ ?",
    confirmreplaterename: "ยืนยันแทนที่การตั้งค่าเปลี่ยนชื่อเอกสารดาวน์โหลด",
    textconfirmreplaterename: "มีการเปิดใช้งานการตั้งค่าแล้ว ต้องการแทนที่การตั้งค่าเปลี่ยนชื่อเอกสารดาวน์โหลดหรือไม่ ?",
    deleterename: "ลบการตั้งค่า",
    editrename: "แก้ไขการตั้งค่า",
    openrename: "เปิดการใช้งาน",
    closerename: "ปิดการใช้งาน",
    disablesuccess: "ปิดการใช้งานสำเร็จ",
    activatesuccess: "เปิดการใช้งานสำเร็จ",
    cannotalreadyenabled: "เอกสารประเภทนี้ถูกเปิดใช้งานแล้ว กรุณาเลือกหนึ่งเทมเพลต",
    cannotdisableactivate: "ไม่สามารถเปิด/ปิดการใช้งานได้ กรุณาติดต่อผู้ดูแลระบบ",
    deletesuccess: "ลบการตั้งค่าสำเร็จ",
    type: "ประเภท",
    nospecialcharacter: `ต้องไม่มีอักขระพิเศษ < > : * ? \\ | / " '`,
    addfilename: "กรุณากรอกข้อความ / สัญลักษณ์",
    infodefault1: "กรณีที่ไม่มีการกำหนดรูปแบบ",
    infodefault2: "ค่าเริ่มต้น คือ  DocumentNumber_DocumentDate.pdf",
  },
  uniquefoldername:"ไฟล์โดนย้ายแล้ว",
  anotheraccount:"เชื่อมบัญชีอื่น",
  migratetogg:"ไฟล์/โฟลเดอร์ที่ต้องการย้าย",
  migratesuccess:"ย้ายจาก google drive สำเร็จ",
  nochoosefilemigrate:"กรุณาเลือกไฟล์หรือโฟลเดอร์ที่ต้องการ Migrate ไปยัง One Box",
  requestdownload:{
    setting_request:"ตั้งค่าการส่งคำขอดาวน์โหลด",
    requesttodownload:"ส่งคำขอดาวน์โหลด",
    setting_btn:"ตั้งค่า",
    canclesetting:"ปิดการตั้งค่า",
    opensetting:"เปิดการตั้งค่า",
    appove_request:"อนุมัติคำขอ",
    no_appove_request:"ไม่อนุมัติ",
    settime_download:"กำหนดเวลาการดาวน์โหลด",
    send_request:"ส่งคำขอ",
    close:"ปิด",
    email_request:"*กรุณากรอกข้อมูลอีเมลที่ต้องการส่งคำขอเพื่อดาวน์โหลด",
  }
};