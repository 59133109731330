import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "../plugins/i18n.js";
import store from "../store";
import axios from "axios";
import { parseJSON } from "date-fns";
import { register } from "register-service-worker";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";
// import OneBox_Login from '../views/onebox-login.vue'
// import OneBox_myfiles from '../views/onebox-myfiles.vue'
// import OneBox_directory from '../views/onebox-directoryfile.vue'
// import OneBox_starfiles from '../views/onebox-starredfiles.vue'
// import OneBox_trashfiles from '../views/onebox-trash.vue'

// Landing Page
const Onebox_test = () => import("../views/onebox-test.vue");
const OneBox_LandingPage = () =>
  import("../components/landingpage/onebox-landingpage.vue");
const OneBox_LandingPage2 = () =>
  import("../components/landingpage/onebox-landingpage.vue");
const LandingPage_Data = () =>
  import("../components/landingpage/onebox-dataprotection.vue");
const LandingPage_Use = () =>
  import("../components/landingpage/onebox-datause.vue");
const LandingPage_Etax = () =>
  import("../components/landingpage/onebox-dataetax.vue");
const OneBox_Login = () => import("../views/onebox-login.vue");
const OneBox_Pagenotfound = () => import("../views/onebox-pagenotfound.vue");
const OneBox_Login_register = () => import("../views/onebox-login-register.vue");
const OneBox_register = () => import("../views/onebox-register.vue");
const OneBox_registerbusiness = () => import("../views/onebox-registerbusiness.vue");
const OneBox_register_mohpromt = () => import("../views/onebox-register_mohpromt.vue");
const OneBox_register_mohpromt2 = () => import("../views/onebox-register_mohpromt2.vue");
const OneBox_login_redirectinbox = () => import("../views/onebox-login-redirectinbox.vue");
const OneBox_redirectdirectory = () => import("../views/onebox-redirectdirectory.vue");
const OneBox_login_sharedtoken = () => import("../views/onebox-login-sharedtoken.vue");
const OneBox_login_sharedtoken_ad = () => import("../views/onebox-login-sharedtoken-ad.vue");
const OneBox_login_sharedtoken_taxbox = () => import("../views/onebox-login-sharetoken-taxbox.vue");
const OneBox_login_business_sharedtoken = () => import("../views/onebox-login-business-sharedtoken.vue");
const OneBox_loginbusiness_by_sharetoken = () => import("../views/onebox-login-landingbusiness.vue");
const OneBox_login_business_sharedtoken_oneplatfrom = () => import("../views/onebox-login-accesstoken-oneplatfrom.vue");
const OneBox_login_default = () => import("../views/onebox-login-accesstoken.vue");
const OneBox_easy_login = () => import("../views/onebox-login-mobile.vue");
const OneBox_loginwithtoken = () => import("../views/onebox-login-withtoken.vue");
const OneBox_checkregister = () => import("../views/onebox-checkregister.vue");
const OneBox_myfiles = () => import("../views/onebox-myfiles-scrolling-new.vue");
const OneBox_filesbusiness = () => import("../views/onebox-filesbusiness-scrolling.vue");
const OneBox_directory = () => import("../views/onebox-directoryfile-scrolling-new.vue");
const OneBox_starfiles = () => import("../views/onebox-starredfiles.vue");
const OneBox_dashboardinbox = () => import("../views/onebox-dashboardinbox.vue");
const OneBox_dashboardoutbox = () => import("../views/onebox-dashboardoutbox.vue");
const OneBox_trashfiles = () => import("../views/onebox-trash.vue");
const OneBox_library = () => import("../views/onebox-library.vue");
const OneBox_publicfolder = () => import("../views/onebox-publicfolder.vue");
const OneBox_public = () => import("../views/onebox-public.vue");
const OneBox_publicfile = () => import("../views/onebox-publicfile.vue");
const OneBox_terminate = () => import("../views/onebox-terminateservice.vue");
const OneBox_dcm = () => import("../views/onebox-dcm.vue");

const OneBox_inboxsystem_citizen = () => import("../views/onebox-inbox-citizen.vue");
const OneBox_inboxsystem = () => import("../views/onebox-inboxsystem.vue");
const OneBox_inboxsourcesystem = () =>
  import("../views/onebox-inboxsourcesystem.vue");
const OneBox_allbusinessinbox = () =>
  import("../views/onebox-allbusinessinbox.vue");
const OneBox_allbusinessoutbox = () =>
  import("../views/onebox-allbusinessoutbox.vue");
const OneBox_sharedwithme = () => import("../views/onebox-sharedwithme.vue");
const OneBox_admindashboard = () => import("../views/admin-dashboard.vue");
const OneBox_adminsetting = () => import("../views/admin-settingadmin.vue");
const OneBox_adminsetemail = () => import("../views/admin-setemail.vue");
const OneBox_adminindex = () => import("../views/admin-index.vue");
const OneBox_admingeneratekey = () => import("../views/admin-generatekey.vue");
const OneBox_adminconsent = () => import("../views/admin-consent.vue");
const OneBox_adminrecheckimap = () => import("../views/admin-recheckimap.vue");
const OneBox_adminlog = () => import("../views/admin-log.vue");
const OneBox_adminfileandfolderbackup = () => import("../views/admin-fileandfolderbackup.vue");
const OneBox_admintemplate = () => import("../views/admin-template.vue");
const OneBox_personallog = () => import("../views/onebox-personal-log.vue");
const OneBox_adminmydrive = () => import("../views/admin-mydrive.vue");
const OneBox_adminmanagefile = () => import("../views/admin-managefile.vue");
const OneBox_adminmanagelogo = () => import("../views/admin-managelogo.vue");
const OneBox_adminmanagestorage = () =>
  import("../views/admin-managestorage.vue");
const OneBox_adminmanageinbox = () => import("../views/admin-manageinbox.vue");
const OneBox_adminmanageoutbox = () =>
  import("../views/admin-manageoutbox.vue");
const OneBox_adminmanagefeature = () =>
  import("../views/admin-managefeature.vue");
const OneBox_adminmanagedirectoryfile = () =>
  import("../views/admin-managedirectoryfile.vue");
const OneBox_outboxsystem = () => import("../views/onebox-outboxsystem.vue");
const OneBox_outboxsourcesystem = () =>
  import("../views/onebox-outboxsourcesystem.vue");
const OneBox_directorysharefile = () =>
  import("../views/onebox-directorysharefile.vue");
const OneBox_allbusinessinboxsystem = () =>
  import("../views/onebox-allbusinessinboxsystem.vue");
const OneBox_allbusinessoutboxsystem = () =>
  import("../views/onebox-allbusinessoutboxsystem.vue");
const Onebox_managestorageonebox = () =>
  import("../views/onebox-managestorageonebox.vue");
const Onebox_managefunction = () =>
  import("../views/onebox-managefunction.vue");
const Onebox_trashinbox = () => import("../views/onebox-trashinbox.vue");
const Onebox_trashoutbox = () => import("../views/onebox-trashoutbox.vue");
const Onebox_loginbysharetoken = () =>
  import("../views/onebox-loginbysharetoken.vue");

const Onebox_loginbusinessbytoken = () =>
  import("../views/onebox-loginbusinessbytoken.vue");
const OneBox_directuploadfiles = () => import("../views/onebox-directuploadfiles.vue");
const OneBox_sharelinkuploads = () => import("../views/onebox-sharelinkuploads.vue");
const Onebox_loginsharelink = () => import("../views/onebox-loginsharelink.vue");

const Onebox_cctv = () => import("../views/onebox-cctv.vue");
const Onebox_loginbydomain = () => import("../views/onebox-loginbydomain.vue");
const Onebox_consent = () => import("../views/onebox-consent.vue");
const Onebox_fileexpired = () => import("../views/onebox-fileexpired.vue");
const Onebox_Package = () => import("../views/onebox-package.vue");
const Onebox_Package_new = () => import("../views/onebox-package-new.vue");
const Onebox_Report = () => import("../views/onebox-report.vue"); // new report problem
const Onebox_packagehistory = () => import("../views/onebox-packagpurchasehistory.vue");
const Onebox_MyPackage = () => import("../views/onebox-mypackage.vue");
const Onebox_Payment = () => import("../views/onebox-resultpayment.vue");
const Onebox_packagelistpay = () => import("../views/onebox-packagelistpay.vue");
const Onebox_packagecurrent = () => import("../views/onebox-package_citizen.vue");
const Onebox_listpackage = () => import("../views/onebox-list-package.vue")
const Onebox_preview = () => import("../views/onebox-previewnewtab.vue");
const Onebox_loading = () =>
  import("../views/onebox-loading.vue");
const Onebox_loginsharelinktokenunavailable = () => import("../views/onebox-loginsharelink-tokenunavailable.vue");

const OneBox_business = () =>
  import("../components/layout/layout-business.vue");
const OneBox_business_login = () =>
  import("../views/business/businessonebox-login.vue");
const OneBox_business_allinbox = () =>
  import("../views/business/businessonebox-allinbox.vue");
const OneBox_business_alloutbox = () =>
  import("../views/business/businessonebox-alloutbox.vue");
const OneBox_business_allinboxsystem = () =>
  import("@/views/business/businessonebox-allinboxsystem.vue");
const OneBox_business_alloutboxsystem = () =>
  import("@/views/business/businessonebox-alloutboxsystem.vue");
const OneBox_business_allinboxsourcesystem = () =>
  import("@/views/business/businessonebox-allinboxsourcesystem.vue");
const OneBox_business_alloutboxsourcesystem = () =>
  import("@/views/business/businessonebox-alloutboxsourcesystem.vue");
const OneBox_File_expired_inbox = () =>
  import("@/views/onebox-fileexpiredinbox.vue")
const OneBox_File_expired_outbox = () =>
  import("@/views/onebox-fileexpiredoutbox.vue")

const OneBox_myinboxetax = () => import("../views/onebox-inbox-new.vue");
const OneBox_inboxsystem_new = () => import("../views/onebox-inboxsystem-new")
const OneBox_allinbox = () => import("../views/onebox-allinbox-new.vue");
const OneBox_allinboxsystem = () => import("../views/onebox-allinboxsystem-new.vue");

const OneBox_myoutboxetax = () => import("../views/onebox-outbox-new.vue");
const OneBox_outboxsystem_new = () => import("../views/onebox-outboxsystem-new.vue");
const OneBox_alloutbox = () => import("../views/onebox-alloutbox-new.vue");
const OneBox_alloutboxsystem = () => import("../views/onebox-alloutboxsystem-new.vue");
const Onebox_policy = () => import("../views/onebox-policy.vue");
const Onebox_redirectevaluate = () => import("../views/onebox-redirectevaluate.vue");
const Onebox_redirectfromgoogledrive = () => import("../views/onebox-redirectfromconectgoogledrive.vue");
const Onebox_redirectrequestdownload = () => import("../views/onebox-redirectapprovedownload.vue");
const Onebox_redirectdownloadfromrequest = () => import("../views/onebox-redirectdownloadfromerequest.vue");

Vue.use(VueRouter);
Vue.use(VueCookies);

// เพิ่มตรงนี้ => 
const diff_minutes = (dt2, dt1) => {
  var diff =(dt2 - dt1) / 1000;
  diff /= 60;
  console.log("Time USED ", Math.abs(Math.round(diff)))
  return Math.abs(Math.round(diff))
};

const decryptdata = (data) => {
  try{
  var data_decrypt = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
  data_decrypt = window.atob(data_decrypt.toString(CryptoJS.enc.Utf8)) 
  return data_decrypt
  } catch(err) {
    return "error"
  }
};

const check_time_used = (next) => {
  var specific_account = decryptdata(VueCookies.get("username"))
  // เช็คก่อนว่าใช่ account "botinebox" มั้ย
  if( specific_account === process.env.VUE_APP_ACCOUNT_BOT){
    // ต้องมีการเช็คเวลาตรงนี้
    var check_isExist_login_logindatetime = VueCookies.isKey("login_datetime")
    var check_logindatetime = decryptdata(VueCookies.get("login_datetime"))
    if(check_isExist_login_logindatetime === true && VueCookies.isKey("data_id")){
      var timeUsed = diff_minutes(new Date().getTime(), new Date(check_logindatetime).getTime())
      if((timeUsed <= 10 && VueCookies.get("continue_otp") === "false") && !(timeUsed <= 0)){
        //เรียกใช้งาน VUEX ในการแสดง dialog
        store.dispatch("triggle_otp_botonebox_manage", {status: "WARNING", show: true})
      } else if (timeUsed <= 0) { 
        //เรียกใช้งาน VUEX ในการแสดง dialog
        store.dispatch("triggle_otp_botonebox_manage", {status: "TIMEOUT", show: true})
      }
      next()
    } else {
      next("/")
    }
  } else {
    //ไม่ใช่ account botonebox
    next()
  }
  ///////
}


const ifAuthenticatedAdmin = (to, from, next) => {
  //if(store.state.role_level === "True"){
  if (store.getters.dataAuthorize === true) {
    if (store.state.role_level !== "True") {
      next("/mydrive");
    } else {
      store
        .dispatch("check_storage", store.getters.dataAccountId)
        .then(res => {check_time_used(next)});
      //next();
    }
    return;
  } else {
    store.dispatch("authorize_checking").then(res => {
      if (res.status === "Access Granted") {
        const data_buffer = sessionStorage.getItem("biz_active");
        const data_biz = JSON.parse(data_buffer);
        console.log("data_biz", data_biz);

        if (data_biz !== null) {
          store.dispatch("switchaccount_business", data_biz).then(res => {
            if (res.status === "Business Access Granted") {
              store
                .dispatch("check_storage", store.getters.dataAccountId)
                .then(res => { });
              if (store.state.role_level !== "True") {
                next("/mydrive");
              } else {
                // RELOAD PAGE ONLY MANAGE LOGO CHECK FEATURE
                if(to.path === '/onebox-system-admin-managelogo'){
                  console.log("หยุดก่ออนน ", )
                  if(store.state.feature_logo){ // FEATURE OPEN
                    check_time_used(next) // เพิ่ม
                  } else { // FEATURE CLOSE
                    next('/onebox-system-admin-index')
                  }
                } else if(to.path === '/onebox-system-admin-template') {
                  console.log("หยุดก่ออนน เช็คก่อน", )
                  if(store.state.feature_inputmore){ // FEATURE OPEN
                    check_time_used(next) // เพิ่ม
                  } else { // FEATURE CLOSE
                    next('/onebox-system-admin-index')
                  }
                } 
                else {
                  check_time_used(next) // เพิ่ม
                }
                //next()
              }

            } else {
              next("/");
            }
          });
        } else {
          store
            .dispatch("check_storage", store.getters.dataAccountId)
            .then(res => {check_time_used(next)}); // เพิ่ม
          //next();

        }
      } else {
        next("/");
      }
    });
    return;
  }
  //}else{
  //  next("/login");
  //}
};


const ifAuthenticated = (to, from, next) => {
  //console.log(VueCookies.isKey('token_auth'),VueCookies.isKey('token'),VueCookies.get('token_auth'));

  if (sessionStorage.getItem("checklogin") == 'false') { // ไม่จดจำฉัน
    if ((sessionStorage.getItem('token_auth') !== null && sessionStorage.getItem('token_auth') === 'true' && sessionStorage.getItem('token') !== null) || store.state.service === 'OneMail') {
      //console.log("wwwwww");
      VueCookies.remove('token_auth')
      VueCookies.remove('token')
      VueCookies.remove('checklogin')
      if (store.getters.dataAuthorize === true) { // เปลี่ยนพาทแบบ redirect
        store
          .dispatch("check_storage", store.getters.dataAccountId)
          .then(res => {check_time_used(next)});
        // next();
        return;
      } else { // ก็อปพาทวาง enter
        store.dispatch("authorize_checking").then(res => {
          if (res.status === "Access Granted") {
            const data_buffer = sessionStorage.getItem("biz_active");
            const data_biz = JSON.parse(data_buffer);
            console.log("data_biz", data_biz);

            if (data_biz !== null) {
              store.dispatch("switchaccount_business", data_biz).then(res => {
                store
                  .dispatch("check_storage", store.getters.dataAccountId)
                  .then(res => {check_time_used(next)});
                // next();
                return;
              });
            } else {
              store
                .dispatch("check_storage", store.getters.dataAccountId)
                .then(res => {check_time_used(next)});
              //next();
              return;
            }
          } else {
            next("/");
          }
        });
      }
    } else {
      //console.log("ddddd");
      VueCookies.set('SessionExpired', 'true')
      next("/");
      // store.dispatch("authorize_checking").then(res => {
      //   if (res.status === "Access Granted") {

      //     next("/login");

      //   } else {
      //     next("/login");
      //   }
      // });
    }
  } else { // จดจำฉัน
    if ((VueCookies.isKey('token_auth') === true && VueCookies.get('token_auth') === 'true' && VueCookies.isKey('token') === true) || store.state.service === 'OneMail') {
      //console.log("wwwwww");
      if (store.getters.dataAuthorize === true) {
        store
          .dispatch("check_storage", store.getters.dataAccountId)
          .then(res => { 
            check_time_used(next)
          });
          // next();
        return;
      } else { // เช็คการเข้าใช้งานครั้งแรก
        store.dispatch("authorize_checking").then(res => {
          if (res.status === "Access Granted") {
            const data_buffer = sessionStorage.getItem("biz_active");
            const data_biz = JSON.parse(data_buffer);
            console.log("data_biz", data_biz);

            if (data_biz !== null) {
              store.dispatch("switchaccount_business", data_biz).then(res => {
                store
                  .dispatch("check_storage", store.getters.dataAccountId)
                  .then(res => {check_time_used(next)});
                //next();
                return;
              });
            } else {
              store
                .dispatch("check_storage", store.getters.dataAccountId)
                .then(res => {check_time_used(next)});
              //next();
              return;
            }
          } else {
            next("/");
          }
        });
      }
    } else {
      //console.log("ddddd");
      // ในกรณีที่มีการเปลี่ยน path พอดีกับ token_auth หมดอายุ
      //มัยขอเพิ่มนะ
      if (VueCookies.get("username") !== null && VueCookies.get("username") !== "" && VueCookies.get("username") !== undefined) {
        let username_show = decryptdata(VueCookies.get("username"))
        if(username_show === process.env.VUE_APP_ACCOUNT_BOT){
            if (store.getters.dataAuthorize === true) {
              store.dispatch("triggle_otp_botonebox_manage", {status: "TIMEOUT", show: true})
              next()
              return
            } else { // เช็คการเข้าใช้งานครั้งแรก
              store.dispatch("triggle_otp_botonebox_manage", {status: "", show: false})
              VueCookies.set('SessionExpired', 'true')
              next("/");
              return
            }
            // store.dispatch("triggle_otp_botonebox_manage", {status: "TIMEOUT", show: true})
            // next()
            // return
        } else {
          //แอคเค้าทั่วไป
          VueCookies.set('SessionExpired', 'true')
          next("/");
          return
        }
      } else { // ไม่มีการ login อะไรเลย
        VueCookies.set('SessionExpired', 'true')
        next("/");
      }
      //จิงๆมีแค่ 2 อันนี้
      // VueCookies.set('SessionExpired', 'true')
      // next("/");

      // store.dispatch("authorize_checking").then(res => {
      //   if (res.status === "Access Granted") {

      //     next("/login");

      //   } else {
      //     next("/login");
      //   }
      // });
    }
  }
};

const ifAuthenticatedBusiness = (to, from, next) => {
  if (store.getters.dataAuthorize === true) {
    store
      .dispatch("check_storage", store.getters.dataAccountId)
      .then(res => { check_time_used(next) });
    //next();
    return;
  } else {
    store.dispatch("authorize_checking").then(res => {
      if (res.status === "Access Granted") {
        store
          .dispatch("check_storage", store.getters.dataAccountId)
          .then(res => { check_time_used(next) });
        //next();
        return;
      } else {
        next("/business/login");
      }
    });
  }
};

const ifAuthenticatedSharelink = (to, from, next) => {
  //console.log(VueCookies.isKey('token_auth'),VueCookies.isKey('token'),VueCookies.get('token_auth'));
  if (sessionStorage.getItem("checklogin") == 'false') {
    if ((sessionStorage.getItem('token_auth') !== null && sessionStorage.getItem('token_auth') === 'true' && sessionStorage.getItem('token') !== null) || store.state.service === 'OneMail') {
      //console.log("wwwwww");
      VueCookies.remove('token_auth')
      VueCookies.remove('token')
      VueCookies.remove('checklogin')
      if (store.getters.dataAuthorize === true) {
        store
          .dispatch("check_storage", store.getters.dataAccountId)
          .then(res => { });
        next();
        return;
      } else {
        store.dispatch("authorize_checking").then(res => {
          if (res.status === "Access Granted") {
            const data_buffer = sessionStorage.getItem("biz_active");
            const data_biz = JSON.parse(data_buffer);
            console.log("data_biz", data_biz);

            if (data_biz !== null) {
              store.dispatch("switchaccount_business", data_biz).then(res => {
                store
                  .dispatch("check_storage", store.getters.dataAccountId)
                  .then(res => { });
                next();
                return;
              });
            } else {
              store
                .dispatch("check_storage", store.getters.dataAccountId)
                .then(res => { });
              next();
              return;
            }
          } else {
            next("/");
          }
        });
      }
    } else {
      // console.log('to',to);
      next({
        path: "/login_sharelink",
        query: {
          nextUrl: to.fullPath
        }
      });
    }
  } else {
    if ((VueCookies.isKey('token_auth') === true && VueCookies.get('token_auth') === 'true' && VueCookies.isKey('token') === true) || store.state.service === 'OneMail') {
      //console.log("wwwwww");
      if (store.getters.dataAuthorize === true) {
        store
          .dispatch("check_storage", store.getters.dataAccountId)
          .then(res => { });
        next();
        return;
      } else {
        store.dispatch("authorize_checking").then(res => {
          if (res.status === "Access Granted") {
            const data_buffer = sessionStorage.getItem("biz_active");
            const data_biz = JSON.parse(data_buffer);
            console.log("data_biz", data_biz);

            if (data_biz !== null) {
              store.dispatch("switchaccount_business", data_biz).then(res => {
                store
                  .dispatch("check_storage", store.getters.dataAccountId)
                  .then(res => { });
                next();
                return;
              });
            } else {
              store
                .dispatch("check_storage", store.getters.dataAccountId)
                .then(res => { });
              next();
              return;
            }
          } else {
            next("/");
          }
        });
      }
    } else {
      // console.log('to',to);
      next({
        path: "/login_sharelink",
        query: {
          nextUrl: to.fullPath
        }
      });
    }
  }
};

const logout = (to, from, next) => {
  if (process.env.NODE_ENV === "production") {
    if (VueCookies.get("check_mohpromt") == "true") {
      // ของเก่าา => ซึ่งยังไม่มั้นใจว่ายังมีการใช้งานอีกมั้ย
      // store
      //   .dispatch("authorize_expired")
      //   .then(next("/moph/loginwithid"))
      //   .catch(next("/moph/loginwithid"));
      // ของใหม่ => เลยเอามาเด้งกลับไปพาท default ก่อน
      store
        .dispatch("authorize_expired")
        .then(next("/"))
        .catch(next("/"));
    } else if (VueCookies.get("azure") === "1") {
      store
        .dispatch("authorize_expired")
        .then(() => window.location.assign(process.env.VUE_APP_ONEPLATFORM + "/manage/api/v1/azure/logout-by-service?service_name=onebox"))
    } else {
      store
        .dispatch("authorize_expired")
        .then(
          location.assign(
            process.env.VUE_APP_ONEID +
            "/api/oauth/logout?redirect_url=" +
            process.env.VUE_APP_UI
          )
        )
        .catch(
          location.assign(
            process.env.VUE_APP_ONEID +
            "/api/oauth/logout?redirect_url=" +
            process.env.VUE_APP_UI
          )
        );
    }
  } else {
    if (VueCookies.get("check_mohpromt") == "true") {
      // ของเก่าา => ซึ่งยังไม่มั้นใจว่ายังมีการใช้งานอีกมั้ย
      // store
      //   .dispatch("authorize_expired")
      //   .then(next("/moph/loginwithid"))
      //   .catch(next("/moph/loginwithid"));
      // ของใหม่ => เลยเอามาเด้งกลับไปพาท default ก่อน
      store
        .dispatch("authorize_expired")
        .then(next("/"))
        .catch(next("/"));
    } else if (VueCookies.get("azure") === "1") {
      store
        .dispatch("authorize_expired")
        .then(() => window.location.assign(process.env.VUE_APP_ONEPLATFORM + "/manage/api/v1/azure/logout-by-service?service_name=onebox"))
    } else {
      store
        .dispatch("authorize_expired")
        .then(next("/"))
        .catch(next("/"));
    }
  }
};

const logout_ = (to, from, next) => {
  // store
  //   .dispatch("authorize_expired")
  //   .then(
  //     location.assign(
  //       process.env.VUE_APP_ONEID +
  //         "/api/oauth/logout?redirect_url=" +
  //         process.env.VUE_APP_UI
  //     )
  //   )
  //   .catch(
  //     location.assign(
  //       process.env.VUE_APP_ONEID +
  //         "/api/oauth/logout?redirect_url=" +
  //         process.env.VUE_APP_UI
  //     )
  //   );
  store
    .dispatch("authorize_expired")
    .then(next("/"))
    .catch(next("/"));
};

const logoutbusiness = (to, from, next) => {
  store
    .dispatch("authorize_expired")
    .then(next("/business/login"))
    .catch(error => {
      next("/business/login");
      console.log(error);
    });
};

const loginbysso = (to, from, next) => {
  if (process.env.NODE_ENV === "production") {
    location.assign(process.env.VUE_APP_ONEIDSSO);
  } else {
    next("/login");
  }
};

const loginsms = (to, from, next) => {
  // if (process.env.NODE_ENV === "production") {
    location.assign(process.env.VUE_APP_ONEIDSSO);
  // } 
  // else {
  //   next("/login");
  // }
};

const loginbysharetoken = (to, from, next) => {
  if ("token" in to.query) {
    axios
      .post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API +
        "/api/login_by_sharetoken_oneid",
        { sharetoken: to.query.token }
      )
      .then(response => {
        if (response.data.status == "OK") {
          let encodetoken = window.btoa(response.data.result["accesstoken"])
            localStorage.setItem("token", encodetoken); //ไม่ได้ใช้งาน
          // localStorage.setItem("token", response.data.result["accesstoken"]);
          VueCookies.set('token', response.data.result["accesstoken"]);
          VueCookies.set('token_auth', true);
          store.dispatch("authorize_granted", response.data.result);
          next("/mydrive");
        } else {
          next("/");
        }
      })
      .catch(error => {
        console.log(error);
        next("/");
      });
  } else {
    next("/");
  }
};

const routes = [
  // <v-btn @click="$router.push('/loginbysso')">SSO</v-btn>
  {
    path: "*",
    component: OneBox_Pagenotfound
  },
  {
    path: "/",
    name: "home",
    component: OneBox_LandingPage,
    meta: { title: "One Box Storage - Collaboration of One Platform" }
  },
  {
    path: "/moph/loginwithid",
    name: "home",
    component: OneBox_LandingPage2,
    meta: { title: "One Box Storage - Collaboration of One Platform" }
  },
  {
    path: "/detailfeature",
    name: "detailfeature",
    component: Onebox_test,
    meta: { title: "Package One Box" }
  },
  {
    path: "/listpackage",
    name: "listpackage",
    component: Onebox_listpackage,
    meta: { title: "List Package | One Box" }
  },
  {
    path: "/dataprotection",
    name: "Data",
    component: LandingPage_Data,
    meta: { title: "Data Protection Policy | One Box" }
  },
  {
    path: "/datause",
    name: "Use",
    component: LandingPage_Use,
    meta: { title: "Term of Use | One Box" }
  },
  {
    path: "/dataetax",
    name: "etax",
    component: LandingPage_Etax,
    meta: { title: "Term of | One Box" }
  },
  {
    path: "/login-register",
    name: "login-register",
    component: OneBox_Login_register,
    meta: { title: "Login-Register | One Box" }
  },
  {
    path: "/login",
    name: "login",
    component: OneBox_Login,
    meta: { title: "Login | One Box" }
  },
  {
    path: "/loginbysso",
    name: "loginbysso",
    beforeEnter: loginbysso
  },
  {
    path: "/loginsms",
    name: "loginsms",
    beforeEnter: loginsms
  },
  {
    path: "/loginbysharetoken",
    name: "loginbysharetoken",
    component: Onebox_loginbysharetoken,
    meta: { title: "Login by Sharetoken | One Box" }
  },
  {
    path: "/loginbydomain",
    name: "loginbydomain",
    component: Onebox_loginbydomain,
    meta: { title: "Login by Domain | One Box" }
  },
  {
    path: "/loginbusinessbytoken",
    name: "loginbusinessbytoken",
    component: Onebox_loginbusinessbytoken,
    meta: { title: "Login Business by Token | One Box" }
  },
  {
    path: "/login_redirectinbox",
    name: "login_redirectinbox",
    component: OneBox_login_redirectinbox,
    meta: { title: "login_redirectinbox | One Box" }
  },
  {
    path: "/login_sharelink",
    name: "login_sharelink",
    component: Onebox_loginsharelink,
    meta: { title: "login sharelink | One Box" }
  },
  {
    path: "/login_sharelink_tokenunavailable",
    name: "login_sharelink_tokenunavailable",
    component: Onebox_loginsharelinktokenunavailable,
    meta: { title: "login sharelink | One Box" }
  },
  {
    path: "/redirectdirectory",
    name: "redirectdirectory",
    component: OneBox_redirectdirectory,
    meta: { title: "redirectdirectory | One Box" }
  },
  {
    path: "/login/bypass",
    name: "OneBox_login_sharedtoken",
    component: OneBox_login_sharedtoken,
    meta: { title: "login sharedtoken | One Box" }
  },
  {
    path: "/login/bypass-business",
    name: "OneBox_login_sharedtoken_ad",
    component: OneBox_login_sharedtoken_ad,
    meta: { title: "login sharedtoken | One Box" }
  },
  {
    path: "/login/bypass_taxbox",
    name: "OneBox_login_sharedtoken_taxbox",
    component: OneBox_login_sharedtoken_taxbox,
    meta: { title: "login sharedtoken | Tax Box" }
  },
  {
    path: "/login/access",
    name: "Onebox_redirectevaluate",
    component: Onebox_redirectevaluate,
    meta: { title: "login accesstoken | One Box" }
  },
  {
    path: "/login/redirectconnect",
    name: "Onebox_redirectfromgoogledrive",
    component: Onebox_redirectfromgoogledrive,
    meta: { title: "login accesstoken | One Box" }
  },
  {
    path: "/detail_request",
    name: "Onebox_redirectrequestdownload",
    component: Onebox_redirectrequestdownload,
    meta: { title: "Approve Download | One Box" }
  },
  {
    path: "/request_download",
    name: "Onebox_redirectdownloadfromrequest",
    component: Onebox_redirectdownloadfromrequest,
    meta: { title: "Download | One Box" }
  },
  {
    path: "/easy_login",
    name: "OneBox_easy_login",
    component: OneBox_easy_login,
    meta: { title: "OneBox easy login | One Box" }
  },
  {
    path: "/easy_loginwithtoken",
    name: "OneBox_loginwithtoken",
    component: OneBox_loginwithtoken,
    meta: { title: "OneBox login | One Box" }
  },
  {
    path: "/login/business_sharedtoken",
    name: "login_business_sharedtoken",
    component: OneBox_login_business_sharedtoken,
    meta: { title: "login business sharedtoken | One Box" }
  },
  {
    path: "/loginbusiness_by_sharetoken",
    name: "loginbusiness_by_sharetoken",
    component: OneBox_loginbusiness_by_sharetoken,
    meta: { title: "login business sharedtoken | One Box" }
  },
  {
    path: "/login/oneplatform",
    name: "login_oneplatform",
    component: OneBox_login_business_sharedtoken_oneplatfrom,
    meta: { title: "login business sharedtoken | One Platfrom" }
  },
  {
    path: "/login/default",
    name: "login_default",
    component: OneBox_login_default,
    meta: { title: "login | One Box" }
  },
  {
    path: "/direct_uploads",
    name: "directuploadfiles",
    component: OneBox_directuploadfiles,
    meta: { title: "Direct Upload | One Box" }
  },
  {
    path: "/sharelinkuploads",
    name: "sharelinkuploads",
    component: OneBox_sharelinkuploads,
    meta: { title: "Share Link Uploads | One Box" }
  },
  {
    path: "/register_mohpromt",
    name: "register_mohpromt",
    component: OneBox_register_mohpromt,
    meta: { title: "Register MohPromt | One Box" }
  },
  {
    path: "/register_mohpromt2",
    name: "register_mohpromt2",
    component: OneBox_register_mohpromt2,
    meta: { title: "Register MohPromt | One Box" }
  },
  {
    path: "/registerone",
    name: "register",
    component: OneBox_register,
    meta: { title: "Register | One Box" }
  },
  {
    path: "/registerbusiness",
    name: "registerbusiness",
    component: OneBox_registerbusiness,
    meta: { title: "Register Business ID | One Box" }
  },
  {
    path: "/register",
    name: "registercheck",
    component: OneBox_checkregister,
    meta: { title: "Register One | One Box" }
  },
  {
    path: "/mydrive",
    name: "myfiles",
    component: OneBox_myfiles,
    beforeEnter: ifAuthenticated,
    meta: { title: "My Drive | One Box" }
  },
  {
    path: "/drivebusiness/:id",
    name: "filesbusiness",
    component: OneBox_directory,
    beforeEnter: ifAuthenticated,
    meta: { title: "Drive Business | One Box" }
  },
  {
    path: "/starred",
    name: "starfiles",
    component: OneBox_starfiles,
    beforeEnter: ifAuthenticated,
    meta: { title: "Starred | One Box" }
  },
  {
    path: "/dashboardinbox",
    name: "dashboardinbox",
    component: OneBox_dashboardinbox,
    beforeEnter: ifAuthenticated,
    meta: { title: "Dashboard Inbox | One Box" }
  },
  {
    path: "/dashboardoutbox",
    name: "dashboardoutbox",
    component: OneBox_dashboardoutbox,
    beforeEnter: ifAuthenticated,
    meta: { title: "Dashboard Outbox | One Box" }
  },
  {
    path: "/library",
    name: "library",
    component: OneBox_library,
    beforeEnter: ifAuthenticated,
    meta: { title: "Library | One Box" }
  },
  {
    path: "/trash",
    name: "trash",
    component: OneBox_trashfiles,
    beforeEnter: ifAuthenticated,
    meta: { title: "Trash | One Box" }
  },
  {
    path: "/trashinbox",
    name: "trashinbox",
    component: Onebox_trashinbox,
    beforeEnter: ifAuthenticated,
    meta: { title: "Trash Inbox | One Box" }
  },
  {
    path: "/trashoutbox",
    name: "trashoutbox",
    component: Onebox_trashoutbox,
    beforeEnter: ifAuthenticated,
    meta: { title: "Trash Outbox | One Box" }
  },
  {
    path: "/fileexpiredinbox",
    name: "fileexpiredinbox",
    component: OneBox_File_expired_inbox,
    beforeEnter: ifAuthenticated,
    meta: { title: "File expired inbox | One Box" }
  },
  {
    path: "/fileexpiredoutbox",
    name: "fileexpiredoutbox",
    component: OneBox_File_expired_outbox,
    beforeEnter: ifAuthenticated,
    meta: { title: "File expired outbox | One Box" }
  },
  {
    path: "/fileexpired",
    name: "fileexpired",
    component: Onebox_fileexpired,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/directory/:id",
    name: "directory",
    component: OneBox_directory,
    beforeEnter: ifAuthenticated,
    // props: true,
    meta: { title: "Directory | One Box" }
  },
  {
    path: "/directorysharefile/:id",
    name: "directorysharefile",
    component: OneBox_directorysharefile,
    beforeEnter: ifAuthenticated,
    // props: true
  },
  {
    path: "/document-management",
    name: "document-management",
    component: OneBox_dcm,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter: logout
  },
  {
    path: "/logout_",
    name: "logout_",
    beforeEnter: logout_
  },
  {
    path: "/publicfile",
    name: "publicfile",
    component: OneBox_publicfile,
    meta: { title: "Link Share Files | One Box" }
  },
  {
    path: "/terminate-service",
    name: "terminate-service",
    component: OneBox_terminate,
    meta: { title: "Terminate Service | One Box" }
  },
  {
    path: "/publicfolder",
    name: "publicfolder",
    component: OneBox_publicfolder,
    meta: { title: "Link Share Folders | One Box" }
  },
  {
    path: "/public",
    name: "public",
    // beforeEnter: ifAuthenticatedSharelink,
    component: OneBox_public,
    meta: { title: "Link Share Files/Folders | One Box" }
  },
  //ใหม่
  {
    path: "/myinboxetax",
    name: "myinboxetax",
    beforeEnter: ifAuthenticated,
    component: OneBox_myinboxetax,
    meta: { title: "Inbox System | One Box" }
  },
  {
    path: "/myinbox-system-new",
    name: "myinbox-system-new",
    beforeEnter: ifAuthenticated,
    component: OneBox_inboxsystem_new,
    meta: { title: "Inbox System | One Box" }
  },
  {
    path: "/allinbox-new",
    name: "allbusiness_inbox_new",
    beforeEnter: ifAuthenticated,
    component: OneBox_allinbox,
    meta: { title: "All Business Inbox System | One Box" }
  },
  {
    path: "/allinbox-system-new",
    name: "allinbox_system_new",
    beforeEnter: ifAuthenticated,
    component: OneBox_allinboxsystem,
    meta: { title: "All Business Inbox SourceSystem | One Box" }
  },
  {
    path: "/myoutboxetax",
    name: "myoutboxetax",
    beforeEnter: ifAuthenticated,
    component: OneBox_myoutboxetax,
    meta: { title: "Outbox System | One Box" }
  },
  {
    path: "/myoutbox-system-new",
    name: "myoutbox_system-new",
    beforeEnter: ifAuthenticated,
    component: OneBox_outboxsystem_new,
    meta: { title: "Outnbox System | One Box" }
  },
  {
    path: "/alloutbox-new",
    name: "allbusiness_outbox_new",
    beforeEnter: ifAuthenticated,
    component: OneBox_alloutbox,
    meta: { title: "All Business Outnbox System | One Box" }
  },
  {
    path: "/alloutbox-system-new",
    name: "alloutbox_system_new",
    beforeEnter: ifAuthenticated,
    component: OneBox_alloutboxsystem,
    meta: { title: "All Business Outbox SourceSystem | One Box" }
  },
  //เก่า
  {
    path: "/myinbox-system-citizen",
    name: "myinbox-system-citizen",
    beforeEnter: ifAuthenticated,
    component: OneBox_inboxsystem_citizen,
    meta: { title: "Inbox System | One Box" }
  },
  {
    path: "/myinbox-system",
    name: "myinbox-system",
    beforeEnter: ifAuthenticated,
    component: OneBox_inboxsystem,
    meta: { title: "Inbox System | One Box" }
  },
  {
    path: "/myinbox-sourcesystem",
    name: "myinbox_sourcesystem",
    beforeEnter: ifAuthenticated,
    component: OneBox_inboxsourcesystem,
    meta: { title: "Inbox SourceSystem | One Box" }
  },
  {
    path: "/myoutbox-system",
    name: "myoutbox_system",
    beforeEnter: ifAuthenticated,
    component: OneBox_outboxsystem,
    meta: { title: "Outbox System | One Box" }
  },
  {
    path: "/myoutbox-sourcesystem",
    name: "myoutbox_sourcesystem",
    beforeEnter: ifAuthenticated,
    component: OneBox_outboxsourcesystem,
    meta: { title: "Outbox SourceSystem | One Box" }
  },
  {
    path: "/allbusinessinbox",
    name: "allbusiness_inbox",
    beforeEnter: ifAuthenticated,
    component: OneBox_allbusinessinbox,
    meta: { title: "All Business Inbox System | One Box" }
  },
  {
    path: "/allbusinessinbox-system",
    name: "allinbox_system",
    beforeEnter: ifAuthenticated,
    component: OneBox_allbusinessinboxsystem,
    meta: { title: "All Business Inbox SourceSystem | One Box" }
  },
  {
    path: "/allbusinessoutbox",
    name: "allbusiness_outbox",
    beforeEnter: ifAuthenticated,
    component: OneBox_allbusinessoutbox,
    meta: { title: "All Business Outbox System | One Box" }
  }, 
  {
    path: "/allbusinessoutbox-system",
    name: "alloutbox_system",
    beforeEnter: ifAuthenticated,
    component: OneBox_allbusinessoutboxsystem,
    meta: { title: "All Business Outbox SourceSystem | One Box" }
  },
  {
    path: "/sharedwithme",
    name: "sharedwithme",
    component: OneBox_sharedwithme,
    beforeEnter: ifAuthenticated,
    meta: { title: "Share With Me | One Box" }
  },
  {
    path: "/onebox-system-admin-managelogo",
    // path: "/system-admin-managefile",
    name: "system-admin-managefile",
    component: OneBox_adminmanagelogo,
    beforeEnter: ifAuthenticatedAdmin,
    meta: {
      title: "(Administrator) Business Permission Files&Folders | One Box"
    }
  },
  {
    path: "/onebox-system-admin-managefile",
    // path: "/system-admin-managefile",
    name: "system-admin-managefile",
    component: OneBox_adminmanagefile,
    beforeEnter: ifAuthenticatedAdmin,
    meta: {
      title: "(Administrator) Business Permission Files&Folders | One Box"
    }
  },
  {
    path: "/onebox-system-admin-index",
    // path: "/system-admin-index",
    name: "system-admin-index",
    component: OneBox_adminindex,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business | One Box" }
  },
  {
    path: "/onebox-system-admin-generatekey",
    // path: "/system-admin-generatekey",
    name: "system-admin-generatekey",
    component: OneBox_admingeneratekey,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business | One Box" }
  },
  {
    path: "/onebox-system-admin-consent",
    // path: "/system-admin-consent",
    name: "system-admin-consent",
    component: OneBox_adminconsent,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business | One Box" }
  },
  {
    path: "/onebox-system-admin-recheckimap",
    // path: "/system-admin-recheckimap",
    name: "system-admin-recheckimap",
    component: OneBox_adminrecheckimap,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business Recheck IMAP | One Box" }
  },
  {
    path: "/onebox-system-admin-fileandfolderbackup",
    // path: "/system-admin-fileandfolderbackup",
    name: "system-admin-fileandfolderbackup",
    component: OneBox_adminfileandfolderbackup,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business File & Folder Backup | One Box" }
  },
  {
    path: "/onebox-system-admin-template",
    // path: "/system-admin-fileandfolderbackup",
    name: "system-admin-template",
    component: OneBox_admintemplate,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Create template | One Box" }
  },
  {
    path: "/onebox-system-admin-dashboard",
    // path: "/system-admin-dashboard",
    name: "system-admin-dashboard",
    component: OneBox_admindashboard,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business Dashboard | One Box" }
  },
  {
    path: "/onebox-system-admin-setting",
    // path: "/system-admin-setting",
    name: "system-admin-setting",
    component: OneBox_adminsetting,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business Setting Admin | One Box" }
  },
  {
    path: "/onebox-system-admin-managestorage",
    // path: "/system-admin-managestorage",
    name: "system-admin-managestorage",
    component: OneBox_adminmanagestorage,
    beforeEnter: ifAuthenticatedAdmin
  },
  {
    path: "/managestorageonebox",
    name: "managestorageonebox",
    component: Onebox_managestorageonebox,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/managefunction",
    name: "managefunction",
    component: Onebox_managefunction,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/onebox-system-admin-manageinbox",
    // path: "/system-admin-manageinbox",
    name: "system-admin-manageinbox",
    component: OneBox_adminmanageinbox,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business Permission Inbox | One Box" }
  },
  {
    path: "/onebox-system-admin-manageoutbox",
    // path: "/system-admin-manageoutbox",
    name: "system-admin-manageoutbox",
    component: OneBox_adminmanageoutbox,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business Permission Outbox | One Box" }
  },
  {
    path: "/onebox-system-admin-managefeature",
    // path: "/system-admin-managefeature",
    name: "system-admin-managefeature",
    component: OneBox_adminmanagefeature,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business Manage Features | One Box" }
  },
  {
    path: "/onebox-system-admin-setemail",
    // path: "/system-admin-setemail",
    name: "system-admin-setemail",
    component: OneBox_adminsetemail,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business Set Email | One Box" }
  },
  {
    path: "/onebox-system-admin-managedirectoryfile/:id",
    // path: "/system-admin-managedirectoryfile/:id",
    name: "system-admin-managedirectoryfile",
    component: OneBox_adminmanagedirectoryfile,
    beforeEnter: ifAuthenticatedAdmin,
    // props: true
  },
  {
    path: "/onebox-system-admin-log",
    // path: "/system-admin-log",
    name: "system-admin-log",
    component: OneBox_adminlog,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business Usage Logs | One Box" }
  },
  {
    path: "/onebox-system-personal-log",
    // path: "/system-personal-log",
    name: "system-personal-log",
    component: OneBox_personallog,
    beforeEnter: ifAuthenticated,
    meta: { title: "Personal Usage Logs | One Box" }
  },
  {
    path: "/onebox-system-admin-mydrive",
    // path: "/system-admin-mydrive",
    name: "system-admin-mydrive",
    component: OneBox_adminmydrive,
    beforeEnter: ifAuthenticatedAdmin,
    meta: { title: "(Administrator) Business Usage Logs | One Box" }
  },

  {
    path: "/cctv",
    name: "cctv",
    beforeEnter: ifAuthenticated,
    component: Onebox_cctv
  },
  {
    path: "/consent",
    name: "consent",
    beforeEnter: ifAuthenticated,
    component: Onebox_consent
  },
  {
    path: "/package",
    name: "package",
    beforeEnter: ifAuthenticated,
    component: Onebox_Package
  },
  {
    path: "/packagenew",
    name: "packagenew",
    beforeEnter: ifAuthenticated,
    component: Onebox_Package_new
  },
  {
    path: "/report",
    name: "report-problem",
    beforeEnter: ifAuthenticated,
    component: Onebox_Report
  },
  {
    path: "/packagecurrent",
    name: "packagecurrent",
    beforeEnter: ifAuthenticated,
    component: Onebox_packagecurrent
  },
  {
    path: "/packagehistory",
    name: "packagehistory",
    beforeEnter: ifAuthenticated,
    component: Onebox_packagehistory
  },
  {
    path: "/packagelistpay",
    name: "packagelistpay",
    beforeEnter: ifAuthenticated,
    component: Onebox_packagelistpay
  },
  {
    path: "/mypackage",
    name: "mypackage",
    beforeEnter: ifAuthenticated,
    component: Onebox_MyPackage
  },
  {
    path: "/payment",
    name: "payment",
    beforeEnter: ifAuthenticated,
    component: Onebox_Payment
  },
  {
    path: "/loading",
    name: "loading",
    beforeEnter: ifAuthenticated,
    component: Onebox_loading
  },
  {
    path: "/preview",
    name: "preview",
    beforeEnter: ifAuthenticated,
    component: Onebox_preview,
    meta: { title: "Preview | One Box " }
  },
  {
    path: "/datapolicy",
    name: "datapolicy",
    component: Onebox_policy,
    meta: { title: "Data Policy | One Box " }
  },
  {
    path: "/business",
    name: "business",
    component: OneBox_business,
    meta: { root: "business" },
    children: [
      {
        path: "login",
        name: "businese_login",
        component: OneBox_business_login,
        meta: { title: "Login | One Box Business" }
      },
      { path: "logout", name: "businese_logout", beforeEnter: logoutbusiness },
      {
        path: "allinbox",
        name: "businese_allinbox",
        component: OneBox_business_allinbox,
        beforeEnter: ifAuthenticatedBusiness,
        meta: { title: "All Business Inbox | One Box Business" }
      },
      {
        path: "alloutbox",
        name: "businese_alloutbox",
        component: OneBox_business_alloutbox,
        beforeEnter: ifAuthenticatedBusiness,
        meta: { title: "All Business Outbox | One Box Business" }
      },
      {
        path: "allinbox-system",
        name: "businese_allinboxsystem",
        component: OneBox_business_allinboxsystem,
        beforeEnter: ifAuthenticatedBusiness,
        meta: { title: "All Business Inbox System | One Box Business" }
      },
      {
        path: "alloutbox-system",
        name: "businese_alloutsystem",
        component: OneBox_business_alloutboxsystem,
        beforeEnter: ifAuthenticatedBusiness,
        meta: { title: "All Business Outbox System | One Box Business" }
      },
      {
        path: "allinbox-sourcesystem",
        name: "businese_allinboxsourcesystem",
        component: OneBox_business_allinboxsourcesystem,
        beforeEnter: ifAuthenticatedBusiness,
        meta: { title: "All Business Inbox SourceSystem | One Box Business" }
      },
      {
        path: "alloutbox-sourcesystem",
        name: "businese_alloutsourcesystem",
        component: OneBox_business_alloutboxsourcesystem,
        beforeEnter: ifAuthenticatedBusiness,
        meta: { title: "All Business Outbox SourceSystem | One Box Business" }
      }
    ]
  }
];

const Router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  duplicateNavigationPolicy: "ignore",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes
});

Router.beforeEach((to, from, next) => {
  if (
    localStorage.getItem("lang") &&
    localStorage.getItem("lang") !== i18n.locale
  ) {
    i18n.locale = localStorage.getItem("lang");
  } else if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", "th");
    i18n.locale = localStorage.getItem("lang");
  }

  if (store.state.authorize === false && window.location.pathname !== "/" &&
    window.location.pathname !== "/login-register" &&
    window.location.pathname !== "/registerone" &&
    window.location.pathname !== "/register" &&
    window.location.pathname !== "/registerbusiness" &&
    window.location.pathname !== "/datause" &&
    window.location.pathname !== "/dataprotection" &&
    window.location.pathname !== "/registerbusiness" &&
    window.location.pathname !== "/dataetax") {
    store.commit('setLoading', true)
  }
  next();
});

const DEFAULT_TITLE = "One Box Storage - Collaboration of One Platform";

Router.afterEach(to => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });

  store.commit('setLoading', false)
});

export default Router;
