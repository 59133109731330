import { colors } from "vuetify/lib";

export default {
  tomanyreqotp:"Too many request for OTP",
  text2fa:"Two-Factor Authentication is enabled",
  syssendotp:"The system will send an OTP to the phone number :",
  verify_OTP:"Verify OTP",
  idcard:"ID card number",
  loginSMS: "Login with SMS",
  phoneNumber: "Mobile number",
  requiredPhoneNumber: "Please your mobile phone number",
  otp: "Swipe right to get OTP",
  verifyOTP: "verify OTP",
  enterOTP: "Enter OTP",
  countDown: "Time Remaining : ",
  resendOTP: "Resend OTP",
  confirmOTP: "Confirm OTP",
  DateDefault: "Date Default",
  namebusiness: "Business Name",
  CitizenProfile: "Citizen Profile",
  BusinessProfile: "Business Profile",
  dowloads: "Download",
  account_id: "Account id",
  user_id: "User id",
  Numberofdaystodelete: "Number of days to delete",
  days: "Days",
  TaxID: "TaxID",
  name: "Name",
  surname: "Surname",
  emailthai: "One Email",
  email: "Email",
  company: "Company",
  dataStorageUsage: "Data Storage Usage",
  dataStorageMax: "Data Storage Max",
  Personal: "Profile",
  consentheader: "Consent",
  usernameprofile: "Username",
  Youwanttodelete: "You want to Delete",
  memoryinformation: "Storage",
  namecompany: "Company-name",
  namecompanyeng: "Company-name-Eng",
  default: "en",
  login: "Login",
  quicksearch: "Quick search",
  hashtag_tooltip: "For user want to search for files or folders that have 2 or more hashtags.",
  more_infor_hashtag: "Press to see more",
  hashtag_manual_header: "For cases where you want to search for files or folders from 2 or more hashtags.",
  hashtag_manual_header_mobile: "Search for files or folders from 2 or more hashtags.",
  hashtag_manual_body_1: "For cases where you want to search for files or folders from 2 or more hashtags. You can enter special characters in your search by using Comma ( , ) and Pipe ( | ) in your search.",
  hashtag_manual_body_2: "Comma ( , ) Used for searching files or folders that must contain all specified hashtags. For example: A,B means that search for files or folders that contain hashtags A and B.(Used to narrow down the search details.)",
  hashtag_manual_body_3: "Pipe ( | ) Used for searching for files or folders from search either one of hashtags. For example: A|B means that search for files or folders that contain hashtags A or B. (Used for cases where you are not sure which hashtag is set to the file/folder you want to search.)",
  username: "Username",
  password: "Password",
  forgotpassword: "Forgot password",
  createaccount1: "Don't have an account",
  createaccount2: "Sign up with ONE-ID",
  new: "Uploads / Create",
  logout: "Logout",
  profile: "Profile",
  Personalinformation: "Personal Information",
  forbusiness: "For Business",
  businessaccount: "Business Account",
  folders: "Folders",
  files: "Files",
  folder: "Folder",
  file: "File",
  recentfile: "Recent Files",
  company: "Company",
  colorpiker: "Setting Theme",
  otp_botonebox: {
    notelephone:"This user doesn't have mobile no for send OTP",
    sendagain:"Send OTP Again",
    setphonenumber:"Setting Telephone Number",
    minutes: 'Minutes',
    send_otp_btn: "Send OTP",
    cancel_otp_btn: "Cancel",
    resend_otp_btn: "Resend OTP",
    continue_otp_btn: "Continue",
    logout_otp_btn: "Logout",
    verify_otp: "Verify OTP",
    username_oid: "Username/Email of OneID",
    reason_hint: "Reason for accessing this account ",
    enter_otp: "Enter OTP ChatBot ",
    require_username: "username required",
    validator_username: "Please fill your OneID",
    validator_reason: "Please fill your reason",
    validator_reason_charactors: "Please fill more than 5 characters", 
    validator_reason_special: "Must not have some special characters" ,
    not_found_user: "Not found account on OneId", 
    account_not_add: "This account does not add friend with chatbot", // Invalid OTP provided
    invalid_otp: "Invalid OTP provided",
    fill_otp: "Please fill OTP received",
    sessionwill: "Session will expire",
    sessiontimeout: "Session expired",
    your_session_timeout_in: "Your session will expire in ",
    your_session_timeout: "Your session has expired",
    otp_detail: "Resend new OTP for continuous using. OTP will send to account "
  }, 
  logo_feature: {
    manage_logo: "Manage Logo",
    dragupload: "Drag & Drop Upload",
    detail_upload: "Your file here or browse to upload",
    file_detail: "Only .JPEG, .PNG, .SVG and .ICO files with max size ",
    file_detail_size: "not more than 4 MB. ",
    set_default: "Set as default",
    save_change: "Save Changes",
    clear_: "Clear",
    consent_header_change: "Confirm to save changes",
    default_detail: "Confirm to set as OneBox default logo image.", //
    change_detail: "Confirm to save changes to the OneBox logo image.",
    default_consent: "Confirm",
    change_consent: "Confirm",
    save_complete: "Complete",
    dark_mode: "Dark Mode",
    light_mode: "Light Mode",
  },
  toast: {
    multiset: {
      star: "Starred successfully",
      remove: "Removed successfully",
      cancle: "Unstarred successfully"
    },
    text: {
      starred: "Star ",
      success: " successfully",
      canclestart: "Unstarred ",
      cannothide: "Can't hide ",
      cannotunhide: "Can't unhide ",
      cannotcancelshared: "Can't Cancel Shared ",
      remove: "Remove ",
      totrash: " to trash",
      cannotstar: "Can't star ",
      textth: "",
      restore: "Restore",
      hide: "Hide",
      unhide: "Unhide",
      cancelshared: "Cancel Shared",
      multiremovetotrash: "Remove all file to trash",
      ocr: "OCR file created successfully",
    },
    cannotconnectonebox: "Unable to connect to ONE-BOX at this time.",
    cannotopenfile: "Unable to retrieve the file at this time.",
    cannotdownload: "Unable to download ",
    nopermissionfile: "Some files you don't have permission to access",
    confirmsetadmin:"Confirm the administrator settings.",
    textsetadmin_1:"Do you want to set",
    textsetadmin_2:"as the administrator?",
    textsetadmin_3:"Do you want to cancel setting",
    expired_token:"Session expired, Please login again",
    otpMismatch: "OTP Code Mismatch",
    mobileNumberIncorrect: "Mobile number incorrect",
    tooManyRequests: "Too many requests, Please try again later",
    consentSuccess: "Consent recorded successfully",
    folderPathInvalid: "The folder path is invalid",
    isSourceFileFolder: "You are already in the source of this file/folder",
  },
  toolbar: {
    googledrive:"Migrate frome google drive",
    mapdrive:"Map Drive",
    cctv: "Renew or change CCTV package",
    dcm: "Document Management",
    specialupload: "Smart Input",
    mydrive: "My Drive",
    mydrive_bu: "Drive Business",
    mystarfile: "Favourites",
    mysharefile: "Share With Me",
    mytrash: "Trash",
    library: "Library",
    storage: "Storage",
    use: "Use",
    from: "out of",
    searching: {
      name: "Advance Search",
      aspect_name: "Aspect Search",
      button: "Search",
      detail: "Keyword",
      searchfromdate: "Upload date",
      searchtodate: "To",
      keywordsearch: "Keyword Search",
      fulltextsearch: "Fulltext Search",
      optional_fulltextearch: "Optional (Full-text Search)",
      optional_folder_name: "Folder",
      optional_name_fulltextearch: "File Name",
      optional_content_fulltextearch: "Content",
      optional_hashtag_fulltextearch: "Hashtag",
      searchby: "Search by",
      fileinfolder:'Search in this folder',
      startenddate: "Start - End date",
      startdate:"Start date",
      enddate:"End date",
      smartuploadsearch: "Smart Upload Search",
    },
    searchCompanyTaxid: "Search by company name or tax id",
    file: "File",
    dashboardinbox: {
      name: "Dash Board Inbox",
      companyname: "Company Name",
      totalinbox: "Total number of inbox",
      totalfilesize: "Total file size of inbox",
      inbox_last6months: "Total number of inbox in the last 6 months",
      filesize_last6months: "Total file size of inbox in the last 6 months",
      inboxpermonth: "Number of inbox per month (last 6 months)",
      listcompany: "List of all companies that send inbox",
      numberofinbox: "Number of inbox",
    },
    dashboardoutbox: {
      name: "Dash Board Outbox",
      companyname: "Company Name",
      totaloutbox: "Total number of outbox",
      totalfilesize: "Total file size of outbox",
      outbox_last6months: "Total number of outbox in the last 6 months",
      filesize_last6months: "Total file size of outbox in the last 6 months",
      outboxpermonth: "Number of outbox per month (last 6 months)",
      listcompany: "List of all companies that send outbox",
      numberofoutbox: "Number of outbox",
    },
    myinbox: {
      Parentname: "ETAX - My Inbox",
      Personalname: "Personal Inbox",
      Businessname: "Business Inbox",
      Trashinbox: "Trash Inbox"
    },
    myoutbox: {
      Parentname: "ETAX - My Outbox",
      Personalname: "Personal Outbox",
      Businessname: "Business Outbox",
      Trashoutbox: "Trash Outbox"
    },
    allbusinessinbox: "All Inbox",
    allbusinessoutbox: "All Outbox",
    allbusinessinbox_: "All Inbox (Seller)",
    allbusinessoutbox_: "All Outbox (Buyer)",
    morecolor: "More colors...",
    expiredfile: "File expired",
    report_problem: "Report Problem",
    onebox_token: {
      header: "Onebox Token",
      copy: "Copy",
      close: "Close",
      token_timeout: "Token timeout",
      token_time_using: "Token can use only 5 minutes"

    }
  },
  mainRClick: {
    uploadfile: "Upload Files",
    newfolder: "Create Folder",
    uploadfolder: "Upload Folder",
    createfile: "Create File",
    createfile_complete: "Create File Complete",
    createfile_failed: "Create File Failed",
    open_file_when_complete : "Open file when file create success.",
    open_folder_when_complete : "Open folder when folder create success."
  },
  currentRClick: {
    printPDF:'Print',
    setpassword:'Set password',
    editpassword:"Edit password",
    forgetpassword:"Forget password",
    deletepassword:"Delete password",
    cancelexpired: "Cancel setting expiration date",
    editexpired: "Edit expiration date",
    setexpired: "Set expiration date",
    open: "Open",
    opennewtab: "Open Link in New Tab",
    showinfolder: "Show in folder",
    deleterelationfile: "Delete relation",
    managefile: "Set permission",
    relationfile: "Relation",
    createrelationfile: "Create Relation",
    alldelete: "All Remove",
    alldownload: "All Download",
    sharelinkfile: "Get shareable Link",
    sharelinkupload: "Share Link for Upload",
    sharefile: "Share",
    rename: "Rename",
    editfile: "Edit and lock File",
    unlockfile: "Unlock File",
    movetotrash: "Move to Trash",
    movefile: "Move to",
    addtostared: "Add to starred",
    downloadfile: "Download file",
    sendfiletomail: "Send file to e-mail",
    sendfiletoonechat: "Send file to OneChat",
    removestarred: "Remove from starred",
    preview: "Preview",
    previewnewtab: "Preview Link in New Tab",
    newpreview: "NewPreview",
    keydetail: "Details",
    tagversion: "Tag Version",
    sendfilemenu: "Send to",
    sharefilemenu: "Share",
    pwdmenu: "Password",
    editmenu:'Edit',
    readfile: "Read File",
    editfileonline: "Edit File Online",
    editting: "Editing",
    setprofile:"Setting picture",
    setrequestdownload:"Set Request Download",
    requestdownload:"Request to download",
  },
  trashRClick: {
    detail: "Details",
    restore: "Restore",
    forcedelete: "Permanently Delete"
  },
  tagVersion: {
    manageversion: "Manage Version",
    viewversion: "View Version",
    addversion: "Add Version",
    remark: "Please input details",
    updatebtn: "Create Version",
    closebtn: "Close",
    activebtn: "Active",
    deletebtn: "Delete",
    selectfile: "Select File: ",
    addfiletitle: "Add file to ",
    submitbtn: "Submit",
    uploadbtn: "Upload File",
    addbtn: "Add File form Onebox",
    filelabel: "Upload File",
    nofolder: "No Folder",
    nofile: "No File",
    detail: "Datails",
    startuploadorselectpdf: "Can only upload or select",
    enduploadorselectpdf: "files.",
    download: "Download"
  },

  sharelinkfile: {
    messageboxheader: "Share link file",
    statusYes: "Link share on",
    statusNo: "Link share off",
    title: "Anyone with the link can view",
    copy: "Copy",
    qrcodeOn: "QR Code On",
    qrcodeOff: "QR Code Off",
    saveqrcode: "Save QR Code",
    setting: "setting",
    cannotshare: "Can't share link file",
    limitheader:"Set number of opening a link",
    numberopen_1:"The link has been opened",
    numberopen_2:"times.",
    reset:"reset",
    resetword:"Reset the number of times it has been opened.",
    limitset:"set number of opening",
    permissionheader:"Set permission of sharing a link",
    timeheader:"Set the expiration time for sharing link",
    date_set:"date",
    hours_set:"hours",
    minute_set:"minutes",
    passwordheader:"Set password of sharing link",
    setting:"Setting",
    copylinksucces: "Link copied successful",
    saveqrsucces: "Save QR code success",
    emptypassword: "Please input a link password",
    // settingsharelinkfilesucces: "Link sharing set up successful",
    settingsharelinkfilesucces: "Link sharing set up and copy link successful",
    opensharelinkfilesucces: "Link share on and copy link successful",
    closesharelinkfilesucces: "Link share off successful",
    sharelinkfileerror: "Failed to share link file",
    emptylimitopenfile: "Please input number of limit open link",
    passwordleast: "Please input password at least 6 characters ",
    choosedate: "Please choose expire link date",
    resetlimitopenfileerror: "Open link number of times cannot be reset",
    sharelinkfilemanyerror: "Please fill out completely",
    shareby: "Shared by",
    setsharelinkfile: "Option",
    entersetplease: "**Please setting for copy the link",
    statusaccess: "Status",
    showqrcode: "Show QR Code",
    setwatermark: "Setting Watermark",
    watermarlusefor: "For file extension pdf, png, jpeg, jpg, tiff only",
    anyonewithalink: "Anyone with a link",
    limit: "Limit",
    pleasefillemail: "Please enter email",
    emailformatinvalid: "Email format invalid",
    filename: "File name",
    foldername: "Folder name",
    type: "Watermark Format",
    example_water: "Example",
    feature_watermark: "A special feature can customize the watermark format.",
    type_ll: "-45 Degree",
    type_tir: "45 Degree",
    type_ctr: "Center (Repeat)",
    type_tl: "Top Left",
    type_tr: "Top Right",
    type_cl: "Center Left",
    type_cr: "Center Right",
    type_bl: "Bottom Left",
    type_br: "Bottom Right",
    type_ct: "Center",
  },
  sharelinkupload: {
    messageboxheader: "Share link for Uploads",
    statusYes: "Link share on",
    statusNo: "Link share off",
    title: "Anyone with the link can view",
    copy: "Copy",
    qrcodeOn: "QR Code On",
    qrcodeOff: "QR Code Off",
    saveqrcode: "Save QR Code",
    setting: "setting",
    cannotshare: "Can't share link for uploads",
    numberopen_1:"The link has been opened",
    numberopen_2:"times.",
    timeheader:"Set the expiration time for sharing link",
    date_set:"date",
    hours_set:"hours",
    minute_set:"minutes",
    copylinksucces: "Link copied successful",
    saveqrsucces: "Save QR code success",
    settingsharelinkfilesucces: "Link sharing set up successful",
    sharelinkfileerror: "Failed to share link",
    choosedate: "Please choose expire link date",
    sharelinkfilemanyerror: "Please fill out completely",
    shareby: "Shared by",
    setsharelinkfile: "Option",
    entersetplease: "**Please setting for copy the link",
    statusaccess: "Status",
    showqrcode: "Show QR Code",
    addremark: "Add remark",
    remark: "Remark",
    notshared: "Not Shared",
  },
  sharefile: {
    messageboxheader: "Share with other Onebox users",
    oksharefile: "Update Status",
    edit: "Edit",
    view: "View",
    download:"Download",
    upload:"Upload",
    delete:"Delete",
    Email: "Email Address",
    Status: "Status",
    sharewith: "Share With :",
    share: "Share",
    sharefoldersuccess: "ShareFolderSuccess",
    sharefilesuccess: "ShareFileSuccess",
    editsharefile: "EditShareFile",
    pleasefill_email: "Please enter email",
    pleasefill_username: "Please enter username",
    pleasefill_onecollab: "Please enter one collab mail",
    pleasefill_type: "Please select type",
    emptytaxid: "Please enter tax id",
    cannotshare: "This user cannot be shared",
    cannotemail: "Cannot use the email repeatedly",
    deletesuc: "Delete Success",
    cannotsharefile: "Cannot share file for yourself",
    cannotsharefolder: "Cannot share folder for yourself",
    taxidbusiness: "Tax ID (Business)",
    settime: "Set time to share",
    closedatepiker: "Close",
    setpermissionsharefile: "Set permissions",
    setdate:"Date",
    sethours:" Hr",
    settime:"Time",
    setminute:"Min",
    submitsharefile: "Confirm cancel sharing ",
    with: " with ",
    notidelete1: "Do you want cancel sharing ",
    notidelete2: " ?",
    business: "Business",
    notfoundtaxid: "Can't find business from this tax id",
    notfoundemail: "This email not found",
    file: "file",
    folder: "folder",
    personal:"Personal",
    type:"Type",
    name:"Name",
    share_detail:"Share detail",
    file_name:"Name (File/Folder)",
    business_name:"Business Name",
    sharing_not_found:"Sharing information not found",
    save:"Save",
    cancel_sharing:"Cancel Sharing",
    no_date_set:"No date set"
  },

  trashPage: {
    messageboxheader: "Permanently Delete",
    messageboxsubheader: " 1 file or folder is about to be permanently deleted",
    messageboxsubheader2: " Warning: If you selected delete, the document can't be recovered",
    forcedelete: "Permanently Delete",
    canceldelete: "Cancel",
    cleartrash: "Clear Trash",
    cleartrashfilefolder: "Clear the files/folders in trash ?",
    confrimmuti: "File/folder is selected will be permanently delete.",
    confrimdeleteall1: "All files/folders will be permanently deleted.",
    confrimdeleteall2: "Note: The system will back up for 7 days after deleting files/folders.",
    preparedelete: "Preparing to delete files/folders",
    cancel_delete: "Cancel delete",
    deletesuccess: "Delete files/folders success",
    deletingfilefolder: "Deleting files/folders",
    nofileandfolderintrash: "Not File/Folder in trash",
    detaildelete: "Detail delete File/Folder",
    cannotdelete: "Can't delete File/Folder",
  },

  myinboxPage: {
    include_duplicate_files:"Include duplicate files",
    summary_item:"Summary Item (1 file 1 row)",
    search_attachments:"Search Attachments",
    restore_filter:"Restore From Filter",
    delete_filter:"Delete From Filter",
    search_file:"Number of documents discovered :",
    yyyy_mm_dd:"ํYYYY-MM-DD",
    clear_data:"Clear",
    loading:"Loading",
    company:"Company",
    Filedetails: "File Details",
    Relateddocument: "Related Documents",
    create_relation:"Create Relation",
    export: "All Download files",
    exportcsv: "All Download files(CSV)",
    exportexcel: "All Download files(Excel)",
    storageisfull: "Storage is full",
    items: "Items",
    search_:" Find",
    search: "Search",
    Nodata: "No information found",
    consent: "Consent",
    openConsent: "Open",
    stipulation: "Stipulation",
    contactadmin: "Please contact the admin to approve the consent ",
    contactadmin1:"for receive document inbox",
    contactadmin2: "(Admin Business Only)",
    contactadmin__: "Please contact the admin for the inbox documents",
    accept: "Accept",
    reject: "Reject",
    HeaderSourceSystem: {
      systemname: "System Name"
    },
    typecompany: "Companies",
    typefiles: "Files list",
    multipledownload: "Multiple Download",
    msg: {
      nofile: "Please Choose Files !!!",
      apierror: "Cannot Download File !!!",
      pleasechoosepdf: "Please choose PDF file",
      norenamedocument: "Unable to rename this file.",
    },
    dowload: {
      dowloading: "Dowloading ",
      dowloadsuccess:"Dowload Success",
      dowloading_print: "Merging file",
      dowloadingsuccess_print: "Merging Success",
      downloadpreparing: "Preparing download, Please weit...",
    },
    conditionFile: "Search Document Condition",
    conditionOptional: {
      ponumber: "PO Number",
      customerid: "Customer ID",
      filename: "File Name",
      fileextension: "File Extension",
      filedocumentid: "Document Id",
      doctype: "Document Type",
      taxrefund: "Tax refund",
      taxabb: "Tax Invoice Status",
      sendername: "Sender Name",
      sendername2: "Seller name",
      sendername_: "Seller name",
      receivername: "Receiver Name",
      receivername2: "Buyer name",
      receivername_: "Buyer name",
      systemname: "System Name",
      sellertaxid: "Seller Tax ID",
      sellertaxid2: "Seller Tax ID",
      sellerbranchid: "Seller Branch ID",
      buyertaxid: "Buyer Tax ID",
      buyertaxid2: "Buyer Tax ID",
      buyerbranchid: "Buyer Branch ID",
      sellertaxid_: "Seller Tax ID",
      sellerbranchid_: "Seller Branch ID",
      buyertaxid_: "Buyer Tax ID",
      buyerbranchid_: "Buyer Branch ID",
      fromdateupload: "Process date",
      rulefromdateupload: "Please input Fromdate (Receive)",
      todateupload: "To",
      ruletodateupload: "Please input Todate (Receive)",
      closefromdateupload: "Close",
      closetodateupload: "Close",
      fromdatedocument: "Fromdate (Document)",
      from_datedocument: "Fromdate",
      rulefromdatedocument: "Please input Fromdate (Document)",
      todatedocument: "Todate (Document)",
      to_datedocument: "Todate (Expired/warning date before expired)",
      ruletodatedocument: "Please input Todate (Document)",
      closefromdatedocument: "Close",
      closetodatedocument: "Close",
      chanel:"chanel",
      status:'Status'
    },
    settingHeader: {
      setting: "Setting Header",
      submit: "Submit"
    }
  },

  myoutboxPage: {
    include_duplicate_files:"Include duplicate files",
    summary_item:"Summary Item (1 file 1 row)",
    search_attachments:"Search Attachments",
    restore_filter:"Restore From Filter",
    delete_filter:"Delete From Filter",
    yyyy_mm_dd:"ํYYYY-MM-DD",
    clear_data:"Clear",
    export: "Export files",
    exportcsv: "All Download files(CSV)",
    exportexcel: "All Download files(Excel)",
    items: "Items",
    add_relation:"Add Relation",
    search: "Search",
    Nodata: "No information found",
    HeaderSourceSystem: {
      systemname: "System Name"
    },
    typecompany: "Companies",
    typefiles: "Files list",

    multipledownload: "Multiple Download",
    msg: {
      nofile: "Please Choose Files to Download !!! ",
      apierror: "Cannot Download File !!!",
      pleasechoosepdf: "Please choose PDF file", 
      nodocumentstodownload: "No documents to download",
      norenamedocument: "Unable to rename this file.",
    },
    conditionFile: "Search Document Condition",
    conditionOptional: {
      ponumber: "PO Number",
      customerid: "Customer ID",
      filename: "File Name",
      fileextension: "File Extension",
      filedocumentid: "Document Id",
      doctype: "Document Type",
      systemname: "System Name",
      sendername: "Sender Name",
      sendername_: "Seller name",
      receivername: "Receiver Name",
      receivername_: "Buyer name",
      systemname: "System Name",
      sellertaxid: "Seller Tax ID",
      sellerbranchid: "Seller Branch ID",
      buyertaxid: "Buyer Tax ID",
      buyerbranchid: "Buyer Branch ID",
      sellertaxid_: "Seller Tax ID",
      sellerbranchid_: "Seller Branch ID",
      buyertaxid_: "Buyer Tax ID",
      buyerbranchid_: "Buyer Branch ID",
      fromdateupload: "Fromdate (Send)",
      rulefromdateupload: "Please input Fromdate (Send)",
      todateupload: "Todate (Send)",
      ruletodateupload: "Please input Todate (Send)",
      closefromdateupload: "Close",
      closetodateupload: "Close",
      fromdatedocument: "Fromdate (Document)",
      rulefromdatedocument: "Please input Fromdate (Document)",
      todatedocument: "Todate (Document)",
      ruletodatedocument: "Please input Todate (Document)",
      namefolder: "Folder name",
      namecustomer: "Customer name",
      receivernameextension: "Receivername extension(Have Box/Haven't Box)",
      closefromdatedocument: "Close",
      closetodatedocument: "Close",
      chanel:"chanel",
      statussendonechat:"Status send to Onechat"
    },
    buttonAction: {
      relatedFile: "Related documents",
      fileDetail: "File details",
      download: "Download"
    }
  },
  mutideletedocinboxoutbox: {
    listofdoc: "List of document deleted",
    fileselect: "Document",
    permissiondenied: "You do not have permission to delete this document.",
    permissiongranted: "This document can be deleted.",
    deletedoc: "Deleting document",
    deletedocsuccess: "Delete success",
    itemsmutiinout: "items",
    itemmutiinout: "item",
    cannotdelete: "Can't delete document",
    canceldelete: "Cancel delete document"
  },
  dcmPage: {
    conditionFile: "Search document condition",
    conditionFile_: "Search document  conditions",
    searchfileattachments :"Search File Attachments",
    download_attachments: "Download File Attachments",
    please_attach_file:"Please attach the document file",
    error_searchfileattachments_1: 'The "Document Number" column was not found in the attachment',
    error_searchfileattachments_2: "Please attach the Excel file (.xlsx)",
    error_searchfileattachments_3: 'Please fill in the information in the "Document Number" column',
    conditionOptional: {
      fromdateupload: "Fromdate (Upload)",
      rulefromdateupload: "Please input fromdate (Upload)",
      todateupload: "Todate (Upload)",
      ruletodateupload: "Please input todate (Upload)",
      closefromdateupload: "Close",
      closetodateupload: "Close",
      fromdatedocument: "Fromdate (Document)",
      rulefromdatedocument: "Please input fromdate (Document)",
      todatedocument: "Todate (Document)",
      ruletodatedocument: "Please input todate (Document)",
      closefromdatedocument: "Close",
      closetodatedocument: "Close"
    }
  },

  pagemyfile: {},
  manual: {
    businessbox: "Business Box Register Guide",
    oneboxmanual: "One Box User Manual",
    etax: "Guide to Finding e-Tax Invoice Files",
    useonebox: "VDO how to use One Box",
    storage: "VDO Buying additional Storage manually",
    manualfail: "Can't used manual. Please contact the system administrator",
  },
  uploadfile: {
    name: "Upload File",
    buttonupload: "Upload file",
    buttonclose: "Close",
    donotshare: "You cannot upload files in the share with me",
    clicktoupload: "Drag and Drop file or <a class=' filepond--label-action' color='primary'>Click to  Upload</a>",
    maxsizeuploadtext: "***Can upload files up to 250 GB in size per file.***",
    upload_excel: "Upload Excel",
    list_of_employees:"list of employees",
    download_form: "Form Example Download",
    clicktouploadnew: "Drag and Drop file or Click to Upload !",
    uploadfail: "Upload Fail",
    loadingfile: "Loading",
    fileduplicate: "File duplicate",
    uploadsuccess: "Upload success",
    taptoretry: "Tap To Retry",
    uploadingfile: "Uploading",
    cancelingfile: "Canceling upload",
    reverterror: "Revert Fail",
    taptocancel: "Tap icon to cancel",
    taptoundo: "Tap To Undo",
    removeerror: "Remove Fail",
    success: "Success",
    fail: "Fail",
    duplicate: "Duplicate",
    file: "file",
    files: "files"
    
  },

  uploadfolder: {
    name: "Create Folder",
    buttonupload: "Create folder",
    textfoldername: "Folder name",
    buttonclose: "Close",
    donotshare: "You cannot create folder in the sharing with me",
    createsucess: "Create folder success",
    cannotcreate: "Can not create folder now",
    nameduplicate: "Name folder duplicata, please rename",
    nospecialcharacter: `A folder name cannot contain special charecter  < > : * ? \\ | / " '`,
    enternamefolder: "Please enter a folder name."

  },
  inputuploadfolder: {
    uploadfoldersuccess: "Upload success",
    cannotchoosefile: "Can't choose file while they are being uploaded",
    detailupload: "Detail",
    uploadfail: "Upload fail",
    uploaddup: "File/Folder duplicate",
  },
  errormessageforupload: {
    cannotcheckduplicate: "Can't check for duplicate files/folders",
    er001pleaseinputfiledup: "Please input file_duplicate ('create_copy' or 'create_tag_version')",
    er102sizemore5gb: "File Size More Than 5 GB",
    er403accountnotexist: "Account user does not exist",
    er403Cannotfindfile: "Can't find file",
    er403Cannotfindfolder: "Can't find folder",
    er403cannotuploadfiletype: "You cannot upload files of this type. Because the admin of your company has blocked it",
    er404authfail: "Authentication Failed",
    er404allowuploadfile: "You're not allow to uploads this file",
    er404allowuploadfolder: "You're not allow to uploads this folder",
    er405keymissing: "Key(s) missing (user_id, folder_id, file_duplicate, remark)",
    er500cannotfindfolderid: "Can't find this folder by id",
    er500cannotfindfileid: "Can't find this file by id",
    er500notfoundaccount: "Not found account",
    er500permissionhandlenotcorrect: "Permission handle is not correct, Please comeback again!",
    cannotupload: "Can't upload files/folders. Please contact the system administrator",
  },
  tablefile: {
    resendmail: "Resend mail",
    warning_expiration_time: "File expired notification date",
    expiredinbox: "expired inbox",
    filenameonly: "Name",
    filename: "Name (Folder/File)",
    filesize: "File size",
    location: "Location",
    fileposition: "File position",
    option: "Optional",
    owner: "Owner",
    version: "Version",
    date: "Date",
    docno: "Document no",
    docdate: "Document date",
    doctype: "Document type",
    sendername: "Sender",
    sendername_: "Seller name",
    inboxdate: "Receive date",
    taxrefund: "Tax refund",
    receivername: "Receiver",
    receivername_: "Buyer name",
    outboxdate: "Sender date",
    createdate: "Upload date",
    sharedate: "Share date",
    shareby: "Share by",
    download: "Download",
    trashdtm:"Date deleted",
    remaindays:"Number of Remain Day",
    restore: "Restore",
    lastmodified: "Last modified",
    empty: "This folder in empty",
    empty_resend:"file could not be found.",
    emptysearch:"The searched file / folder could not be found.",
    searchtext:"Search results",
    doc_id: "Document Id",
    date_effect: "Effective Date",
    tag_version: "Revised Plan",
    fileuse: "Storage",
    filetotalstorage: "Storage usage",
    expiration_time: "Expired Date",
    permission: "Permission",
    status:"Status",
    status_onechat: "Statussend Onechat",
    ogposition:"Location",
    lastupdatename:"Deleted By",
    po_number: "PO Number",
    count: "File",
    loadingitems: "Loading items..."
  },

  allbusinessPage: {
    tablebusinesslist: {
      business: "Business",
      record: "Record"
    },
    inbox: {
      title: "Inbox all business",
      title_: "Inbox all business",
      fromdate: "From",
      todate: "To",
      close: "Close"
    },
    outbox: {
      title: "Outbox all business",
      title_: "Outbox all business",
      fromdate: "From",
      todate: "To",
      close: "Close"
    }
  },

  multirightclick: {
    download: "Download All",
    delete: "Delete All",
    star: "Star All",
    movefiles: "Move to",
    hashtag: "Add Hashtag",
  },

  keydetail: {
    path: "Path",
    details: "Details",
    general: "General Info",
    filename: "Name",
    document_no: "Document No",
    receiver_name: "Receiver Name",
    document_type: "Document Type",
    cre_dtm: "Create Datetime",
    sender_name: "Sender Name",
    filesize: "Size",
    filetype: "Type",
    filedate: "Upload date",
    closeDialog: "close",
    owner: "Owner",
    month: {
      jan: "Jan",
      feb: "Feb",
      mar: "Mar",
      apr: "Apr",
      may: "May",
      jun: "Jun",
      jul: "Jul",
      aug: "Aug",
      sep: "Sep",
      oct: "Oct",
      nov: "Nov",
      dec: "Dec"
    },
    cannotopendatafile: "Unable to retrieve the file data at this time.",
    permission: "Permission",
    expiration: "Expiration date",
    edit_download:"Edit and Download",
    downloadonly:"Download only",
    viewonly:"View only",
    name_user:"Name",
    modified_date:"Modified Date",
    activity:"Activity Log",
    no_data_activity : "Don't activity log found"
  },
  shareRClick: {
    rename: "Rename",
    movetotrash: "Cancel Shared",
    movetotrash_: "Move to trash",
    addtostared: "Add to Starred",
    downloadfile: "Download File",
    removestarred: "Remove from Starred",
    preview: "Preview",
    listshare: "Share List",
    hide:'Hide',
    unhide:'Unhide',
  },
  greeting: "Welcome to Your Vue.js App",
  language_picker_helper: "Choose a language",
  languages: {
    english: "English",
    thai: "ภาษาไทย"
  },

  changeName: {
    nospecialcharacter: `A file/folder name cannot contain special charecter < > : * ? \\ | / " '`,
    changeName: "Rename",
    close: "close",
    submit: "confirm",
    changeSuccess: "Change name success",
    noChange: "Can not change name",
    filename: "Name",
    addfilename: "Please enter name files / folders",
    addurl: "Specify URL",
    addname: "Specify name",
  },
  sentFileOnechat: {
    header: "Send file to OneChat",
    friend: "Friend",
    nofriend: "You have no friend in OneChat",
    close: "close",
    choose:"comfirm",
    send: "send",
    cannotsendfolder: "Can not send folder now",
    sendfile: "Send file to OneChat success",
    sendding:"Sendding file to OneChat",
    choosefriend:"OneChat receiver",
    success:"success",
    cannotsendfile: "Can not send file to Onechat now",
    addreceiver: "Please choose receiver",
  },
  sendtoemail: {
    sendtomail: "Send to Email",
    to: "To :",
    subject: "Subject :",
    buttonclose: "Close",
    buttonsend: "Send",
    sendemailsuccess: "Send to Email Success",
    error: " can't sent to email ",
    cannotsendfolder: "Can not send folder now",
    pleasefillemail: "Please fill email",
    invalidemailformat: "Invalide email format"
  },
  multirecovery: {
    taball: 'All',
    tabnonerecovery_success: 'Waiting',
    tabrecovery_success: 'Success',
    selectAll: "Select All",
    folder: "Folder",
    file: "File",
    folderselected: "",
    fileselected: "",
    document_recovering: "Recovering",
    recovering: "Recovering",
    recovery_cancel: "Cancel",
    list: "list",
    list_filefolder_recov: "List of file/folder to recovery",
    filenoparent: "This File/Folder has no source",
    filenopermission: "Parent not permission",
    deletepermission: "Permission denied",
    movefilefolder: "Move File/Folder",
    move: "Move",
    moving: 'Moving',
    movingfilefolder: 'Moving file and folder',
    movesuccess: 'Moving success',
    filefoldermoved: "This file/folder has been moved",
    allowpermission: "Permission Allow",
    document_allowpermission: "Permisson Allow",
    notallowpermission: "Permission Denied",
    document_notallowpermission: "Permission Denied",
    document_fail_recovered: "Not complete",
    recoverror: "Can not recovery",
    document_recovered: "Complete",
    recovsuccess: "Complete",
    recovfail: "Not complete",
    waittomove: "Wait to move",
    waittomovefilefolder: "Wait to move File/Folder",
    recovbtn: "Recovery"
  },
  multimovefiles: {
    selectfile: "Selected file/folder",
    moveto: "Move to",
    move: "Move",
    permission_denied: "Permission Denied",
    permission_granted: "Permission Grant",
    filefoldermomove1: "File/Folder ",
    filefoldermomove2: " items can not movefile",
  },
  movefiles: {
    movefiles: "Movefile",
    buttonclose: "Close",
    move: "Move",
    maveat: "Move here",
    success: "success",
    nosuccess: "failed",
    nofolder: "No Data ",
    movefolder: " Movefolder",
    permission_denied: "Permission Denied",
    cannotmovefile: "Can not movefile",
    movefoldersuccess: "Movefolder success",
    cannotmovefolder: "Can not movefolder",
    filenomove:
      "This file is still in the original folder. Please select a folder to move ",
    foldernomove:
      "This folder is still in the original folder. Please select a folder to move ",
    choosetargetfolder: "Please choose target folder to move this file",
    mutifilefoldernomove: "All file/folder is still in the original folder. Please select a folder to move",
    muticannotmove: "Can not move file/folder",
    multicannotmovesomefilefolder: "Can not move some or all file/folder",
    multisuccess: "Move file/folder success",
    lockfilefolder: "Can not move file/folder because the folder is locked",
    permissionnotallow: "No permission is allowed to move to this folder",
    choosetargetallowfolder: "Choose target allow folder",
  },
  Filter: {
    name: "Name (Folder/File)",
    size: "File size",
    position: "File position",
    date: "Last modified",
    outboxdate: "Sender date",
    docno: "Document no",
    docdate: "Document date",
    nameonly: "Name",
    inboxdate: "Receive date",
    shareby: "Share by",
    owner: "Owner",
    sharedate: "Share date",
    doctype: "Document type",
    sendname: "Sender",
    receivername: "Receiver",
    sendnameAll: "Seller name",
    receivernameAll: "Buyer name",
    status: "Status file",
    expiredinbox: "Expired Inbox"
  },
  uploadFile: {
    header: "Upload file",
    upload: "Uploading....",
    uploadsuccess: "Upload file Success",
    cannotupload: "Can not Upload file",
    dropfile: "Drop file/folder to upload",
    movedropfile: "Drop file/folder to move",
  },
  receivername: "Receiver name",

  sharelist: {
    headermessage: "Share list",
    sharewith: "Share with",
    cancelsharefile: "Cancel sharefile",
    cancelbutton: "Cancel"
  },
  theme: "Theme",

  admin: {
    groupname:"Group Name",
    creategroup:"Create Group",
    unlock_complete: "Unlock Complete",
    unlock_notcomplete: "Not Complete",
    consent: "Consent",
    edit_date: "Date",
    system:"System",
    systemconsent: "System consent",
    systembusiness:"Business system",
    status: "Status",
    confirmclonepermission:"If you disable set permission only this folder ,The permissions of all internal files/folders are set according to this folder.",
    permissionclone:"Set permission only this folder",
    confirmdelete:"Comfirm Delete File/Folder" ,
    confirmdelete_text1: "Do you want to delete",
    confirmdelete_text2:"from drive ?",
    tel:"Mobile",
    user_name:"Name",
    oneid_user_id:"USER ID ONEID",
    business_email:"E-Mail",
    mobile:"Phone Number",
    genkey:"Generate Key",
    seeall:"see all",
    addemail:'Add Email',
    emailtext:"Email",
    setadmin:"Setting Administrator",
    setemail:"Setting Email Notification",
    dashboard:"One Box Business Dashboard",
    numberuser:"number of user : ",
    totalusage:"Total usage",
    spacestorage:"Space storage",
    available:"Available",
    driveused:"Drive used",
    drivetash:"Drive trash",
    storageused:"Storage used",
    file:"Files",
    folder:"Folder",
    personalfile:"Personal Drive",
    personalfilebuss:"Personal Drive in Business",
    inbox:"Inbox",
    outbox:"Outbox",
    sharedrive:"Business Drive",
    top5department:"Top 5 Department Storage Used",
    top5employee:"Top Employee Storage Used",
    alldepartment:"All Department Storage Used",
    employeeinshare:"All Employee Storage Used in Business Drive",
    employeeoutshare:"Details of Storage Used in Business Drive",
    adminfolder: "admin",
    textset:
      "The role values ​​for members of this group have been updated. It may take time for the changes to take effect.",
    wanttoset: "Are you sure the permission settings?",
    confrimsetting: "Confirm setting",
    openall: "Open all",
    closeall: "Close all",
    update: "updating",
    setupdate: "in progress of updating",
    managefile: "Manage File/Folder",
    setpermissionfile: "Set Permission",
    department: "Department",
    role:"Role",
    employee: "Employee",
    view: "View",
    download: "Download",
    delete_s: "Delete",
    admin_folder: "Admin",
    upload: "Upload",
    edit: "Edit",
    setting: "Setting",
    save: "Save",
    close: "Close",
    managefeature: "Manage Feature",
    selectoption: "Select Setting",
    filterdepartment: "Filter Department",
    all: "All",
    name: "Name",
    business: "Business",
    submit: "Submit",
    manageinbox: "Manage Permission Inbox",
    manageoutbox: "Manage Permission Outbox",
    settingall: "Setting Permission",
    permissiondepartment: "Permission Department",
    permissionemployee: "Permission Employee",
    permissionrole: "Permission Role",
    permissionbusiness: "Permission Business",
    status: "Status",
    allow: "Allow",
    denind: "Denined",
    ad: "Download/Delete",
    delete: "Default",
    option: "Option",
    remain_storage: "Storage Remain",
    use_storage: "Storage Usage",
    storage: "Storage",
    managestorage: "Manage Quota Storage",
    settingfeature: "Setting Feature",
    indexemail: "Email:",
    indexmobile: "Mobile:",
    adminheader: "Administrator",
    log: "Log",
    titlestorage_re: "Remain Storage",
    titlestorage_all: "Storage Business",
    search: "Search",
    department_search: "Department Search",
    upload_permission:"Upload Permission",
    headerCheck: "Confirm Setting",
    opencheckdepartment:
      "Are you sure you want to enable department file / folder access permissions ?",
    opencheckrole:
      "Are you sure you want to enable role file / folder access permissions ?",
    closecheckdepartment:
      "Are you sure you want to disable department file / folder access permissions ?",
    closecheckrole:
      "Are you sure you want to disable role file / folder access permissions ?",
    confirmclosedepartment:
      "***If you disable access setting permission Department files / folders wiill make the permissions that have already been set up to not work.***",
    confirmcloserole:
      "***If you disable access setting permission Role files / folders wiill make the permissions that have already been set up to not work.***",
    opencheckemployee:
      "Are you sure you want to enable employee file / folder access permissions ?",
    closecheckemployee:
      "Are you sure you want to disable employee file / folder access permissions ?",
    confirmcloseemployee:
      "***If you disable access setting permission Employee files / folders wiill make the permissions that have already been set up to not work.***",
    opencheckbusiness:
      "Are you sure you want to enable business file / folder access permissions ?",
    closecheckbusiness:
      "Are you sure you want to disable business file / folder access permissions ?",
    confirmclosebusiness:
      "***If you disable access setting permission Business files / folders wiill make the permissions that have already been set up to not work.***",
      settypeupload:"Set Typefile",
      password_expired: "Set Password Expired",
      day: "Day",
      time:"Time",
      set_date: "Set date",
      no_date_set:"No date set",
      enable: "Enable",
      disable: "Disable",
      two_factor_authen: "Two Factor Authen",
      maxenterdatenumber: "Enter number of days up to 366 days",
  },
  admintoolbar: {
    report_problem: "Report Problem",
    managemapdrive:"Manage Mapdrive",
    manageemployee:"Mange Employee",
    managefile: "Manage File/Folder",
    managefeature: "Manage Feature",
    managestorage: "Manage Quota Storage",
    manageinbox: "Manage Inbox",
    manageoutbox: "Manage Outbox",
    dashboard: "Dashboard",
    admin: "Business Management",
    log: "Log",
    setadmin:"Setting Administrator",
    setemail:"Setting Email Notification",
    genkey:"Generate key",
    manageheader:"Business Management",
    mydriveadmin:"My Drive Employee",
    buystorage:"Purchase Storage",
    paymentlist:"Payment List",
    packagepurchasehistory:"Package Purchase History",
    register:"Register Account Business",
    admin2:"Admin",
    consent: "Consent",
    recheckimap: "Recheck MAIL AUTO KEEP",
    fileandfolderbackup: "File/Folder Backup",
    addemployee:"Add an employee",
    setbusiness:"Business Setting",
    template_feature: "Create Template" 

  },
  adminrightclick: {
    setpermission: "Set Permission",
    movetolibrary: "Move to Library",
    movetodelete:"Delete",
    movetoedit:"Edit",
    download:"Download",
    downloadall:"Download All Files",
    unlockfile_right: "Unlock File",
    unlockfolder_right: "Unlock Folder"
  },
  admintoast: {
    settingsuccess: "Setting Success",
    settingfail: "Setting Fail",
    confirmdeleteemail:"confirm delete email",
    confirmdeleteemail_text:"Do you want to delete ",
    cancel:"cancel",
    notenoughstorage: "Not enough storage",
    errormanagequotastorage: "Can not manage quota storage",
  },
  adminlog: {
    name: "Name",
    process_name: "Process Name",
    process_dtm: "Process Dtm",
    detail: "Detail",
    dialog: "Detail Log",
    filename: "Filename",
    foldername: "Foldername",
    status: "Status",
    newfilename: "New Filename",
    newfilestatus: "New File status",
    newfolderstatus: "New Folder status",
    newfoldername: "New Foldername",
    newparentfoldername: "New Parent Foldername",
    oldfilename: "Old Filename",
    oldfilestatus: "Old File status",
    oldfoldername: "Old Foldername",
    oldfolderstatus: "Old Folder status",
    oldparentfoldername: "Old Parent Foldername",
    currentname: "Current name",
    statussharelink: "Status Share Link",
    type: "Data Type",
    newname: "New name",
    oldname: "Old name",
    sharewith: "Share With",
    statusshare: "Status share",
    businessset: "Business Setting",
    department: "Department",
    nameset: "Name Setting",
    resultset: "Result Setting",
    filenamein: "Filename Inbox",
    filenameout: "Filename Outbox",
    newrelation: "New Relation",
    oldrelation: "Old Relation",
    text: "Please select the process information you wish to inspect.",
    download: "Download",
    fine:"Find Log Information",
    log_information:"Log Information",
    employee_name:"Employee Name",
    file_folder_name:"FileName/FolderName",
    no_name:"(Unnamed)",
    email:"Email"
  },
  adminrecheckimap: {
    checkimapbutton: "Check and Download",
    noserviceimap: "This business does not MAIL AUTO KEEP services.",
    noemailsavedoc: "Can't find an email to save the document.",
    checkpleaseweit: "Checking documents is in progress. Please wait a moment...",
    checkdoc: "Checking documents",
    downloaddocsuccess: "Download document success",
    nowdownload: "Downloading document",
    notdownload: "Can not download document now",
    pleaseenterpasswordemail: "Please enter password for email",
    pleaseselectdate: "Please select the date you wish to search",
    dateemail: "Email documant date",
    storagemail: "Storage Mail",
    usagestorage: "Usage Storage",
    unlimit: "Unlimit",
    in: "in",
    storagemailautokeep: "Storage Mail Auto Keep"
  },
  relationMyfile: {
    headerdialog: "Relation Document",
    createrelation: "Create Relation",
    addrelation: "Add Relation",
    relationname: "Relation name : ",
    chooserelation: "Choose Relation",
    namerelation: "Name Relation",
    relation: "Relation",
    master: "Master",
    child: "Child"
  },
  dragdrop: {
    cancelupload: "Cancel upload",
    cannotupload: "File upload failed in",
    uploadsuccess: "Upload success",
    uploadfail: "Upload fail",
    detail: "detail",
    hide: "hide",
    uploading: "Uploading",
    close: "Close",
    header: "Detail upload",
    file:"File",
    cancel:"Cancel",
    of:"of",
    morefivegb: "Size file more than 5GB",
    morefile: "Size file more than ",
    yourspacenotenough: "Your space not enough",
    cannotdetailupload: "Can not upload file/folder to now",
    nofolder: "No folder has been selected",
    duplicatefile: "File duplicate",
    duplicatefolder: "Folder duplicate",
    duplicatefail: "Can't check file/folder duplicate, Please contact the system administrator"
  },
  checkdelete: {
    deleteonlyme: "Delete Only Me",
    deleteandrequest: "Delete And Request",
    ok: "OK",
    cancel: "cancel",
    delete: "Delete",
    wanttodelete: "Do you want to delete a file or folder?",
    wanttodeleteandrequest: "Do you want to delete and request delete?",
    cantrestore: "If you delete the file Will not be able to restore files"
  },
  checkpassword: {
    oldpassword:"Old password",
    newpassword:"New password",
    confirmnewpassword:"Confirm New password",
    setnewpassword:"Set New password",
    referencecode:"Reference code",
    clickrequestotp:"Please click to request OTP",
    requestotp:"Request OTP",
    forget:"Forget password",
    title:'This file is password protected',
    check:'Enter the password ',
    removepassword: 'Please enter a password to deactivate.',
    inputpassword: "Please Input Password",
    editpassword: "Edit Password",
    deletepassword: "Delete Password",
    forgotpassword: "Forgot Password",
    setpassword: "Set password",
    ok: "OK",
    edit: "Edit",
    cancel: "Cancel",
    passwordotp: "ReferenceCode",
    password: "VerificationCode",
    setok: "Set",
    passwordincorrect:'Password incorrect',
    enterpassword:'Please enter password',
    deletepasswordsuccess:'Delete password success',
    createpasswordsuccess: 'Create password success',
    editpasswordsuccess: 'Edit password success',
    oldpasswordincorrect: 'Old password incorrect',
    confirmnewpasswordnotmatch: 'Confirm password not match',
    newpasswordsameoldpassword: 'The new password must not be the same as the old password ',
    sendotp: 'Send OTP to email',
    success: 'success',
    sendotpfailed: 'Send OTP to email failed',
    close:"Close"
    
  },
  dashboard: {
    name: "Name",
    emempty_1:'ไม่มีพนักงานที่ใช้ไดรฟ์บริษัทสูงสุด',
    emempty_2:'ไม่มีพนักงานที่ใช้ไดรฟ์ส่วนตัวสูงสุด',
    deempty:'ไม่มีแผนกที่ใช้พื้นที่สูงสุด'
  },
  fileduplicate: {
    headerfileduplicate: "Found Duplicate Files/Folders",
    filecopy: "Copy",
    filereplace: "Replace",
    fileskip: "Cancel",
    selectall: "Select All",
    skipmsg: "Cancel upload",
  },
  fileexpiredall: {
    fileexpired: "File Expired"
  },
  expiredrightclick: {
    showinfolder:"Show in folder",
    setexpire: "Edit expired date",
    choosedate: "Choose expired date",
    deleteexpire: "Delete",
    setexpiredsuccess:'Set Expiration Date Success',
    setexpireddate: "Set Expiration Date",
    expireddate: "Expired date :",
    settingexpiration: "Setting expiration",
    beforeexpireddate:'The warning date before expired :',
    settingbeforeexpiration:'Setting The warning date before expired',
    choosebeforeexpiretiondate:'Choose the warning date before expired',
    setbeforeexpire:'Notification date before file expired',
    alert_email: "Alert by Email",
    alert_onechat: "Alert by Onechat",
    friend:"Friend",
    condition:"If you want to specify more than one email address, Please enter your email address separated by a comma (,).",
    detail: "Detail",
  },
  libraryRclick: {
    download: "Download",
    preview: "Preview"
  },
  checkmultidownload: {
    listselectdownload: "List file and folder",
    countfile: "Number of files and folders can download",
    cannotdownload:'Number of files and folders that do not have permission to download',
    totalfile:'Total number of files and folders selected',
    file: "file",
    folder:'folder',
    download: "Download",
    candownload:'Can be downloaded',
    cannotpermissiondownload:'Do not have permission to download',
    cannotlockdownload:'Unable to download because the file is locked',
    downloading:'Downloading',
    downloadsuccess:'Download Success',
    downloadnotsuccess:'Download Failed',
    mergingfiles:'Merging Files..'
  },
  dialogmultidelete: {
    header: "List of selected files and folders",
    total: "Number of selected files and folders",
    can_delete:"Number of files and folders that can be deleted",
    cannot_delete:"Number of files and folders that don't have permission to delete",
    title2: "files",
    title1:"folders",
    filelock:"The file cannot be deleted because the file is locked",
    canbedelete: "this file / folder can be deleted.",
    cannotdelete: "You do not have permission to delete this file / folder.",
    buttonclose: "close",
    buttondelete: "delete",
    deleting:"deleting files/folder",
    deletesuccess:" delete success",
    deletecancel: "Cancel",
    deletefail:"Can not delete this files/folder",
    canceldelete: "Cancel"
  },
  // dialogconfirmdelete:{
  //   header:"Files or folders to be imported into the trash",
  //   noti:"Do you want to put files or folders in the trash?"
  //  },
   dialogconfirmdelete:{
    header:"Confirm to put the files / folders into trash.",
    noti_1:"Do you want to put",
    noti_2:"to trash ?",
    comfirmdeletetext1: "Can't be restored when permanent deletion.",
    comfirmdeletetext2: "Do you want to confirm delete?",
    permanently: "Permanently delete",
    restoresuccess: "Restored successfully !",
    deletesuccess: "Delete successfully !",
    permanentlydelete_success: "Permanently Delete successfully !",
    undocumented_delete:"There is no document to delete",
    undocumented_permanently:"There is no document to permanently delete",
    undocumented_restore:"There is no document to restore",
    noti_emptytrash: "This action will delete all documents in the Trash Inbox. Do you insist on taking action?",
    noti_emptytrash_outbox: "This action will delete all documents in the Trash Outbox. Do you insist on taking action?"
   },
  balloon:{
    choose:"Select",
    chooseall:"Select All",
    evaluate:"Assessment",
    download_all:"All Download Files From Search",
    download_all_csv:"All Download Files From Search (CSV)",
    download_all_excel:"All Download Files From Search (Excel)",
    reload:"reload data",
    view:"view only",
    download:"download",
    upload:"upload",
    edit:"edit",
    delete:"delete",
    admin:"admin file / folder",
    clear:"clear",
    close:"close",
    menu:"Menu",
    upload_create:"upload / create",
    purchase:"purchase storage",
    print:"print PDF",
    menu_download:"menu download",
    back:"back",
    grid:"grid",
    list:"list",
    star:"favorites",
    move:"move file/folder",
    menu_trash:"trash menu",
    cleartrash: "clear trash",
    permanently_delete:"Empty Trash (Selected)",
    restore:"restore",
    allrestore:"all restore",
    resend: "resend",
    manual:"One Box User Manual",
    darkmode: "Dark Mode",
    lightmode: "Light Mode",
    lock_function:"Only available to One Box Enterprise Package",
    storage_and_permission: "Storage is full and Permissions denied",
    storage: "Storage is full",
    permission: "Permissions denied",
    Medandetax:"Document",
    business_management:"Business Management",
    drive_business:"Drive Business",
    info_date:"Date of submission is the date the document into the One Box system",
    info_date_doc:"Date document is the date specified in the document",
    info_date_outbox:"Document upload date is the date the document is entered into the One Box system",
    permanently_delete_total:"Empty Trash (All)",
    addhashtag: "Add Hasgtag",
    setting:"Setting",
    editfileonline:"Only file extensions docx xlsx pptx",
    allsettingmanagestorage: "Setting all quota storage",
    selectsettingmanagestorage: "Setting quota storage (Selected)",
    managestorage: "Manage quota storage",
  },
  textprint: {
    filesomethingwrong : "File something went wrong, Unable to print.",
    cannotprint: "Can't to print file.",
  },
  consent:{
    date_time:"Date",
    Name_:"Name",
    Status:"Status",
    Edit:"Edit",
    Detail:"Detail",
    cancelapprove:"Reject",
    appove:"Approve",
    pending:"Pending",
    approvetext:"Approve",
    canceltext:"Reject",
    detail:"Detail..",
    confirmcancel:"Confirm cancel consent",
    confirmcanceltext1:"Do you want to delete",
    confirmcanceltext2:"?",
    dialogdetail:"Detail",
    cancel:"Cancel",
    setting:"Setting",
    save:"Save",
    except:"Except company",
    receive_etax_all:"Receive e-Tax from all company : ",
    receive_etax:"Receive e-Tax from company : ",
    company:"Company(s)",
    total:"Total",
    taxid_duplicate:"Tax ID Duplicate",
    added_taxid:"Successfully Added Tax ID",
    set_successfully:"Set Successfully",
    limit_receipt_of_etax:"Choosing to receive E-documents",
    show_more:"Show more", 
    show_less:"Show less",
    title_receive_etax:"Save company(s) information to receive e-Tax",
    receive_all:"Receive All",
    receive_specificate:"Receive Specificate",
    receive_all_with_except:"Receive All with Except",
    title_receive_all:"Receive e-Tax from all Tax ID.",
    title_receive_specificate:"Receive e-Tax  from specific Tax ID.",
    title_receive_all_with_except:"Receive e-Tax from all Tax ID, Except Tax ID that need to be except.",
    input_block_taxid:"Input Tax ID that need to be except",
    input_accept_taxid:"Input Tax ID that need to be accept",
    setting_history:"Setting History",
    current_status:"Current Status : ",
    name_user:"Name",
    modified_date :"Modified Date ",
    close:"Close",
    history:"Revision History",
    title_log:"Choosing to receive E-documents Log",
    please_accept_consent:"Please accept consent"
    
  },
  backupfileandfolder: {
    allrestore: "Restore all files/folders",
    contentallrestore: "Do you want to restore all files/folders ?",
    multirestore: "Restore selected files/folders",
    contentmultirestore: "Do you want to restore selected files/folders ?",
    cancelrestore: "Cancel restore",
    preparerestore: "Preparing to restore files/folders",
    restoresuccess: "Restore files/folders success",
    restoringfilefolder: "Restoring files/folders",
    nofileandfolderforrestore: "Not File/Folder for restore",
    detailrestore: "Detail restore File/Folder",
    cannotrestore: "Can't restore File/Folder",
    pleaseselect: "Please select the files/folders to be restore",
    nodatarestore: "No file/folder for restore"
  },
  edithashtag:{
    edit:"Edit",
    ok:"Save",
    buttonclose:"Close",
    editsuccess:"Edit hashtag success",
    erroredit:"Can't edit hashtag",
    setting:"Add/Edit Hashtag",
    add:"Add",
    hashtagempty:'Please enter the desired hashtag',
    nospecialcharacter: "A hashtag cannot contain characters , |  or space",
  },
  donotpreview:"No permission to access this file.",
  permissionsharefile:{
    viewonly:"View only",
    upload:"Upload",
    download:"Download",
    edit:"Edit",
    delete:"Full Access"
  },
  adminsetemail:{
    headeraddemail:"Add email to receive notifications",
    headereditemail:"Edit email to receive notifications",
    add:"Add",
    edit:"Edit"
  },
  confirm:{
    confirmcancleshared:"Confirm Cancel Shared",
    cancelshared:"Do you want to cancel shared ",
    confirm:"confirm",
    closecomfirm:"close",
    confirmhide:"Confirm hiding",
    hide:"Do you want hiding file",
    confirmunhide:'Confirm unhide',
    unhide:'Do you want to unhide'
  },
  lockfile:{
    unlockfilesuccess: "Unlock File Success"
  },
  register:{
    regissuccess: "Successfully registered"
  },
  quota_storage:{
    quota_storage:"Quota Storage",
    quota_usage:"Quota Usage",
    quota_remain:"Quota Remain",
    quota_default: "Quota Default",
    setting:"Setting storage",
    settingselect:"Setting storage (selected)",
    settingall:"Setting all storage",
    settingdefault: "Setting default storage",
    headerset:"Setting Quota Storage",
    quota_business:"Quota Storage for Business",
    text_remain_bus:"Remaining storage for business",
    text_remain:"Remaining storage",
    text_used:"Used storage",
    text_quota:"Quota storage",
    setstorage:"Setting quota storage",
    text_edit:"Enter the storage you want to edit.",
    text_set_quota:"Storage set up",
    text_set_quota_head:"Quota Storage set up",
    quota_DE:"department",
    quota_EM:"employee",
    updatingsetting: "Updating",
    updatesettingsuccess: "Update Success",
    cannotfinddata: "Can't find in data employee",
    cannotfindemployeeinbiz: "Can't find employee in business",
    cannotupdatemorespace: "Cannot update because the storage is less than the used space",
    notset: "Not set",
    pleaseselectemployee: "Please select the employee you want to setting storage",
    alldepartment: "All",
    edit: "Edit",
    messagesetdefault: "When setting this value, when a user enters the company You will automatically receive the quota that you set"
  },
  statusfileexpired:{
    unsetsettingexpired:'Unset setting expired',
    expired:'expired',
    nearexpired:'Nearly Expired',
    unsetexpiredfile:'Do you want to unset files or folders ?',
    unsetsuccess:'Canceled the expiration date setting success',
    unsetunsuccess:"Can't cancelled expiration date",
    expired_search:"Serach"
  },
  selectbutton:{
    hashtag:'Hashtag',
    encrypt:'Encrypt',
    remark:'** If the file is encrypted, it may take longer to upload than usual'
  },
  loginbusiness:{
    error:'Token expired, please login again'
  },
  packagedetail:{
    ManageUser:'Manage User',
    UploadDownloadShare:'Upload/Download/Share',
    RestrictAccess:'Restrict Access',
    AdvanceSearch:'Advance Search',
    ConnectThroughAPI:'Connect Through API',
    SendFiletoeMail:'Send File to e-Mail',
  },
  defaultbusiness:'Default Business',
  settingdefaultbusiness:'Setting default Business',
  settinfdefaulesuccess:'Setting default Success',
  shortcut_header:'Create Shortcut',
  goback:"Go Back",
  resend:{
    sorrt_by:'Sort by',
    status_error: 'Failed to send',
    status_success:'Send success',
    status_pending:'Pending',
    datafile:'Data File',
    datafilesummary:'Data File Summary'
  },
  buystorage:"Purchase Storage",
  resendmail:{
    header:"resend mail",
    wordresend:"Do you want to send files to",
    note:"Note: You can use , (comma) if you want to send more than one email.",
    addmail:"add e-mail",
    wordresend_2:"again ?",
    confirm:"confirm"
   },
   sorting: {
    sort: "Sort by",
    name: "Name",
    date_created: "Date Created",
    date_modified: "Date Modified",
    date_delete: "Date Delete",
    type: "Type",
    size_file:"Size",
    ascending: "Ascending (A-Z , 0-9)",
    descending: "Descending (Z-A , 9-0)",
    document_date: "Document Date",
    document_type: "Document Type",
    cre_dtm: "Create Datetime",
    customer_name: "Customer Name",
    saler_name: "Saler Name",
    filename: "Filename",
    doc_no:"Document Number",
    status:"Status",
    trash_dtm:"Last Delete"
  },
  paymentlist:{
    package_name:"Package Name",
    price:"Price(Baht)",
    payment_date:"Payment Date",
    dateofpayment:"Date of Payment",
    payment_status:"Payment Status",
    tax_invoice:"Tax Invoice",
    pay:"Pay",
    btnpay: "Press to pay",
  },
  packagepurchasehistory:{
    package_name:"Package Name",
    package_name_addon:"Package Name (Add on)",
    price:"Price(Baht)",
    payment_type:"Payment Type",
    dateofpayment:"Date of Payment",
    payment_status:"Payment Status",
    tax_invoice:"Tax Invoice"
  },
  packagenew:{
    package:"Package",
    price:"Price (Bath)",
    amount:"Amount",
    purchase_package:"Purchase Package",
    my_package:"My Package",
    package_history:"Package Purchase History",
    current_mystorage:"Current storage space",
    used_space:"Used Space",
    total_users:"Total Users",
    title_package:"Choose the Onebox package that is right for you, Membership packages start at 350 Baht/Month",
    month:"Monthly",
    year:"Yearly",
    baht_month:"Baht/Month",
    baht_year:"Baht/Year",
    choose_package:"Choose a package",
    vat:"*The package doesn't include VAT 7% and supports payment through 3 channels. (Credit Card / QR Code / Internet Banking)",
    text_notvat:"*Storage price doesn't include VAT 7%",
    clear:"Clear",
    update_package:"Update Package",
    pay:"Pay",
    service_charge: "Service charge calculation your storage.",
    login_for_purchase_storage:"Login to purchase storage",
    login_for_purchase_package:"Login to purchase package",
    personal:"Personal",
    business:"Business",
  },
  addon_package:{
    addon_package:"Choose the One Box add-on package for you",
    addon_title:"One Box account can add additional packages to meet your needs.",
    choose_package:"Choose a package",
    price:"Price(Baht)",
    add:"Add",
  },
  Order_summary:{
    Order_summary:"Order Summary",
    not_choose:"You have not choose the main package",
    not_choose_addon:"You have not choose the add-on package",
    main_package:"Main Package",
    addon_package:"Add-on Package",
    total:"Total",
    baht:"Baht",
    vat:"*The package doesn't include VAT 7%."
  },
  taxinvoice_address:{
    taxinvoice_address:"Tax Invoice Address",
    purchaser:"Purchaser : ",
    address:"Address : ",
    address_detail:"1768 Thai Summit Tower, 16th Floor, Huai Khwang Subdistrict, Bang Kapi District, Bangkok 10310",
    tax_identification:"Tax Identification : ",
    click_here:"Click here to fill out the tax invoice",
    edit_tax:"✔ Complete the tax invoice, You can click here to edit"
  },
  my_package:{
      my_package:"My Package",
      change_package:"Change Package",
      current_mystorage:"Current storage space",
      used_space:"Used Space",
      total_users:"Total Users",
      main_package:"Main Package",
      addon_package:"Add-on Package",
      main_package_next:"Main Package(Next Bill)",
      baht:"Baht",
      month:"Month",
      year:"Year",
      duration:"Duration of use",
      no_expiration:"There is no expiration date"
  },
  package_current:{
    confirm_tax:"Confirm the issuance of the tax invoice",
    want_tax:"Do you want to issue a tax invoice ?",
    cancel:"Cancel",
    wish:"Wish",
    unwish:"Unwish",
  },
  resendfile: {
    resend: "Resend",
    closefromdateresend: "Close",
    fromdate: "Fromdate (document)",    
    todate: "Todate (document)",
    filename: "File name",
    document_no: "Document no",
    document_date: "Document date",
    document_type: "Document type",
    data_type: "File type",
    sender_name: "Sender name",
    receiver_name: "receiver name",
    cre_dtm: "Sender date",
    status_trash_receiver: "status",
    optional: "Optional",
    msg: {
      nofile: "Please Choose Files !!!",
      apierror: "Cannot Resend File !!!"
    },
  },
  checkremember:{
    check: "Want to remember me in the system ?",
    yes: "Yes",
    no: "No",
  },
  sharewithme:{
    filesharewithme: "Share with me files",
    hiddenfile: "Hidden files",
    adminonly: "Only the admin business to cancel this sharing"
  },
  landingpagenavbar:{
    home: "Home",
    oneboxserviceinfo: "One Box Service Information",
    dataprotectionpolicy: "Data Protection Policy",
    termsofservice: "Terms of Service",
    termsofuseofelectronic:"Terms of Use of Electronic Document Storage System",
    manual:"One Box User Manual",
    businessbox: "Business Box Register Guide",
    oneboxmanual: "One Box User Manual",
    etax: "Guide to Finding e-Tax Invoice Files",
    useonebox: "VDO how to use One Box",
    storage: "VDO Buying additional Storage manually",
    registeraccount: "Register",
    registerone: "Register Account One Platform",
    registerbusiness: "Register Account Business",
    register_mohpromt:"Register Account MohPromt",
    login: "Log in",
    gotoonebox: "Go to One Box",
    logout: "Log out",
    language: "Language",
    login_onecollab:"One Collaboration Mail",
    login_azure_id: "Login with Business Account"
  },
  landingpagefooter:{
    contactus: "Contact Us",
    addressheader: "Address",
    addresscontent1: "1768",
    addresscontent2: "Thai Summit Tower,",
    addresscontent3: "10th-12th Floor and IT Floor",
    addresscontent4: "New Petchaburi Road,",
    addresscontent5: "Khwaeng Bang Kapi,",
    addresscontent6: "Khet Huay Khwang,",
    addresscontent7: "Bangkok",
    addresscontent8: "10310",
    telephone: "Telephone"
  },
  landingpage: {
    rememberme: "Remember Me",
    accept: "Accept",
    termsofserviceoneid: "Terms of Service ONE ID",
    and: "and",
    privacypolicy: "Privacy Policy",
    createaccountoneid: "Create Account OneID ?",
    createaccount_oneid: "Create Account One Platform ?",
    registereasyoneid: "Register Easy One Platform",
    registeruserheader: "Register",
    success: "Success",
    mobileno: "Mobile No.",
    username: "Username",
    password: "Password",
    idcard:"Identification Number",
    confirmpassword: "Confirm Password",
    registerbtn: "Register",
    verify:"Verify",
    haveanaccount: "Have an One Platform account ?",
    registersuccessfully: "Register Successfully",
    loginlink: "Log in",
    pleaseremember: "Please remember !!",
    failedLogin: "You have tried to log in too many times. Please try again in",
    hour: "hour",
    minute: "minute",
    second: "second",
    contentsection1: {
      title: "Easy, Convenient, and Fast with ONEBOX",
      item: "Support for use via browser",
    },
    contentsection2: {
      title: "Ease of Use and File-Sharing Capabilities",
      subtitle1: "Conveniently to Anytime, Anywhere",
      item1: "Can access file storage anytime, anywhere and on any device through a Web Application.",
      subtitle2: "Share Files across Multiple Channels",
      item2: "Can share files for other people can download them without sending a link and can also send files via chat and e-mail",
    },
    contentsection3: {
      title: "Don't worry about File Storage and Data Safety",
      subtitle1: "Data Security",
      item1: "Can store files of all types, and data stored safety on the database in Thailand with transparency and accountability.",
      subtitle2: "Protection Data in Business",
      item2: "Protection data in Business with restricting access to files",
      subtitle3: "Advance Search",
      item3: "Can easily search key content files in the document.",
    },
    notiloginandregister: {
      incorrectusernameorpassword: "Username or password incorrect",
      emptyusernameorpassword: "Please input username and password",
      incorrectpasswordnotmatchregister: "Passwords not match",
      incorrectformetusername: "Invalid username format",
      incorrectformetpassword: "Invalid password format",
      emptyregister: "Please fill out completely",
      required: "Please fill out this field",
      formetentertextphone: "Please fill 10 digits",
      formetenterusername: "Must have at least 6 characters and no special characters.",
      formetenterpassword: "Must have at least 1 character, at least 1 number, and length at least 8.",
      formetenterconfirmpassword: "Passwords not match",
      usernameduplicate: "Username duplicate",
      mobileduplicate: "Mobile no. duplicate",
      incorrectlogin: "Please enter correct information.",
    },
  },
  setemailforalert:"Setting email",
  alertemailstoragefull: {
    setemailforalert:"Setting email",
    notemailalert: "This drive business there is no email to alert the storage full,",
    pleaseenteremailalert: "Please specify the email address that you would like the notification to be sent.",
    setalertsuccess: "Successfully set up email to alert",

  },
  med: {
    medicalcertificate: "Medical Certificate",
    etax: "Etax",
    hospitalname: "Hospital Name",
    filename: "File name",
    document_date: "Document date",
    entry_status: "Status One Box",
    entry_dtm: "Date One Box",
    importfilemed: "Import",
    messageimportfilemed1: "Do you want to import",
    messageimportfilemed2: "files into your One Box?",
    selectfile: "Select File",
    accept: "Import files into One Box",
    close: "Close",
    confirm: "Confirm",
    cancel: "Cancel",
  },
  shopdee:{
    shopdeemeekuen:"shop dee mee kuen",
    messageimportfile1: "Do you want to import",
    messageimportfile2: "files into your One Box?",
    filename: "File name",
    document_id:"Document ID",
    document_type : "Document type",
    document_date: "Document date",
    duplicate_status: "Status One Box",
    selectfile: "Select File",
    accept: "Import files into One Box",
    close: "Close",
    confirm: "Confirm",
    cancel: "Cancel",
    download: "Download",
    preview: "Preview"
  },
  reload_permission:"Update organization chart from One ID",
  confirm_reload_permission:"Confirm Organization Chart from One ID",
  reload_permission_check:"Updating the Organization chart from One ID takes time to update",
  reload_permission_check1:"Did you confirm the update ?",
  admin_business:"Admin Business :",
  donthaveadminbusiness:"Consent to receive inbox documents is only for Admin Business",
  consentlog:"Consent Log",
  setbusinesslog:"Business Setting Log",
  conditions:"Please specify conditions",
  textconditions:"Document date or Process date was submitted or Document ID.either",
  textconditions_delete_filter:"Document date or Process date was submitted or Document ID.either for Delete from filter",
  textconditions_restore_filter:"Document date or Process date was submitted or Document ID.either for Restore from filter",
  text_want_delete:"Do you want to delete the filtered document ?",
  text_want_delete_permanently:"Do you want to [Permanently Delete] the filtered document ?",
  text_want_restore:"Do you want to restore the filtered document ?",
  conditions_file_attachments:'Please attach a document file with specified information " Document No "',
  items:"items",
  Number_documents:"Number of documents : ",
  dashboard_inboxoutbox:"Dashboard",
  status_inbox_outbox: {
    active: "Active",
    pending: "Receiver Full",
    duplicate: "Duplicate",
    resend_from_etax: "Active",
    sender_delete: "Sender Remove",
    request_delete_log: "Sender Remove",
    reject: "Receiver Denied Request",
  },
  onlyoffice:{
    alert_word: "Online edits are saved to the original file. Unable to restore the previous version before editing.",
    confirm:"confirm"
  },
  direct_upload: {
    savedriveof:"File will be saved to your drive of",
    uploadfile: "Upload",
    clearfile: "Clear",
    nofilewaitupload: "No files",
    // filewaitupload: "Pending upload",
    file: "file",
    files: "files",
    uploadsuccess: "Upload Success",
    uploadfail: "File upload failed in",
    loading: "Loading",
    pleasewait: "Please wait a moment",
    cannotlogin: "Can't log in at this time",
    yourspacenotenough: "Your space not enough",
  },
  information:{
    hashtag: "In case you want to enter 'hashtag' information, please specify 'hashtag' before uploading the file",
    encrypt: "In case of choosing file encryption is to prevent access to files It must be downloaded through the website only",
    twofactorauthen: "*** Two Factor Authen is The user must confirm that they are a real user. The system will send an OTP number to your phone number to use to verify your identity when log in ***"
  },
  count_data_Advasearch: "Found",
  Usermanual: "User Manual MAP DRIVE",
  blockmapdrive:"Blocked from use by Administrator",
  openmapdrive:"Activate",
  closemapdrive:"Off",
  copysuccess:"Copy",
  timeremaining:"Time Remaining",
  publicpage: {
    dowload: "Download",
    preview: "Preview",
    filename: "File name",
    filesize: "File size",
    sharedby: "Shared by",
    shareddate: "Shared date",
    sharedfrom: "Shared from",
    openby: "Openby",
    opendate: "Opendate",
    bizwatermark: "Business",
    openfully: "Link has been opened the specified number of times",
    filefolderdelete: "File/Folder has been deleted",
    linktimeout: "Link has expired",
    filefoldernotfoundorcloseshare: "File/Folder not found or File/folder sharing is disabled",
    logintoopenlink: "Log in to open link",
    notaccesslink: "File/Folder not found or File/folder sharing is disabled or Link has expired or Link has been opened the specified number of times",
    login: "Log in",
    enterpasswordfilefolder: "Enter password for file/folder",
    cancel: "Cancel",
    notpreview: "This file is not supported in preview",
  },
  sharelinkuploadpage: {
    savedriveof:"File will be saved to your folder",
    uploadfile: "Upload",
    clearfile: "Clear",
    nofilewaitupload: "No files",
    // filewaitupload: "Pending upload",
    file: "file",
    files: "files",
    uploadsuccess: "Upload Success",
    uploadfail: "File upload failed in",
    loading: "Loading",
    pleasewait: "Please wait a moment",
    cannotlogin: "Can't log in at this time",
    yourspacenotenough: "Your space not enough",
    filename: "File name",
    pleaseinputfilename: "Please input file name by",
    not: "not",
    fileextension: " file extension",
    notfilemoreone: "Please upload one file at a time",
    status: "Status",
    waitupload: "Wait upload",
    uploading: "Uploading",
    uploadsuccess: "Upload Success",
    notupload: "Not upload",
    remark: "Remark",
    failuplaod: "Upload fail, Please try again",
    linkexpired: "This link was expired",
    disablelink: "This link was disable or uncorrect , Please check a link",
    linkploblem: "This is ploblem , Please try again",
    nolink: "This link not correct",
    clicktoupload_mobile: "Click to Upload !",
  },
  reportPrb: {
    headerPage: "Report problem",
    no_header_table: "No.",
    header_table: "Report Topic",
    date_reported: "Reported Date",
    hottopic_header: "Hot Topic",
    description_header: "Description"
  },
  copyandpaste:{
    copy_btn: "Copy/Paste",
    copying_word: "Copying",
    copied_success: "Success copied",
    copy_paste_success: "Success Copy/Paste",
    copy_paste_error: "Not success",
    snackbar_clipboard_copy: "Copy (only) a list of files to the clipboard ...",
    snackbar_clipboard_paste: "Paste (only) a list of files to the folder ...",
    file_copy: "Copy",
    file_pasted: "Paste",
    pasted_file_folder: "Paste to folder",
    files_copy: "Copy all files"
  },
  evaluategialog:{
    evaluate_title:"Satisfaction assessment form One Box",
    evaluate_title1:"To use the evaluation results to develop Onebox for the better.",
    evaluate_btn1:"Later",
    evaluate_btn2:"Start evaluating",
  },
  smartinput: {
    template: "Template",
    smartinput: "Smart Input",
    pleaseselectfile: "Please select file to upload",
    emptyaspectnull: "Please fill out completely",
    special_aspect_error: "Plase No special charecter (Except - __ ( ) @ # $ ! + ^ & =)",
    fileto: "Files will be uploaded to : ",
    exemplefilename: "Exemple read flie name : ",
    notchose: "Not chose",
    tampletedetail: "Tamplete detail",
    create_template: "Create Template",
    edit_template: "Edit Template",
    view_template: "Template Detail",
    search_template_name: "Search Template By Name",
    no_data_templates: "No Template",
    selectfoldertemplate: "Select Folder Template",
    createnewfolder: "Create New Folder",
    select: "Select",
    selecthere: "Select Here",
    foldertemplate: "Folder Template : ",
    fomatnamefile: "Format file name",
    yourfileformatwrong: "Format of the file name is wrong",
    valuesamedropdown: "Value from file not same dropdown",
    notyetchosen: "Not yet chosen",
    pleaseselectfoldertemplate: "Please selectf folder template",
    permission_denied: "Permission Denied",
    header_table: {
      template_name: "Template Name",
      template_owner: "Template Owner",
      template_date: "Date Created",
      template_manage: "Manage",
    },
    template_form: {
      field_key: "Field Key",
      field_name: "Field Name",
      field_type: "Field Type",
      field_dropdown: "Dropdown ",
      field_dropdown_mobile: "Value ",
      field_reuqired: "Required"
    },
    select_default: {
      item_text: "Text",
      item_date: "Date",
      item_dropdown: "Dropdown"
    },
    template_detail: "View",
    template_edit: "Edit",
    btn_add_field: "Add Field",
    btn_delete_field: "Delete Field",
    btn_cancel: "Cancel",
    btn_save: "Save",
    btn_save_change: "Save Change",
    field_active: "Active",
    field_non_active: "Non Active",
    error_message_field: "Please fill template name without (/ and ')",
    error_message_dup: "Duplicate Field Key",
    warning_search_smup:"Please input information to search",
    emptry_aspect: "empty aspect",
    message_suggession: `Type name template no more than 50 alphabets without special charecters < > : * ? \\ | / " '`,
    error_message_field_name: `Please fill template name no more than 50 alphabets without special charecters < > : * ? \\ | / " '`,
    error_message_field_key: `Please type filed key no more than 50 alphabets without special charecters < > : * ? \\ | / " '`,
    error_message_field_labelname: `Please type filed name no more than 50 alphabets without special charecters < > : * ? \\ | / " '`,
    error_message_field_dropdown: `Please type select value no more than 100 alphabets without special charecters < > : * ? \\ | / " '`,
    error_message_field_symbol: `Please type symbol 1 alphabet without special charecters < > : * ? \\ | / " '`,
    error_message_dup: "Duplicate Field Key",
    cannotgettemplate: "Can't loading template. Please contact the system administrator",
    cannotgetdetailtemplate: "Can't loading detail template. Please contact the system administrator",
    success_create_template: "Create Template Success",
    error_create_template: "Create Template Not Success",
    success_edit_template: "Edit Template Success",
    error_edit_template: "Edit Template Not Success",
    error_some: "Something wrong",
    field_name_detail: `"Field Name" will be used in a smart input menu`,
    downloadreportfilesmartupload: "download report smart upload",
    nofileforreport: "No file for download report",
    datagoogledrive: "File/Folder in Google Drive",
    filename_search_infor: "For user who want to search for 2 or more files, ",
    filename_search_infor_2: "Use the symbol , to separate files",
    filename_search_infor_new2: "You can press the i icon to enter symbols for separating data",
    filename_search_infor_3: "Please enter symbols for separating data",
  },
  renamedocument: {
    renamedocumentheader: "Setting Rename Document",
    selectformatrenamedocument: "Select format rename document",
    selectformatrenamedocumentsuccess: "Select format rename document success",
    selectformatrenamedocumentfail: "Can't Select format rename document. Please contact the system administrator",
    default: "All document type",
    createdate: "Create Datetime",
    example: "Example",
    doctype: "Document Type",
    format: "Format",
    SellerName: "Sender name",
    SellerTaxID: "Sender tax id",
    SellerBranchID: "Sender branch id",
    BuyerName: "Receiver name",
    BuyerTaxID: "Receiver tax id",
    BuyerBranchID: "Receiver branch id",
    DocumentTypeCode: "Document type code",
    DocumentNumber: "Document number",
    DocumentDate: "Document date",
    textandsymbol: "Text / Symbol",
    selectall: "Set the format for all document type",
    notselect: "Not Select",
    emptysetting: "Empty Setting",
    titleformat: "A maximum of 3 formats can be specified, except for Text / Symbol.",
    confirmdeleterename: "Confirm delete setting rename document",
    textconfirmdeleterename: "Do you want delete setting rename document ?",
    confirmreplaterename: "Confirm replate setting rename document",
    textconfirmreplaterename: "is activated. Do you want to replate the settings for rename document ?",
    deleterename: "Delete Setting",
    editrename: "Edit Setting",
    openrename: "Activate",
    closerename: "Disable",
    disablesuccess: "Disable success",
    activatesuccess: "Activate succes",
    cannotalreadyenabled: "This type of document is already enabled. Please select one template.",
    cannotdisableactivate: "Can't disable/activate. Please contact the system administrator",
    deletesuccess: "Delete setting success",
    type: "Type",
    nospecialcharacter: `Can't contain special charecter  < > : * ? \\ | / " '`,
    addfilename: "Please enter text / symbol",
    infodefault1: "In case where not set format.",
    infodefault2: "Default is DocumentNumber_DocumentDate.pdf",
  },
  uniquefoldername:"ไฟล์โดนย้ายแล้ว",
  anotheraccount:"Another account",
  migratetogg:"File/Folder want to move to OneBox",
  migratesuccess:"Migrate frome google drive success",
  nochoosefilemigrate:"Please select the file or folder you want to migrate to One Box",
  requestdownload:{
    setting_request:"Setting Request Download",
    requesttodownload:"Request to download",
    setting_btn:"Setting",
    canclesetting:"Close to setting",
    opensetting:"Open to setting",
    appove_request:"Appove",
    no_appove_request:"Not approved",
    settime_download:"Setting time to download",
    send_request:"Send Request",
    close:"Close",
    email_request:"*Please fill in the email address you want to send the download request",
  }

};
